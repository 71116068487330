import {
  Box,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import SortableTableHead from '@components/ui/SortableTableHead';
import moment from 'moment-timezone';
import { qcStatusTooltips } from '@constants/qcStatuses';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { formatToUSD } from '@components/util';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSort } from '@hooks/sort';
import { useBWSync } from '@hooks/bwSync';
import { useFlags } from '@hooks/flags';
import { Scopes } from '@constants/scopes';
import { useAPI, usePermissions } from '@components/hooks';
import AdvertiserContext from '@components/AdvertiserContext';
import { CreativeAdGroupV1, LineItem } from '@local-types';
import { classes } from './styles';
import { CreativeQCAlerts } from '@components/CreativeQCAlerts';

export const AdGroupCreativeTable = ({
  adGroupId,
  campaignId,
}: {
  adGroupId: number;
  campaignId: number;
}) => {
  const adContext = useContext(AdvertiserContext);
  const { useGetAll, useGet } = useAPI();

  const [creativeAdGroups, setCreativeAdGroups] = useState<
    CreativeAdGroupV1[]
  >([]);
  const [csOwner, setCSOwner] = useState<{ email: string } | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [adGroup, setAdGroup] = useState<LineItem | null>(null);
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const { hasPermission } = usePermissions();
  const { flags } = useFlags();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const { order, orderBy, handleRequestSort, getComparator, stableSort } =
    useSort();

  const headCells = useMemo(() => {
    const cells = [
      { id: 'id', sortable: true, label: 'ID', disablePadding: false },
      {
        id: 'name',
        sortable: true,
        label: 'Creative',
        disablePadding: false,
      },
      {
        id: 'adGroupName',
        sortable: false,
        label: 'Ad Group',
        disablePadding: false,
      },
      {
        id: 'start_date',
        sortable: false,
        label: 'Uploaded',
        disablePadding: false,
      },
      { id: 'spend', sortable: true, label: 'Spend', disablePadding: false },
      {
        id: 'visits',
        sortable: true,
        label: 'Visits',
        disablePadding: false,
      },
      {
        id: 'costPerVisit',
        sortable: true,
        label: 'Cost per visit',
        disablePadding: false,
      },
    ];

    if (showQCFunctionality) {
      cells.push({
        id: 'qcStatus',
        sortable: true,
        label: '',
        disablePadding: false,
      });
    }

    if (showBWSync && flags.SYNC_CHECKS_ENABLED) {
      cells.push({
        id: 'adServerCheck',
        sortable: false,
        label: 'Ad Server Check',
        disablePadding: false,
      });
    }

    cells.push({
      id: 'active',
      sortable: true,
      label: 'Status',
      disablePadding: false,
    });

    return cells;
  }, [showQCFunctionality, showBWSync, flags.SYNC_CHECKS_ENABLED]);

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    id: number
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = creativeAdGroups.map(
        group => group.creative_object.id
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const creativeStatusIconRender = (status: boolean) => {
    if (status === true) {
      return <Chip label="Active" color="primary" />;
    }

    return <Chip label="Inactive" />;
  };

  async function getAdGroup() {
    try {
      const response = await useGet(`/lineitems/${adGroupId}`);
      setAdGroup(response);
    } catch (error) {
      console.log(error);
    }
  }

  const getCSOwner = async () => {
    try {
      const response = await useGet(adContext.cs_owner);

      setCSOwner(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getCreativeAdGroups = async () => {
    useGetAll(
      '/creative_lineitems',
      [],
      results => {
        return setCreativeAdGroups(results);
      },
      {
        params: {
          line_item_id: adGroupId,
          expand: 'creative',
        },
      }
    );
  };

  useEffect(() => {
    if (adContext.id !== null) {
      getAdGroup();
      getCreativeAdGroups();
      getCSOwner();
    }
  }, [adContext]);

  return (
    <>
      {showQCFunctionality && (
        <CreativeQCAlerts
          email={csOwner?.email || 'support@tvscientific.com'}
          items={creativeAdGroups.map(item => item.creative_object) || []}
        />
      )}

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px"
      >
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">
            Creative
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAllClick}
            />

            <TableBody>
              {stableSort(
                creativeAdGroups as never[],
                getComparator(order, orderBy)
              ).map((creativeAdGroup, index) => {
                const { creative_object: creative } =
                  creativeAdGroup as unknown as CreativeAdGroupV1;
                const isItemSelected = isSelected(creative.id);
                const labelId = `creative-table-checkbox-${index}`;
                const {
                  id,
                  stats_caches_by_lineitem_id: stats_caches,
                  qc = {} as CreativeAdGroupV1['creative_object']['qc'],
                } = creative;
                const stats = stats_caches
                  ? stats_caches[adGroupId]
                  : ({} as Record<string, never>);

                const mediaUploadDate =
                  creative.media_upload_date === null
                    ? 'n/a'
                    : moment(creative.media_upload_date).format('l');
                const mediaUploadDateTitle =
                  creative.media_upload_date === null
                    ? 'n/a'
                    : moment(creative.media_upload_date).format(
                        'YYYY-MM-DD HH:mm'
                      );

                const QCStatusTooltip =
                  qcStatusTooltips[
                    qc.status as keyof typeof qcStatusTooltips
                  ] || (() => null);

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={`${creative.id}-${index}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    data-testid="creative-table-row"
                  >
                    <TableCell
                      className={clsx(classes.height)}
                      padding="checkbox"
                    >
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>{creative.id}</TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      <Link
                        data-testid={`creative-name-${creative.name}`}
                        className={classes.link}
                        to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${creative.id}`}
                      >
                        {creative.name}
                      </Link>
                    </TableCell>
                    <TableCell
                      className={clsx(classes.tableCell, classes.noWrap)}
                      align="left"
                    >
                      {adGroup?.name}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="left"
                      title={mediaUploadDateTitle}
                    >
                      {mediaUploadDate}
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {stats && stats.total_spend != null
                        ? formatToUSD(stats.total_spend)
                        : '-'}
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {stats && stats.visit_count != null
                        ? stats.visit_count
                        : '-'}
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {stats && stats.cost_per_visit != null
                        ? formatToUSD(stats.cost_per_visit)
                        : '-'}
                    </TableCell>

                    {showQCFunctionality && (
                      <TableCell
                        className={clsx(classes.stats)}
                        align="center"
                      >
                        <QCStatusTooltip items={qc.messages} />
                      </TableCell>
                    )}

                    {showBWSync && flags.SYNC_CHECKS_ENABLED && (
                      <TableCell
                        className={clsx(classes.tableCell)}
                        align="left"
                      >
                        {renderBWSyncStatus(creative)}
                      </TableCell>
                    )}

                    <TableCell className={clsx(classes.height)} align="left">
                      {creativeStatusIconRender(creative.active)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
