import { isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { UnsavedChanges } from '../components/UnsavedChanges';
import { useWizardQuery } from './useWizardQuery';
import { ThemeProvider, useTheme } from '@mui/material';
import { GoToOptions, NavigationLink, RouteBase } from '../types';
import { useModal } from '@components/hooks/modal';

export const useWizardNavigation = ({
  sections = [],
  sectionNames = [],
  base,
}: {
  sections: string[];
  sectionNames: string[];
  base?: RouteBase;
}) => {
  const theme = useTheme();

  const entitiesStatesRef = useRef(new Map());
  const {
    activeSection,
    activePane,
    goToSection,
    goToCampaign,
    campaignId,
    ...other
  } = useWizardQuery({
    base,
  });

  const sectionKeysToNames: Record<string, string> = useMemo(
    () =>
      sections.reduce(
        (acc, section, index) => ({
          ...acc,
          [section]: sectionNames[index],
        }),
        {}
      ),
    [sections, sectionNames]
  );

  const { setModal } = useModal();

  const currentSectionIndex = useMemo(
    () => sections.findIndex(s => s === activeSection),
    [sections, activeSection]
  );

  const nextSectionIndex = useMemo(
    () =>
      currentSectionIndex === sections.length - 1
        ? undefined
        : currentSectionIndex + 1,
    [currentSectionIndex]
  );

  const prevSectionIndex = useMemo(
    () => (currentSectionIndex === 0 ? undefined : currentSectionIndex - 1),
    [currentSectionIndex]
  );

  const goTo = useCallback(
    (
      {
        campaignId,
        sectionId,
        paneId,
      }: {
        campaignId?: NavigationLink;
        sectionId?: NavigationLink;
        paneId?: NavigationLink;
        base?: RouteBase;
      },
      {
        checkDirty = true,
        workflowName = '',
        base: forceBase,
      }: GoToOptions = {}
    ) => {
      const goer = () =>
        campaignId
          ? goToCampaign(
              { campaignId, sectionId, paneId },
              {
                base: forceBase ?? base,
              }
            )
          : goToSection(
              { sectionId, paneId },
              {
                base: forceBase ?? base,
              }
            );

      if (checkDirty) {
        const { dirty: dirtySection } =
          entitiesStatesRef.current.get(activeSection) || {};

        const { dirty: dirtyPane } =
          entitiesStatesRef.current.get(activePane) || {};

        const actualWorkflowName = isEmpty(workflowName)
          ? sectionKeysToNames[activeSection]
          : workflowName;

        if (
          (sectionId !== activeSection && dirtySection) ||
          (sectionId === activeSection && dirtyPane)
        ) {
          setModal({
            isOpen: true,
            component: () => (
              <ThemeProvider theme={theme}>
                <UnsavedChanges
                  onCancel={() => setModal(null)}
                  workflowName={actualWorkflowName}
                  onConfirm={() => {
                    goer();
                    // This needs to happen last, otherwise the modal will unmount and the onConfirm won't finish
                    setModal(null);
                  }}
                />
              </ThemeProvider>
            ),
          });

          return;
        }
      }

      goer();
    },
    [goToSection, goToCampaign, sectionKeysToNames]
  );

  const hasPrev = useMemo(
    () => !isUndefined(prevSectionIndex) && prevSectionIndex !== -1,
    [prevSectionIndex, sections]
  );

  const hasNext = useMemo(
    () => !isUndefined(nextSectionIndex) && !!sections[nextSectionIndex],
    [nextSectionIndex, sections]
  );

  const prevSection = useMemo(
    () => (prevSectionIndex ? sections[prevSectionIndex] : undefined),
    [prevSectionIndex, sections]
  );

  const nextSection = useMemo(
    () => (nextSectionIndex ? sections[nextSectionIndex] : undefined),
    [nextSectionIndex, sections]
  );

  const goToNext = useCallback(
    (options?: GoToOptions) => {
      if (hasNext) {
        goTo(
          {
            campaignId,
            sectionId: nextSection,
          },
          options
        );
      }
    },
    [sections, campaignId, hasNext, nextSection, currentSectionIndex]
  );

  const goToPrev = useCallback(
    (options?: GoToOptions) => {
      if (hasPrev) {
        goTo({ campaignId, sectionId: prevSection }, options);
      }
    },
    [sections, hasPrev, campaignId, currentSectionIndex, prevSection]
  );

  const handleUpdateEntityState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (entityId: string, status: Record<string, any>) => {
      entitiesStatesRef.current = new Map(
        entitiesStatesRef.current.set(entityId, status)
      );
    },
    []
  );

  const handeToggleSection = (providedSectionId: string) => {
    if (providedSectionId !== activeSection) {
      goTo({
        campaignId,
        sectionId: providedSectionId,
      });

      return;
    }

    goToCampaign({ campaignId });
  };

  const handleHidePane = (options?: GoToOptions) => {
    goTo(
      {
        campaignId,
        sectionId: activeSection,
      },
      options
    );
  };

  return {
    goToNext,
    goToPrev,
    goTo,
    hasNext,
    hasPrev,
    activeSection,
    activePane,
    campaignId,
    nextSectionIndex,
    prevSectionIndex,
    nextSection,
    prevSection,
    hidePane: handleHidePane,
    toggleSection: handeToggleSection,
    updateEntityState: handleUpdateEntityState,
    ...other,
  };
};
