import { createContext } from 'react';

interface ModalContextType {
  setModal: (_: unknown) => void;
}

const ModalContext = createContext<ModalContextType>({
  setModal: () => undefined,
});

ModalContext.displayName = 'ModalContext';

export default ModalContext;
