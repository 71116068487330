import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubwayNodeProps } from './types';
import { iconList } from './constants';
import { StyledSubwayNodeButton } from './styles';

export const SubwayNode = ({
  label,
  render,
  status,
  onClick,
  ...props
}: SubwayNodeProps) => {
  return (
    <StyledSubwayNodeButton
      startIcon={render ? null : <FontAwesomeIcon icon={iconList[status]} />}
      disableRipple
      status={status}
      onClick={onClick}
      {...props}
    >
      {render ?? label}
    </StyledSubwayNodeButton>
  );
};
