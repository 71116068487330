import AdvertiserContext from '@components/AdvertiserContext';
import { useCopy } from '@hooks/copy';
import { useCurrentSession } from '@hooks/currentSession';
import { useFlags } from '@hooks/flags';
import { useContext, useEffect, useMemo } from 'react';
import { Copies, fields, objectives } from '../constants';
import { Copies as AdvancedDeliveryOptionsFormCopies } from '../../AdvancedDeliveryOptionsForm/constants';
import { useSection } from './useSection';
import {
  getObjectivesOptions,
  getRecommendedCampaignObjectForAdvertiser,
} from '../utils';

export const useObjectivesSection = () => {
  const { currentAdvertiser } = useCurrentSession();
  const { advertiserevent_set = [], campaign_objective_types } =
    useContext(AdvertiserContext);

  const Copy = useCopy(Copies);

  const AdvancedDeliveryOptionsFormCopy = useCopy(
    AdvancedDeliveryOptionsFormCopies
  );

  const { flags, Flags } = useFlags();

  const trackingIsSetUp = advertiserevent_set.length > 0;

  const { form } = useSection();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const [advancedOptions, objective, objectiveGoalEvent, objectiveGoalType] =
    watch([
      fields.advancedOptions.path,
      fields.objective.path,
      fields.objectiveGoalEvent.path,
      fields.objectiveGoalType.path,
    ]);

  const objectiveGoalsAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS];

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentType,
    experimentPlan,
    freqCaps,
  } = advancedOptions || {};

  const recommendedCampaignObjective = useMemo(
    () => getRecommendedCampaignObjectForAdvertiser(currentAdvertiser),
    [currentAdvertiser]
  );

  const objectivesOptions = useMemo(
    () =>
      getObjectivesOptions({
        advertiserObjectives: campaign_objective_types,
        control,
        copies: Copy,
        objectiveGoalsAllowed,
        objectiveGoalEventSet: advertiserevent_set,
        recommendedCampaignObjective,
        setValue,
      }),
    [
      campaign_objective_types,
      control,
      Copy,
      objectiveGoalsAllowed,
      advertiserevent_set,
      recommendedCampaignObjective,
      setValue,
    ]
  );

  const handleObjectivesChange = () => {
    setValue(
      fields.objectiveGoalEvent.path,
      fields.objectiveGoalEvent.defaultValue,
      { shouldDirty: true }
    );
    setValue(
      fields.objectiveGoalType.path,
      fields.objectiveGoalType.defaultValue,
      { shouldDirty: true }
    );
    setValue(
      fields.objectiveGoalValueMultiplier.path,
      fields.objectiveGoalValueMultiplier.defaultValue,
      { shouldDirty: true }
    );
    setValue(
      fields.objectiveGoalValueCost.path,
      fields.objectiveGoalValueCost.defaultValue,
      { shouldDirty: true }
    );
  };

  useEffect(() => {
    const objectiveObject = Object.entries(objectives).find(
      ([_, o]) => o.value === objective
    )?.[1];

    if (!objectiveObject) return;

    if (
      objectiveObject.hasGoalEvent &&
      !objectiveGoalEvent &&
      !objectiveGoalType &&
      advertiserevent_set.length
    ) {
      setValue(fields.objectiveGoalEvent.path, advertiserevent_set[0].id, {
        shouldValidate: true,
      });

      if (objectiveObject.goalTypes.length) {
        setTimeout(() => {
          setValue(
            fields.objectiveGoalType.path,
            objectiveObject.goalTypes[0].value,
            {
              shouldValidate: true,
            }
          );
          setValue(objectiveObject.goalTypes[0].goalValue.path, null, {
            shouldValidate: true,
          });
        });
      }
    }
  }, [objective, advertiserevent_set, objectiveGoalEvent, objectiveGoalType]);

  return {
    Copy,
    form,
    objectivesOptions,
    trackingIsSetUp,
    errors,
    onObjectivesChange: handleObjectivesChange,
    isIncremental,
    isExperiment,
    isCapAds,
    experimentType,
    experimentPlan,
    freqCaps,
    AdvancedDeliveryOptionsFormCopy,
  };
};
