import { useContext } from 'react';
import AdvertiserContext from '../AdvertiserContext';
import { Tenants } from '../../constants';

// Define the structure of a tenant
interface Tenant {
  id: number;
  url: string;
}

export const useTenant = (): Tenant => {
  const adContext = useContext(AdvertiserContext);
  const theme = adContext.theme as unknown as keyof typeof Tenants;

  return Tenants[theme];
};
