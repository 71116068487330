import React, { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import ListFilterForm from "@components/forms/ListFilterForm";
import { IFormValues, IItem } from '@components/forms/ListFilterForm/types';
import { useGetCampaignsV2 } from '@apis/campaigns';

interface ICampaignIdFilterProps {
  onSubmit: (params: Record<string, string>) => void;
  data: Record<string, string>;
}

const CampaignIdFilter: React.FC<ICampaignIdFilterProps> = ({
  onSubmit,
  data
}) => {
  const [searchValue, setSearchValue] = useState('');

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    
    if (searchValue) {
      params.id = searchValue;
    }
    
    if (data.adGroupIds) {
      params.lineitem_ids = data.adGroupIds;
    }
    
    return params;
  }, [searchValue, data.adGroupIds]);

  const { items: campaigns, isLoading } = useGetCampaignsV2(queryParams, {
    revalidateOnMount: true
  });

  const formData = useMemo(() => {
    const selectedIds = data.campaignIds ? data.campaignIds?.split(',').map(Number) : [];

    return {
      items: campaigns.map((campaign: IItem) => ({
        id: campaign.id,
        name: campaign.id.toString(),
        checked: selectedIds.includes(campaign.id),
      })),
      selectedIds,
      isLoading,
    };
  }, [campaigns, data.campaignIds, isLoading]);

  const handleSearch = useCallback(
    debounce((value: string | undefined) => {
      setSearchValue(value || '');
    }, 300),
    []
  );

  const handleSubmit = (formData: IFormValues) => {
    onSubmit({
      campaignIds: formData.selectedIds.join(',')
    });
  };

  return (
    <ListFilterForm
      fieldName="Campaign ID"
      data={formData}
      onSearch={handleSearch}
      onSubmit={handleSubmit}
    />
  );
};

export default CampaignIdFilter;
