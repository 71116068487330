import { useMemo } from 'react';
import { reverse } from 'lodash';
import clsx from 'clsx';
import { DataGridColumn } from '../types';
import { PinnedPosition } from '../constants';

/**
 * Hook that provides pinning styles to pin columns left or right
 * Important Note: If you have columns that are pinned, we will disable virtualization
 * in the grid.  We have to do this because the pinned columns will not render
 * correctly if virtualization is enabled and they are scrolled horizontally
 * by the user.
 *
 * Pinned columns currently also require a `width` attribute to be set so we can determine how to
 * position them.  This is not ideal and we should look to improve this in the future.
 *
 * Important note: We currently only support one right pinned column at this time
 */
const CHECKBOX_COLUMN_WIDTH = 50;
export const usePinnedColumns = ({
  columns,
  checkboxSelection,
}: {
  columns: DataGridColumn[];
  checkboxSelection?: boolean;
}) => {
  const hasPinnedColumns = columns.some(column => column.pinned);

  if (!hasPinnedColumns) {
    return {
      adjustedColumns: columns,
      pinnedStyles: {},
      hasPinnedColumns: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pinnedStyles: Record<string, any> = {
    '.MuiDataGrid-cellCheckbox': {
      position: 'sticky',
      backgroundColor: 'white',
      zIndex: 3,
      left: 0,
    },
    '.MuiDataGrid-row:nth-child(even) .MuiDataGrid-cell--pinnedLeft': {
      backgroundColor: 'rgba(250, 250, 250, 1)',
    },
    '.MuiDataGrid-row:nth-child(even) .MuiDataGrid-cell--pinnedRight': {
      backgroundColor: 'rgba(250, 250, 250, 1)',
    },
    '.MuiDataGrid-columnHeaderCheckbox': {
      position: 'sticky',
      backgroundColor: 'white',
      zIndex: 3,
      left: 0,
    },
    '.pinned-left-last': {
      boxShadow: '4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
    },
    '.pinned-right-first': {
      boxShadow: '-4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
    },
  };

  const adjustedColumns = useMemo(() => {
    let leftPinnedCount = 0;
    let leftOffset = checkboxSelection ? CHECKBOX_COLUMN_WIDTH : 0;
    let rightPinnedCount = 0;
    let rightOffset = 0;

    // Map over the left pinned columns and add the styles and classes necessary to pin them to the left side of the grid
    const leftColumns = columns
      .filter(column => column.pinned === PinnedPosition.LEFT)
      .map((column, index) => {
        const pinnedPrefix = `left-pinned-${leftPinnedCount}`;
        pinnedStyles[`.${pinnedPrefix}`] = { left: `${leftOffset}px` };
        const isLastLeftPinnedColumn =
          index ===
          columns.filter(col => col.pinned === PinnedPosition.LEFT).length -
            1;

        const headerClassName = clsx(
          pinnedPrefix,
          isLastLeftPinnedColumn ? 'pinned-left-last' : '',
          'MuiDataGrid-columnHeader--pinnedLeft'
        );
        const cellClassName = clsx(
          pinnedPrefix,
          isLastLeftPinnedColumn ? 'pinned-left-last' : '',
          'MuiDataGrid-cell--pinnedLeft'
        );

        leftPinnedCount++;
        leftOffset += column.width ?? 0;
        return {
          ...column,
          cellClassName,
          headerClassName,
        };
      });

    // Reverse the right columns and map over them to add the styles and classes necessary to pin them to the right side of the grid
    const rightColumns = reverse(
      columns.filter(column => column.pinned === PinnedPosition.RIGHT)
    ).map((column, index) => {
      const pinnedPrefix = `right-pinned-${rightPinnedCount}`;
      pinnedStyles[`.${pinnedPrefix}`] = { right: `${rightOffset}px` };
      const isLastRightPinnedColumn =
        index ===
        columns.filter(col => col.pinned === PinnedPosition.RIGHT).length - 1;

      const headerClassName = clsx(
        pinnedPrefix,
        isLastRightPinnedColumn ? 'pinned-right-first' : '',
        'MuiDataGrid-columnHeader--pinnedRight'
      );
      const cellClassName = clsx(
        pinnedPrefix,
        isLastRightPinnedColumn ? 'pinned-right-first' : '',
        'MuiDataGrid-cell--pinnedRight'
      );

      rightPinnedCount++;
      rightOffset += column.width ?? 0;
      return {
        ...column,
        cellClassName,
        headerClassName,
      };
    });
    const unpinnedColumns = columns.filter(column => !column.pinned);

    return [...leftColumns, ...unpinnedColumns, ...rightColumns];
  }, [columns]);

  return { adjustedColumns, pinnedStyles, hasPinnedColumns };
};
