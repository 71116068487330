import React from 'react';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ButtonCard } from '@v2/components/campaign/ButtonCard';
import { Icon } from '@v2/components/campaign/Icon';
import PropTypes from 'prop-types';

import { useCopy } from '@hooks/copy';
import { Copies, CampaignTypes } from './constants';

export const CampaignTypeForm = ({ onSubmit, ...props }) => {
  const Copy = useCopy(Copies);
  const theme = useTheme();

  return (
    <Box
      sx={{ background: 'common.white' }}
      {...props}
    >
      <Stack
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <ButtonCard
          testId='campaign-type-quick-start'
          disabled
          id="Streaming TV"
          cardHeaderEnabled={false}
          cardHeaderTitle="RECOMMENDED"
          customIcon={Copy.quickStartCustomIcon}
          iconColor="green.8"
          iconMargin="55px 0 35px"
          cardFooterTitle={Copy.quickStartFooter}
          cardFooterIcon={faBolt}
          cardFooterBGColor={theme.palette.tip['light']}
          onSelect={() => onSubmit(CampaignTypes.QUICK_START)}
        >
          <Box sx={{
            position: 'absolute',
            top: -24,
            right: -24,
          }}>
            <Icon name="comingSoon"/>
          </Box>
          <Box px={4}>
            <Typography variant="h4" mb={1}>
              {Copy.quickStartHeader}
            </Typography>
            {Copy.quickStartBody}
          </Box>
        </ButtonCard>

        <ButtonCard
          id='Linear TV'
          testId="campaign-type-from-scratch"
          cardHeaderEnabled={false}
          customIcon={Copy.fromScratchCustomIcon}
          iconName={Copy.fromScratchIcon}
          iconColor="grey.5"
          iconMargin="55px 0 35px"
          onSelect={() => onSubmit(CampaignTypes.FROM_SCRATCH)}
        >
          <Box padding='0 30px'>
            <Typography variant="h4" mb={1}>
              {Copy.fromScratchHeader}
            </Typography>
            {Copy.fromScratchBody}
          </Box>
        </ButtonCard>
      </Stack>
    </Box>
  );
};

CampaignTypeForm.defaultProps = {
  data: {
    type: '',
  },
};

CampaignTypeForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
