/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatMap, intersection, keys } from 'lodash';
import { useSnackbar } from 'notistack';

import { additional_error_keys } from './constants';
import {
  BareFetcher,
  Key,
  Middleware,
  SWRConfiguration,
  SWRHook,
  SWRResponse,
} from 'swr';

const getMessage = <Data extends Record<string, any>>(data: Data) =>
  Object.values(data);

export const useNotification: Middleware =
  (useSWRNext: SWRHook) =>
  <Data = any, Error = any>(
    key: Key,
    fetcher: BareFetcher<Data> | null,
    config: SWRConfiguration<Data, Error>
  ): SWRResponse<Data, Error> => {
    const { enqueueSnackbar } = useSnackbar();
    const modifiedFetcher: BareFetcher<Data> = async (...args) => {
      return (fetcher?.(...args) as Promise<Data>)?.catch?.(e => {
        const { response } = e as { response: { data: Record<string, any> } };

        if (!response) {
          throw e;
        }

        if (
          !intersection(
            [
              'error',
              'detail',
              'errorType',
              'errorDetail',
              'non_field_errors',
              ...additional_error_keys,
            ],
            keys(response.data)
          ).length
        ) {
          throw e;
        }

        const messages = flatMap([...getMessage(response.data)]);

        messages.forEach(message =>
          enqueueSnackbar(message, { variant: 'error' })
        );

        throw e;
      });
    };

    return useSWRNext(key, modifiedFetcher, config);
  };
