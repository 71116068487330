import { defaultAdGroupName } from '@apis/adGroups';
import { Campaign } from '@local-types';
import { sections } from '@components/Wizard';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { createContext } from 'react';
import { AdGroup } from '@local-types/group';

export const sectionGetter = {
  group: (id: number | string) => String(id),
  staticGroup: (id: number | string) => `static-${id}`,
  review: () => sections.review,
  campaign: () => sections.campaign,
};

export const getEmptyGroup = ({
  name = defaultAdGroupName(),
  id = Number(uniqueId()),
  outOfSync,
  campaign,
}: {
  name?: string;
  outOfSync?: boolean | null;
  id?: number;
  campaign?: Campaign;
} = {}) =>
  ({
    name,
    status: 'draft',
    id,
    outOfSync,
    temporary: true,
    campaign: Number(campaign?.id),
    start_date: campaign?.start_date,
    end_date: campaign?.end_date,
  } as unknown as AdGroup);

export const WizardPageContext = createContext<{
  groups: AdGroup[];
  retargetingAdGroups: AdGroup[];
  campaign: Campaign;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  localAssets: Map<string, any>;
}>({
  groups: [],
  retargetingAdGroups: [],
  campaign: {} as Campaign,
  localAssets: new Map(),
});

export const getEmptyRetargetingGroup = (campaign: Campaign) => {
  const timestamp = moment().format('MMMM Do, h:mm a');
  return {
    campaign: Number(campaign?.id ?? uniqueId()),
    creatives: [],
    end_date: campaign?.end_date,
    id: 1,
    isDisplay: true,
    name: `${timestamp} Ad Group - Display Retargeting`,
    start_date: campaign?.start_date,
    temporary: true,
  };
};

export const bwSyncParams = {
  sync: 1,
};
