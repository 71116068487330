import React from 'react';
import { ChipSelectorItemProps } from './types';
import { CircularProgress, Stack } from '@mui/material';
import { useChipSelectorItem } from './hooks/useChipSelectorItem';
import { faRemove } from '@fortawesome/pro-solid-svg-icons';
import {
  StyledChipSelectorItem,
  StyledChipSelectorItemRemover,
} from './styles';
import ChipPopper from './ChipPopper';

export const ChipSelectorItem = ({
  value,
  onChange,
  onRemove,
  types,
  type,
  index,
  ...props
}: ChipSelectorItemProps) => {
  const {
    change,
    escape,
    baseRef,
    setBaseRef,
    isEditing,
    edit,
    remove,
    data,
    errored,
    isLoading,
    inputValue,
    setInputValue,
    onKeyboardEvent,
    focus,
  } = useChipSelectorItem({
    onChange,
    onRemove,
    value,
    types,
    type,
    index,
  });

  return (
    <StyledChipSelectorItem
      {...props}
      tabIndex={0}
      onKeyDown={onKeyboardEvent}
      onClick={e => {
        e.stopPropagation();
        props.onClick?.(e);
      }}
      onFocus={() => {
        focus(index);
      }}
      data-testid={value.id}
      errored={errored}
      focused={isEditing}
      ref={el => {
        setBaseRef(el);
      }}
    >
      <Stack onDoubleClick={edit}>
        {isEditing ? (
          <ChipPopper
            onEscape={() => {
              escape();
            }}
            onBlur={escape}
            anchorEl={baseRef}
            onSelect={v => {
              change(v[0]);
              escape();
            }}
            onInput={setInputValue}
            value={inputValue}
            isLoading={isLoading}
            type={type}
            data={data}
          />
        ) : (
          value.text
        )}
      </Stack>
      {value.temporary ? (
        <CircularProgress size={15} />
      ) : (
        <StyledChipSelectorItemRemover
          onClick={remove}
          errored={errored}
          icon={faRemove}
        />
      )}
    </StyledChipSelectorItem>
  );
};
