import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';

import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { entityStatus } from '@components/CampaignWizard/constants';
import { SectionInfo } from '@components/SectionInfo';
import { useWizardPageContentData } from '@components/containers/WizardPage/hooks/useWizardPageContentData';
import { SelectDate } from '@v2/components/ui/SelectDate';
import { SelectTime } from '@v2/components/ui/SelectTime';
import { SectionActions } from '../../../../components/Wizard';
import { BudgetSection } from './Sections/BudgetSection';
import { NameSection } from './Sections/NameSection';
import { ObjectiveSection } from './Sections/ObjectiveSection';
import { fields, sections } from './constants';
import { useCampaignForm } from './hooks/useCampaignForm';
import { useSectionsInfo } from './hooks/useSectionsInfo';
import { ScrollTopContainer, StyledFormContainer } from './styles';

const dateFields = ['startDate', 'startTime', 'endDate', 'endTime'];
const budgetTypeField = 'budgetType';

const CampaignForm = props => {
  const {
    onAdvancedCampaignDeliveryOptionsClick,
    onSubmit,
    onFormChange,
    onRetargetDisplayToggleChange,
    campaignStaticDisplayAdGroups,
    campaignId,
    ...rest
  } = props;

  const { nextSectionContent } = useWizardPageContentData();

  const {
    control,
    handleSubmit,
    formState,
    watch,
    trigger,
    setValue,
    reset,
  } = useFormContext();

  const { isValid, dirtyFields } = formState;

  const [
    budgetType,
    startDate,
    startTime,
    endDate,
    endTime,
    advancedOptions,
    status,
  ] = watch([budgetTypeField, ...dateFields, 'advancedOptions', 'status']);

  const { isIncremental } = advancedOptions || {};

  const {
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    minEndDate,
    ref,
    validatorAction,
  } = useCampaignForm({
    campaignStaticDisplayAdGroups,
    isIncremental,
    startDate,
    startTime,
  });

  // set time to default if the selected date is in the future
  const setDefaultTime = (selectedDate, path, defaultTime) => {
    const currentDate = moment().endOf('day');
    if (currentDate.isBefore(selectedDate)) {
      setValue(path, defaultTime ?? moment().startOf('day'), {
        shouldValidate: true,
      });
    }
  };

  const handleCancel = () => {
    reset();
  };

  useEffect(() => {
    onFormChange?.({
      formState,
      reset,
      trigger,
    });
  }, [formState, reset, trigger]);

  useEffect(() => {
    if ([...dateFields, budgetTypeField].some(field => dirtyFields[field])) {
      trigger(dateFields);
    }
  }, [budgetType, startDate, endDate, startTime, endTime, trigger]);

  const { info } = useSectionsInfo();

  return (
    <ScrollTopContainer
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="on"
      ref={ref}
      {...rest}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={12}>
          <StyledFormContainer>
            {' '}
            {/* 2rem spacing */}
            <NameSection />
            <BudgetSection campaignId={campaignId} />
            <Stack direction="column" spacing={3}>
              <SectionInfo infoBlocks={info} sub={sections.dates} />
              <Stack direction="row" spacing={3} xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <Controller
                    name={fields.startDate.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectDate
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          setDefaultTime(e, fields.startTime.path);
                        }}
                        disabled={status === entityStatus.active}
                        fieldState={fieldState}
                        id="start-date"
                        label="Start Date"
                        testId="start-date-picker"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <Controller
                    name={fields.startTime.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectTime
                        {...field}
                        disabled={status === entityStatus.active}
                        fieldState={fieldState}
                        id="start-time"
                        label="Start Time (ET)"
                        testId="start-time-picker"
                      />
                    )}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={3} xs={6}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name={fields.endDate.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectDate
                        {...field}
                        clearable={!!fields.endDate.clearable}
                        minDate={minEndDate}
                        fieldState={fieldState}
                        id="end-date"
                        label="End Date"
                        testId="end-date-picker"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name={fields.endTime.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectTime
                        {...field}
                        clearable={!!fields.endTime.clearable}
                        disabled={!endDate}
                        fieldState={fieldState}
                        id="end-time"
                        label="End Time (ET)"
                        testId="end-time-picker"
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
            {dragsAllowed && (
              <Box>
                <Controller
                  name={fields.displayPct.path}
                  control={control}
                  render={({ field: displayPctField }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...displayPctField}
                          checked={displayPctField.value}
                          disabled={dragToggleDisabled}
                          id="retarget-switch"
                          name="displayPct"
                          data-testid="campaign-retarget-switch"
                          onChange={event =>
                            onRetargetDisplayToggleChange(
                              event,
                              displayPctField
                            )
                          }
                        />
                      }
                      label={
                        <>
                          {dragToggleText}
                          <Tooltip title={dragTooltip} placement="top" arrow>
                            <IconButton size="small">
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    />
                  )}
                />
              </Box>
            )}
            <ObjectiveSection
              withAdvancedDeliveryOptions
              onAdvancedCampaignDeliveryOptionsClick={
                onAdvancedCampaignDeliveryOptionsClick
              }
            />
          </StyledFormContainer>
        </Grid>

        <SectionActions
          prevLabel={'Cancel'}
          nextLabel={campaignId ? 'Save' : nextSectionContent?.name ?? 'Save'}
          disabled={!isValid}
          prevCallback={handleCancel}
          nextCallback={handleSubmit(onSubmit)}
          nextDisabled={!isValid}
          showValidator={!isValid}
          validatorCallback={validatorAction}
        />
      </Grid>
    </ScrollTopContainer>
  );
};

// Define PropTypes
CampaignForm.propTypes = {
  campaignStaticDisplayAdGroups: PropTypes.array,
  onAdvancedCampaignDeliveryOptionsClick: PropTypes.func.isRequired,
  onRetargetDisplayToggleChange: PropTypes.func.isRequired,
  ObjectiveOptionArgs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
  campaignId: PropTypes.number,
};

CampaignForm.displayName = 'CampaignForm';

export default memo(CampaignForm);
