import React, { useState, useContext, useEffect, useMemo, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { Container, Paper } from '@mui/material';

import AppHeader from '../AppHeader';
import AdvertiserWizard from '../AdvertiserWizard';
import AdvertiserCostPerClick from '../AdvertiserCostPerClick';
import AdvertiserContext from '../AdvertiserContext';
import OrganizationBilling from '../OrganizationBilling';
import OrganizationInfo from '../OrganizationInfo';
import OrganizationType from '../OrganizationType';
import SensitiveCategories from '../SensitiveCategories';
import { useAPI } from '../hooks/api';
import { useAdvertisers, useUser, useCurrentSession, useTenant } from '../hooks';
import { Tenant, Themes } from '../../constants';
import { omit } from 'lodash';
import { useDomain } from '@hooks/domain';

const PREFIX = 'SetupAdvertiser';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`
};

const StyledAppHeader = styled(AppHeader)(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },

  [`& .${classes.paper}`]: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: 19,
    minHeight: 750,
    position: 'relative',
    marginTop: -10,
  }
}));

const SetupAdvertiser = () => {

  const history = useHistory();
  const adContext = useContext(AdvertiserContext);
  const locationDomain = useDomain();
  const userContext = useUser();
  const location = useLocation();

  const { useGet, usePatch, usePost } = useAPI();
  const { createAdvertiser, updateAdvertiser, formatAdvertiserUrl } = useAdvertisers();
  const { updateCurrentAdvertiser } = useCurrentSession();
  const tenant = useTenant();

  const [theme, setTheme] = useState(null);
  const [progress, setProgress] = useState('orgType');

  const [org, setOrg] = useState({});
  const [billingAcct, setBillingAcct] = useState({});

  // Org Type
  const [orgType, setOrgType] = useState('');

  // Org Info
  const [name, setName] = useState(localStorage.getItem('company') || '');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [dba, setDBA] = useState('');
  const [attrModel, setAttrModel] = useState('');
  const [attrWindow, setAttrWindow] = useState('');

  // Org Billing
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [billingName, setBillingName] = useState('');
  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [billingAccountName, setBillingAccountName] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingAccountEmail, setBillingAccountEmail] = useState('');
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [fundingType, setFundingType] = useState('');

  // Ad Account
  // TODO: replace with form
  const [aaName, setAAName] = useState('');
  const [adName, setAdName] = useState('');
  const [domain, setDomain] = useState('');
  const [sfAdvertiserId, setSfAdvertiserId] = useState('');
  const [sfAgencyId, setSfAgencyId] = useState('');
  const [category, setCategory] = useState('');
  const [aaKpi, setAAKpi] = useState('');
  const [aaBudget, setAABudget] = useState('');
  const [looker, setLooker] = useState(null);
  const [aaAttrModel, setAAAttrModel] = useState('');
  const [aaAttrWindow, setAAAttrWindow] = useState('');
  const [sensitiveCategory, setSensitiveCategory] = useState(null);

  // Alerting user to save before leaving page
  useBeforeunload(e => {
    switch (progress) {
      case 'orgType': {
        if (orgType !== '') {
          e.preventDefault();
        }
        break;
      }
      case 'orgInfo': {
        const hasUnsavedInfo = [
          name,
          address1,
          city,
          state,
          zip,
          phone,
          contact,
          email,
          attrModel,
          attrWindow,
        ].some(i => i !== '');

        if (hasUnsavedInfo) {
          e.preventDefault();
        }
        break;
      }
      case 'orgBilling': {
        const hasUnsavedBilling = [
          billingName,
          billingAddress1,
          billingCity,
          billingState,
          billingZip,
          billingAccountName,
          billingAccountEmail,
        ].some(i => i !== '');

        if (hasUnsavedBilling) {
          e.preventDefault();
        }
        break;
      }
    }
  });

  useEffect(() => {
    const userType = localStorage.getItem('userType');
    const name = localStorage.getItem('company');

    updateCurrentAdvertiser({name, userType});
    adContext.updateAdvertiser({
      name,
      userType,
    });
  }, []);

  useEffect(() => {
    if (adContext && adContext.id) {
      getProfilesRefresh();
    }
  }, [adContext]);

  useEffect(() => {
    if (selectedCard && locationDomain.default) {
      setFundingType('CC');
    }
  }, [locationDomain.default, selectedCard]);

  useEffect(() => {
    if (locationDomain.peacock) {
      setFundingType('Invoice');
    }
  }, [locationDomain.peacock]);

  useEffect(() => {
    if (locationDomain.peacock) {
      setLooker('NBCU');
    }
  }, [locationDomain.peacock]);

  useEffect(() => {
    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }

    if (!isSameAddress) {
      setBillingName('');
      setBillingAddress1('');
      setBillingAddress2('');
      setBillingCity('');
      setBillingState('');
      setBillingZip('');
      setBillingAccountName('');
      setBillingAccountEmail('');
    }
  }, [isSameAddress]);

  useEffect(() => {
    if (adContext && adContext.theme) {
      setTheme(adContext.theme);
    }
  }, [adContext]);

  const orgData = useMemo(() => {
    const SFDCAccountId = localStorage.getItem('invite');

    const dataObj = {
      name,
      business_phone: phone,
      business_physical_address: `${address1}, ${
        address2 && `${address2}, `
      }${city}, ${state}, ${zip}`,
      default_attribution_model: attrModel || null,
      default_attribution_window: attrWindow || null,
      primary_tenant: tenant.url,
      sfdc_account_id: SFDCAccountId,
      type: orgType,
      owner: userContext?.user?.url,
      street_address: address1,
      street_address_2: address2,
      postal_code: zip,
      city,
      state,
    };

    if (dba && dba !== '') {
      dataObj.doing_business_as = dba;
    }

    return dataObj;
  }, [
    orgType,
    phone,
    address1,
    address2,
    city,
    dba,
    state,
    zip,
    attrModel,
    attrWindow,
    location.state,
    userContext?.user?.url,
  ]);

  const adAcctData = useMemo(() => {
    return {
      ad_account_name: aaName,
      ad_account_budget: aaBudget || null,
      attribution_model: aaAttrModel,
      attribution_window: aaAttrWindow,
      name: adName,
      category,
      domain: formatAdvertiserUrl(domain),
      looker_experience: looker,
      looker_validated: true,
      primary_kpi: aaKpi,
      regulated_brand_type: sensitiveCategory || null,
      primary_org: org && org.url ? org.url : null,
      type: orgType,
      owner: userContext?.user?.url,
    };
  }, [
    aaName,
    aaBudget,
    adName,
    aaKpi,
    aaAttrWindow,
    aaAttrModel,
    category,
    domain,
    looker,
    orgType,
    sensitiveCategory,
    org?.url,
    userContext?.user?.url,
  ]);

  const billingData = useMemo(() => {
    return {
      billing_method: tenant.id === Tenant.DEFAULT ? 'CC' : 'Invoice',
      name: billingName,
      parent_org: org.url,
      city: billingCity,
      secondary_contact: billingAccountEmail,
      secondary_first_name: billingFirstName,
      secondary_last_name: billingLastName,
      state: billingState,
      postal_code: billingZip,
      primary_contact: userContext?.user?.url,
      street_address: billingAddress1,
      street_address_2: billingAddress2,
      funding_type_cc_allowed: !!selectedCard && fundingType === 'CC',
      funding_type_invoice_allowed: fundingType === 'Invoice',
      default_payment_profile: selectedCard,
    };
  }, [
    tenant,
    org,
    billingName,
    billingAccountName,
    billingFirstName,
    billingLastName,
    billingAccountEmail,
    billingCity,
    billingState,
    billingZip,
    billingAddress1,
    billingAddress2,
    selectedCard,
    fundingType,
    userContext?.user?.url,
  ]);

  async function getProfilesRefresh() {
    try {
      const res = await useGet(`/payment_profiles/refresh`);

      if (res && res.results && res.results.length > 0) {
        const reversed = res.results.reverse();

        setSelectedCard(reversed[0].url);
        setCards(reversed);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const setProgressAfterBilling = () => {
    if(theme === Themes.NBCU) {
      history.push('/');
    } else {
      setProgress('lastClick');
    }
  }

  const handleOrgInfo = () => {
    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }
  };

  const handleBillingAccount = async () => {
    if (!isSkip) {
      try {
        const res = billingAcct && billingAcct.id
          ? await usePatch(`/billing_accounts/${billingAcct.id}`, omit(billingData, ['primary_org']))
          : await usePost('/billing_accounts', billingData)

        setBillingAcct(res.data);
        console.log('res from saving Billing Account', res);
        // update ad account with billing info
        await usePatch(`/advertisers/${adContext.id}/`, { billing_account: res.data.url });
      } catch (err) {
        console.error('Error saving billing account', err);
      }
    } else {
      setProgressAfterBilling();
    }
  };

  const handleSaveOrg = async () => {
    handleOrgInfo();
    try {
      const response =
        org && org.id
          ? await usePatch(`/organizations/${org.id}`, omit(orgData, ['primary_tenant', 'type']))
          : await usePost('/organizations/', orgData);

      if (response) {
        console.log('response in saving org', response);
        localStorage.removeItem('invite');

        adContext.updateAdvertiser({
          ...adContext,
          primary_org: response.data.url,
        });

        setOrg(response.data);
        setProgress('adWizard');
      }

      return response;
    } catch (error) {
      console.log('error in saving Organization', error);
    }
  };

  const handleSaveAdAccount = async data => {
    const dataObj = {
      ...adAcctData,
      ...data,
    };

    let filteredDataObj = dataObj;

    if (Themes.NBCU) {
      filteredDataObj = omit(dataObj, [
        'attribution_model',
        'attribution_window',
      ]);
    }

    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }

    try {
      const res =
        adContext && adContext.id
          ? await updateAdvertiser(adContext.id, {
            ...filteredDataObj,
            primary_org: undefined,
          })
          : await createAdvertiser(filteredDataObj);

      return res;
    } catch (error) {
      console.log('error in creating advertiser', error);
      return error;
    }
  };

  return (
    <Suspense fallback={<div>loading...</div>}>
      <StyledAppHeader hidenav="true">
        <Container
          maxWidth="lg"
          className={clsx(classes.container, 'SetupWizard')}
        >
          <Paper
            className={clsx(classes.paper, '--background-colorbar')}
            elevation={12}
          >
            {progress === 'orgType' && (
              <OrganizationType
                onNext={() => setProgress('orgInfo')}
                orgType={orgType}
                setOrgType={setOrgType}
              />
            )}

            {progress === 'orgInfo' && (
              <OrganizationInfo
                onBack={() => setProgress('orgType')}
                org={{
                  name,
                  setName,
                  address1,
                  setAddress1,
                  address2,
                  setAddress2,
                  city,
                  setCity,
                  state,
                  setState,
                  zip,
                  setZip,
                  phone,
                  setPhone,
                  contact,
                  setContact,
                  email,
                  setEmail,
                  dba,
                  setDBA,
                  attrModel,
                  setAttrModel,
                  attrWindow,
                  setAttrWindow,
                }}
                handleSaveOrg={handleSaveOrg}
              />
            )}

            {progress === 'orgBilling' && (
              <OrganizationBilling
                advertiserId={adContext.id}
                isSameAddress={isSameAddress}
                setIsSameAddress={setIsSameAddress}
                isInvoice={isInvoice}
                isSkip={isSkip}
                setIsSkip={setIsSkip}
                setIsInvoice={setIsInvoice}
                cards={cards}
                paymentProvider={org.payment_provider}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                getProfilesRefresh={getProfilesRefresh}
                onBack={() => setProgress('adWizard')}
                onNext={setProgressAfterBilling}
                handleBillingAccount={handleBillingAccount}
                name={name}
                org={{
                  billingName,
                  setBillingName,
                  billingAddress1,
                  setBillingAddress1,
                  billingAddress2,
                  setBillingAddress2,
                  billingCity,
                  setBillingCity,
                  billingState,
                  setBillingState,
                  billingZip,
                  setBillingZip,
                  billingAccountName,
                  setBillingAccountName,
                  billingFirstName,
                  setBillingFirstName,
                  billingLastName,
                  setBillingLastName,
                  billingAccountEmail,
                  setBillingAccountEmail,
                }}
              />
            )}

            {progress === 'adWizard' && (
              <AdvertiserWizard
                adAccount={{
                  aaName,
                  setAAName,
                  adName,
                  setAdName,
                  domain,
                  setDomain,
                  category,
                  setCategory,
                  aaKpi,
                  setAAKpi,
                  aaBudget,
                  setAABudget,
                  looker,
                  setLooker,
                  aaAttrModel,
                  setAAAttrModel,
                  aaAttrWindow,
                  setAAAttrWindow,
                  sfAdvertiserId,
                  setSfAdvertiserId,
                  sfAgencyId,
                  setSfAgencyId,
                }}
                showKPI
                showModel
                showWindow
                showSalesforce={theme === Themes.NBCU}
                handleSaveAdAccount={handleSaveAdAccount}
                onNext={() => setProgress('orgBilling')}
                onBack={() => setProgress('orgInfo')}
              />
            )}

            {progress === 'lastClick' && (
              <AdvertiserCostPerClick
                onNext={() => setProgress('sensitive')}
                onBack={() => setProgress('orgBilling')}
              />
            )}

            {progress === 'sensitive' && (
              <SensitiveCategories
                sensitiveCategory={sensitiveCategory}
                setSensitiveCategory={setSensitiveCategory}
                handleSaveAdAccount={handleSaveAdAccount}
                onNext={() => history.push('/campaign-setup')}
                onBack={() => setProgress('lastClick')}
              />
            )}
          </Paper>
        </Container>
      </StyledAppHeader>
    </Suspense>
  );
};

export default SetupAdvertiser;
