import { Stack, styled } from '@mui/material';

export const SectionHeaderStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(2, 4),
  margin: theme.spacing(-6.5, -7, 2.5, -7),
  color: theme.palette.common.black,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  zIndex: 999,
}));

export const NumericHeaderStyled = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(-3.25),
  marginRight: theme.spacing(-3.25),
  height: theme.spacing(2.5),
  alignItems: 'center',
}));

export const NumericHeaderNumberStyled = styled('svg')(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  fill: theme.palette.common.black,
  '& text': {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const NumericHeaderConnectorStyled = styled(Stack)(({ theme }) => ({
  '& span': {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.grey[9],
  },
}));
