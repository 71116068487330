/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWizardContext } from './useWizardContext';
import { GoToOptions } from '../types';

export const useWizardPane = ({
  key,
  workflowName,
}: {
  key: string;
  workflowName: string;
}) => {
  const { navigation } = useWizardContext();

  const active = navigation.activePane === key;

  const { updateEntityState, hidePane } = navigation;

  return {
    ...navigation,
    hidePane: (options: GoToOptions) => {
      hidePane({
        ...options,
        workflowName,
      });
    },
    updateEntityState: (state: any) => updateEntityState(key, state),
    active,
  };
};
