import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LoginCallback as OktaLoginCallback } from '@okta/okta-react';
import { WizardSelector } from '@components/containers/WizardSelector';
import { WizardPage } from '@components/containers/WizardPage';
import { WizardQuickstartPage } from '@components/containers/WizardPage/WizardQuickstartPage';
import AcceptInvitePage from '@components/containers/AcceptInvitePage/AcceptInvitePage';
import JoinPage from '@components/containers/JoinPage/JoinPage';
import AdGroupsIndexPage from '@components/containers/AdGroupsIndexPage/AdGroupsIndexPage';
import BusinessManager from '@components/containers/BusinessManagerPage';
import CampaignsPage from '@components/containers/CampaignsPage';
import CreativeDetailPage from '@components/containers/CreativeDetailPage';
import CreativeIndexPage from '@components/containers/CreativeIndexPage';
import CreativeIndexPerAdvertiserPage from '@components/containers/CreativeIndexPerAdvertiserPage';
import DisplayDetailPage from '@components/containers/DisplayDetailPage';
import DisplayIndexPage from '@components/containers/DisplayIndexPage';
import ReportsPage from '@components/containers/ReportsPage';
import RequestAccountPage from '@components/containers/RequestAccountPage';
import SettingsPage from '@components/containers/SettingsPage';
import SetupWizardPage from '@components/containers/SetupWizardPage';
import SetupAdvertiserPage from '@components/containers/SetupAdvertiserPage';
import TermsAndConditionsPage from '@components/containers/TermsAndConditionsPage';
import SupportPage from '@components/containers/SupportPage';
import TrackingPage from '@components/containers/TrackingPage';
import MemberPage from '@components/containers/MemberPage';
import NBCULoginCallbackPage from '@components/containers/NBCULoginCallbackPage';
import QuietOktaLoginCallbackPage from '@components/containers/QuietOktaLoginCallbackPage';
import Dashboard from '@components/Dashboard';
import { Logout } from '@components/containers/Logout';
import LoginPage from '@components/containers/LoginPage';
import NotFoundPage from '@components/NotFoundPage';
import PrivateRoute from '@components/PrivateRoute';
import SecureRoute from '@components/SecureRoute';
import { Flags, RoutePaths, Themes } from './constants';

export const createRoutes = dependencies => {
  const {
    key,
    isAuthenticated,
    theme,
    flags,
    canViewBusinessManager,
    canViewCampaignSetup,
    canUpdateCampaigns,
    canViewReports,
    isPrivateRouteAllowed,
    isFlagsLoading,
  } = dependencies;

  return [
    {
      type: Route,
      exact: true,
      path: RoutePaths.LOGIN,
      component: LoginPage,
    },
    {
      type: Route,
      exact: true,
      path: RoutePaths.ACCEPT_INVITE,
      component: AcceptInvitePage,
    },
    {
      type: Route,
      exact: true,
      path: RoutePaths.JOIN,
      component: JoinPage,
    },
    {
      type: Route,
      path: '/home',
      render: () => <Redirect to={RoutePaths.CAMPAIGNS} />,
    },
    {
      type: PrivateRoute,
      exact: true,
      path: RoutePaths.HOME,
      component: RequestAccountPage,
      isAllowed: !isAuthenticated && theme === Themes.NBCU,
      redirectTo: RoutePaths.CAMPAIGNS,
    },
    {
      type: SecureRoute,
      path: RoutePaths.MEMBER,
      component: MemberPage,
    },
    {
      type: SecureRoute,
      path: RoutePaths.ACCOUNT_SETUP,
      component: SetupAdvertiserPage,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.OLD_CAMPAIGN_SETUP,
      route: SecureRoute,
      component: SetupWizardPage,
      isAllowed: canViewCampaignSetup,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.OLD_CAMPAIGN_EDIT,
      route: SecureRoute,
      component: SetupWizardPage,
      isAllowed: canUpdateCampaigns,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.REPORTS,
      route: SecureRoute,
      component: ReportsPage,
      isAllowed: canViewReports,
      redirectTo: RoutePaths.HOME,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.TRACKING,
      route: SecureRoute,
      component: TrackingPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: SecureRoute,
      path: RoutePaths.DASHBOARD,
      component: Dashboard,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.WIZARD_SELECTOR,
      route: SecureRoute,
      component: WizardSelector,
      isAllowed:
        isPrivateRouteAllowed && flags[Flags.USER_GETS_VERTICAL_CAMPAIGN],
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.CAMPAIGN_QUICKSTART,
      route: SecureRoute,
      component: WizardQuickstartPage,
      isAllowed:
        isPrivateRouteAllowed &&
        flags[Flags.USER_GETS_VERTICAL_CAMPAIGN] &&
        flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART],
      isLoading: isFlagsLoading,
      redirectTo: RoutePaths.WIZARD,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.CAMPAIGN_REWORK,
      route: SecureRoute,
      component: WizardPage,
      isAllowed:
        isPrivateRouteAllowed && flags[Flags.USER_GETS_VERTICAL_CAMPAIGN],
      isLoading: isFlagsLoading,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      exact: true,
      path: RoutePaths.CAMPAIGNS,
      route: SecureRoute,
      component: CampaignsPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.CREATIVE_DETAIL,
      route: SecureRoute,
      component: CreativeDetailPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.CREATIVE_INDEX,
      route: SecureRoute,
      component: CreativeIndexPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.DISPLAY_DETAIL,
      route: SecureRoute,
      component: DisplayDetailPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.DISPLAY_INDEX,
      route: SecureRoute,
      component: DisplayIndexPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.AD_GROUPS,
      route: SecureRoute,
      component: AdGroupsIndexPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      key,
      type: PrivateRoute,
      path: RoutePaths.CREATIVES,
      route: SecureRoute,
      component: CreativeIndexPerAdvertiserPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.DISPLAYS,
      route: SecureRoute,
      component: CreativeIndexPerAdvertiserPage,
      isAllowed: isPrivateRouteAllowed,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: PrivateRoute,
      path: RoutePaths.MANAGER,
      route: SecureRoute,
      component: BusinessManager,
      isAllowed: canViewBusinessManager,
      redirectTo: RoutePaths.REPORTS,
    },
    {
      type: SecureRoute,
      path: RoutePaths.SETTINGS,
      component: SettingsPage,
    },
    {
      type: SecureRoute,
      path: RoutePaths.SUPPORT,
      component: SupportPage,
    },
    {
      type: Route,
      path: RoutePaths.TERMS_AND_CONDITIONS,
      component: TermsAndConditionsPage,
    },
    {
      type: Route,
      path: RoutePaths.NBCU_LOGIN_CALLBACK,
      component: NBCULoginCallbackPage,
    },
    {
      type: Route,
      path: RoutePaths.QUIET_LOGIN_CALLBACK,
      component: QuietOktaLoginCallbackPage,
    },
    {
      type: Route,
      path: RoutePaths.LOGIN_CALLBACK,
      component: OktaLoginCallback,
    },
    {
      type: Route,
      path: RoutePaths.LOGOUT,
      component: Logout,
    },
    {
      type: Route,
      component: NotFoundPage,
    },
  ];
};
