import Papa from 'papaparse';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import sanitize from 'sanitize-html';
import { useCaretOffset } from './useCaretOffset';
import { GeoZipItem } from '../types';

const sanitizeConfig = {
  allowedTags: ['span'],
  allowedAttributes: {
    span: ['class'],
  },
};

export const useAddZipCodes = ({
  onChange,
  value = [],
  wrongZipCodes,
}: {
  onChange: (codes: GeoZipItem[]) => void;
  value: GeoZipItem[];
  wrongZipCodes: string[];
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [showDropzone, setShowDropzone] = useState(false);
  const { caretOffset, saveSelection, restoreSelection } =
    useCaretOffset(editorRef);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleZipCodeChange = (e: any) => {
    // Replace &nbsp with space to prevent unnecessary re-render
    const zipCodeText = e.target.value.replace(/&nbsp;/g, ' ');
    const san = sanitize(zipCodeText, sanitizeConfig);

    caretOffset.current = saveSelection();

    // Split zip codes by commas and filter out empty values
    const zipCodeArray = san
      .replace(/<\/?(span)(.*?)>/gi, '')
      .split(',')
      .map((item: string) => item.trim());

    // Process each zip code
    const codes = zipCodeArray.map((zip: string) => {
      return {
        zip: zip.trim(),
        valid: zip.length === 5,
      };
    });

    // Update the form or component state with the processed zip codes
    onChange(codes);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        complete: (result: { data: string[] }) => {
          const zipCodesFromFile = result.data.map(row => row[0]);
          const codes = zipCodesFromFile.map(zip => ({ zip }));
          onChange(codes);
          setShowDropzone(false);
        },
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragOver: event => event.preventDefault(),
    onDragEnter: () => setShowDropzone(true),
    onDragLeave: () => setShowDropzone(false),
    accept: '.csv',
  });

  const actualValue = useMemo(
    () =>
      value.map(v => ({
        ...v,
        valid: !wrongZipCodes.includes(v.zip),
      })),
    [value, wrongZipCodes],
  );

  const html = useMemo(
    () =>
      actualValue
        .map(({ zip, valid }) =>
          valid
            ? `<span>${zip}</span>`
            : `<span class="errored">${zip}</span>`,
        )
        .join(','),
    [actualValue],
  );

  useEffect(() => {
    if (caretOffset.current) restoreSelection(caretOffset.current);
  }, [html]);

  return {
    editorRef,
    actualValue,
    getRootProps,
    getInputProps,
    html,
    isDragActive,
    handleZipCodeChange,
    showDropzone,
  };
};
