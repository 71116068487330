import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { routes } from '../routes';
import { sections } from '../constants/general';
import {
  NavigationLink,
  RouteBase,
  WizardQueryRedirectAdditionalOptions,
} from '../types';

export const useWizardQuery = (
  {
    base = routes.campaign,
  }: {
    base?: RouteBase;
  } = {
    base: routes.campaign,
  }
) => {
  const {
    campaignId,
    sectionId = sections.campaign,
    paneId,
  } = useParams<{
    campaignId: string;
    sectionId: string;
    paneId: string;
  }>();

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const usefulParams = {
    activeSection: sectionId,
    activeCampaign: campaignId,
    activePane: paneId,
  };

  const handleHistoryPush = (pathname: string) => {
    history.replace({ pathname, search: query.toString() });
  };

  const goToCampaign = (
    {
      campaignId,
      sectionId,
      paneId,
    }: {
      campaignId: NavigationLink;
      sectionId?: NavigationLink;
      paneId?: NavigationLink;
    },
    options: WizardQueryRedirectAdditionalOptions = {}
  ) => {
    handleHistoryPush(
      generatePath(options.base ?? base, {
        campaignId,
        sectionId,
        paneId,
      })
    );
  };

  const goToSection = (
    {
      sectionId,
      paneId,
    }: {
      sectionId?: NavigationLink;
      paneId?: NavigationLink;
    },
    options: WizardQueryRedirectAdditionalOptions = {}
  ) => {
    if (!sectionId) {
      return;
    }
    handleHistoryPush(
      generatePath(options.base ?? base, {
        campaignId,
        sectionId,
        paneId,
      })
    );
  };

  return {
    query,
    goToCampaign,
    goToSection,
    campaignId,
    groupId: sectionId,
    ...usefulParams,
  };
};
