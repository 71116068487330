import moment from 'moment-timezone';
import { convertToStatus, removeNullValues } from '../../../utils';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';
import {
  fields,
  objectives,
} from '@v2/components/forms/CampaignForm/constants';
import { fields as advancedOptionsFields } from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';
import { getLockedStatus } from '@v2/components/forms/CampaignForm/utils';

const getIsObjectiveLocked = ({ objective, advertiser }) =>
  getLockedStatus({
    option: objective,
    disabledObjectiveOptions: {
      objectiveGoalEventSet: advertiser.advertiserevent_set,
    },
  });

const getInitialObjective = ({ values, advertiser }) => {
  const recommendedCampaignObjective =
    getRecommendedCampaignObjectForAdvertiser(advertiser);

  const initialObjective = values?.objective ?? recommendedCampaignObjective;

  if (
    initialObjective &&
    !getIsObjectiveLocked({
      objective: Object.values(objectives).find(
        o => o.value === initialObjective
      ),
      advertiser,
    })
  ) {
    return initialObjective;
  }

  return (
    Object.values(objectives).find(o => {
      return !getIsObjectiveLocked({ objective: o, advertiser });
    })?.value ?? null
  );
};

export const parseApiDataToFormValues = ({
  campaign: values,
  advertiser,
}) => {
  const {
    id,
    active,
    name,
    startDate,
    endDate,
    budget,
    dailyBudget,
    dailyBudgetCap,
    draft,
    experimentType,
    experimentPlan,
    freqCaps = [],
    objectiveGoal,
    objectiveGoalEvent,
    objectiveValueCost,
    objectiveValueMultiplier,
    displayPct,
    budgetType,
  } = values || {};

  const initialObjective = getInitialObjective({
    values,
    advertiser,
  });

  return {
    [fields.name.path]: name,
    [fields.advertiserName.path]: advertiser.name,
    [fields.draft.path]: draft,
    [fields.active.path]: active,
    [fields.status.path]: convertToStatus(values),
    [fields.startDate.path]: startDate
      ? moment(startDate)
      : id
      ? null
      : undefined,
    [fields.startTime.path]: startDate
      ? moment(startDate)
      : id
      ? null
      : undefined,
    [fields.endDate.path]: endDate ? moment(endDate) : id ? null : undefined,
    [fields.endTime.path]: endDate ? moment(endDate) : id ? null : undefined,
    [fields.budgetType.path]: budgetType,
    [fields.lifetimeBudget.path]: budget,
    [fields.dailyBudget.path]: dailyBudget,
    [fields.dailyBudgetCap.path]: dailyBudgetCap,
    [fields.displayPct.path]: Number(displayPct) ? true : undefined,
    [fields.objective.path]: initialObjective,
    [fields.objectiveGoalEvent.path]: objectiveGoalEvent,
    [fields.objectiveGoalType.path]: objectiveGoal,
    [fields.objectiveGoalValueCost.path]: objectiveValueCost,
    [fields.objectiveGoalValueMultiplier.path]: objectiveValueMultiplier,
    [fields.advancedOptions.path]: {
      isIncremental: !!experimentType,
      isExperiment: !!experimentPlan,
      isCapAds: freqCaps.length > 0,
      freqCaps,
      experimentPlan: experimentPlan ?? undefined,
      experimentType: experimentType ?? undefined,
    },
  };
};

export const mustIncludePostFields = ['objective_goal'];
export const mustIncludePatchields = ['objective_goal', 'end_date'];

export const filter =
  (mandatoryFields = []) =>
  (key, value) => {
    if (mandatoryFields.includes(key)) {
      return true;
    }
    return value;
  };

export const parseFormValuesToApiData = (
  updatedValues,
  formValues,
  campaignId,
  options = {
    filter: filter(mustIncludePostFields),
  }
) => {
  // if daily budget, lifetime budget or daily budget cap is in form values, make sure
  // budget type is as well
  if (
    fields.dailyBudget.path in updatedValues ||
    fields.lifetimeBudget.path in updatedValues ||
    fields.dailyBudgetCap.path in updatedValues
  ) {
    updatedValues.budgetType = formValues.budgetType;
  }

  const {
    name,
    advertiser,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    objectiveGoalEvent,
    objectiveGoalType,
    objectiveGoalValueCost,
    objectiveGoalValueMultiplier,
    advancedOptions,
    active,
    draft,
  } = updatedValues || {};

  if (
    fields.endDate.path in updatedValues ||
    fields.endTime.path in updatedValues
  ) {
    updatedValues.endTime = updatedValues.endTime || formValues.endTime;
    updatedValues.endDate = updatedValues.endDate || formValues.endDate;
  }

  if (
    fields.startDate.path in updatedValues ||
    fields.startTime.path in updatedValues
  ) {
    updatedValues.startTime = updatedValues.startTime || formValues.startTime;
    updatedValues.startDate = updatedValues.startDate || formValues.startDate;
  }

  const campaignBudgetType = budgetType || formValues.budgetType;

  const data = removeNullValues(
    {
      name,
      budget:
        campaignBudgetType === 'lifetime_budget'
          ? lifetimeBudget
          : dailyBudget,
      budget_type: budgetType,
      start_date: combineDateAndTime(
        updatedValues.startDate,
        updatedValues.startTime
      ),
      end_date:
        combineDateAndTime(updatedValues.endDate, updatedValues.endTime) ??
        null,
      advertiser,
      active,
      draft,
      objective,
      objective_goal_event: objectiveGoalEvent,
      objective_goal: objectiveGoalType,
      objective_value_cost: objectiveGoalValueCost,
      objective_value_multiplier: objectiveGoalValueMultiplier,
    },
    options.filter ?? filter
  );

  // if endDate or endTime have not been updated, don't send a blank end_date
  if (!(fields.endDate.path in updatedValues || fields.endTime.path in updatedValues)) {
    delete data.end_date;
  }

  // setting nullable, empty string fields here
  if (fields.displayPct.path in updatedValues) {
    data.display_pct = updatedValues.displayPct ? 0.1 : 0.0;
  }

  if (fields.dailyBudgetCap.path in updatedValues) {
    data.daily_budget_cap =
      campaignBudgetType === 'lifetime_budget'
        ? updatedValues.dailyBudgetCap
        : null;
  }

  // if the campaign exists, include all goals-related fields in the request
  // that has changed in case some of the fields need to be reset to null
  if (campaignId) {
    if (fields.objectiveGoalEvent.path in updatedValues) {
      data.objective_goal_event = updatedValues.objectiveGoalEvent;
    }

    if (fields.objectiveGoalValueCost.path in updatedValues) {
      data.objective_value_cost = updatedValues.objectiveGoalValueCost;
    }

    if (fields.objectiveGoalValueMultiplier.path in updatedValues) {
      data.objective_value_multiplier =
        updatedValues.objectiveGoalValueMultiplier;
    }
  }

  if (fields.advancedOptions.path in updatedValues) {
    const {
      isCapAds,
      isIncremental,
      isExperiment,
      experimentPlan,
      experimentType,
      freqCaps,
    } = advancedOptions || {};

    if (advancedOptionsFields.freqCaps.path in advancedOptions) {
      data.freq_caps = isCapAds ? removeNullValuesFromFreqCaps(freqCaps) : [];
    }

    if (advancedOptionsFields.experimentPlan.path in advancedOptions) {
      data.experiment_plan = isExperiment ? experimentPlan : null;
    }

    if (advancedOptionsFields.experimentType.path in advancedOptions) {
      data.experiment_type = isIncremental ? experimentType : null;
    }
  }

  return data;
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10)
        );
      });
    }
  }
  return null;
};

const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  const combinedMoment = moment({
    year: dateMoment.year(),
    month: dateMoment.month(),
    day: dateMoment.date(),
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });

  return combinedMoment;
};

const removeNullValuesFromFreqCaps = freqCaps => {
  if (!freqCaps) return;

  return freqCaps.map(freqCap => {
    return removeNullValues(freqCap);
  });
};
