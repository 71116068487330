import { createContext } from 'react';
import { useWizardNavigation } from '../hooks/useWizardNavigation';
import { nodeStatus } from '../components/Subway/constants';
import { Values } from '@local-types/general';

export const WizardContext = createContext<{
  state?: Record<
    string,
    Record<
      string,
      {
        status: Values<typeof nodeStatus>;
      }
    >
  >;
  updateState?: (
    state: Record<
      string,
      Record<
        string,
        {
          status: Values<typeof nodeStatus>;
        }
      >
    >
  ) => void;
  navigation: ReturnType<typeof useWizardNavigation>;
}>({
  state: {},
  updateState: () => void 0,
  navigation: {
    activeSection: '',
    updateEntityState: () => void 0,
    goToNext: () => void 0,
    goToPrev: () => void 0,
    goTo: () => void 0,
    hidePane: () => void 0,
    toggleSection: () => void 0,
    query: new URLSearchParams(),
    groupId: '',
    campaignId: '',
    activeCampaign: '',
    activePane: '',
    hasNext: false,
    hasPrev: false,
    nextSectionIndex: 0,
    prevSectionIndex: 0,
    nextSection: '',
    prevSection: '',
  },
});
