import React from 'react';
import { Grid, GridProps } from '@mui/material';

export const Sections = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & GridProps) => {
  return (
    <Grid {...props} item xs={9}>
      {children}
    </Grid>
  );
};
