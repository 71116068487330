import { GridCellParams } from '@mui/x-data-grid';
import { LineItem } from '@local-types';
import { useBWSync } from '@components/hooks';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import { Box } from '@mui/material';

export const AdGroupSyncCheckRenderer = (
  params: GridCellParams<LineItem>,
) => {
  const { renderBWSyncStatus } = useBWSync();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%',
        width: '100%',
      }}
    >
      {renderBWSyncStatus(params.row, params.row.status !== Statuses.ACTIVE)}
    </Box>
  );
};
