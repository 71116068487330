import { flatMap } from 'lodash';
import { ReactElement } from 'react';

export const getSectionProp = (
  children: ReactElement | ReactElement[],
  propName: string
): string[] =>
  flatMap(
    Array.isArray(children)
      ? children.map(child => getSectionProp(child, propName))
      : [children?.props?.[propName]]
  ).filter(Boolean);

export const getSectionProps = (
  sectionsElements: ReactElement | ReactElement[]
) => {
  return {
    sections: getSectionProp(sectionsElements, 'sectionKey'),
    sectionNames: getSectionProp(sectionsElements, 'sectionName'),
  };
};
