import {
  faCircleCheck,
  faCircleDashed,
  faPlusCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

export const iconList = {
  complete: faCircleCheck,
  add: faPlusCircle,
  pending: faCircleDashed,
  active: faCircleArrowRight,
} as const;

export const nodeStatus = {
  add: 'add',
  pending: 'pending',
  complete: 'complete',
  active: 'active',
} as const;
