import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  fields,
  formSchema,
  defaultValues as initialValues,
} from '../formConfig';
import { transformAdGroupToFields } from '../utils';
import { useRoles } from '@hooks/roles';
import { useDomain } from '@hooks/domain';
import { useCurrentSession } from '@hooks/currentSession';

export const useGroupSectionForm = ({
  adGroup,
  bundles,
  creatives,
  appBundleList,
  targeting,
  audiences,
  campaign,
}) => {
  const {
    currentAdvertiser: {
      bidstrategy_set: bidStrategies,
      bidstrategyevent_set: bidStrategyEventSet,
    },
  } = useCurrentSession();
  const roles = useRoles();
  const domain = useDomain();
  const values = useMemo(
    () =>
      defaults(
        transformAdGroupToFields(adGroup, {
          customInventory: bundles,
          creatives,
          bidStrategies,
          bidStrategyEventSet,
          appBundleList,
          audiences,
          allowedTargeting: targeting,
          campaign,
        }),
        initialValues()
      ),
    [
      adGroup,
      campaign,
      creatives,
      targeting,
      audiences,
      bidStrategies,
      bidStrategyEventSet,
      bundles,
    ]
  );

  const form = useForm({
    resolver: yupResolver(
      formSchema({ campaign, bidStrategies, roles, domain })
    ),
    reValidateMode: 'all',
    mode: 'all',
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { watch, trigger } = form;

  const [inventoryOption, endDate, endTime] = watch([
    fields.inventoryOption.path,
    fields.endDate.path,
    fields.endTime.path,
  ]);

  useEffect(() => {
    if (inventoryOption) {
      trigger(fields.advancedCustomInventory.path);
    }
  }, [trigger, inventoryOption]);

  useEffect(() => {
    if (endDate || endTime) {
      trigger(fields.endDate.path);
      trigger(fields.endTime.path);
    }
  }, [trigger, endDate, endTime]);

  return {
    form,
  };
};
