import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { defaultTheme_v2 } from '@themes/index';
import RealTimeModal, {
  RealTimeModalProps,
  TimeRange,
  Interval,
} from '@components/modals/RealTimeModal/index';
import { useAdGroupWinsReporting } from '@apis/adGroups';

interface RealtimeDeliveryModalProps {
  onClose: () => void;
  data: Omit<
    RealTimeModalProps,
    | 'open'
    | 'isLoading'
    | 'wins'
    | 'data'
    | 'timeRange'
    | 'onTimeRangeChange'
    | 'onClose'
  > & { id: number };
}

const TimeRangeInterval: Record<TimeRange, Interval> = {
  '1h': '15m',
  '1d': '1hr',
};

const RealtimeDeliveryModal: React.FC<RealtimeDeliveryModalProps> = props => {
  const { onClose, data } = props;
  const { id, ...rest } = data || {};

  const [timeRange, setTimeRange] = React.useState<TimeRange>('1h');

  const timeRangeTimestamps = useMemo(() => {
    const now = Date.now();
    let startTime;

    switch (timeRange) {
      case '1h':
        startTime = now - 60 * 60 * 1000;
        break;
      case '1d':
        startTime = now - 24 * 60 * 60 * 1000;
        break;
      default:
        startTime = now;
    }

    return { startTime, endTime: now };
  }, [timeRange]);

  const queryParams = useMemo(() => {
    const { startTime, endTime } = timeRangeTimestamps;

    return {
      interval: TimeRangeInterval[timeRange],
      startDate: moment(startTime).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endTime).format('YYYY-MM-DDTHH:mm:ss'),
    };
  }, [timeRangeTimestamps, timeRange]);

  const { data: reporting, isLoading } = useAdGroupWinsReporting(
    id,
    queryParams,
  );

  const handleTimeRangeChange = (
    _event: React.ChangeEvent<unknown>,
    newTimeRange: RealTimeModalProps['timeRange'],
  ) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <CssBaseline />

      <RealTimeModal
        open
        isLoading={isLoading}
        wins={reporting?.wins || 0}
        data={reporting?.data || []}
        timeRange={timeRange}
        onTimeRangeChange={handleTimeRangeChange}
        onClose={onClose}
        sx={{
          '& > .MuiBox-root': {
            width: '70%',
          },
        }}
        {...rest}
      />
    </ThemeProvider>
  );
};

export default RealtimeDeliveryModal;
