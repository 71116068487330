import React, { createContext, useContext } from 'react';
import { Creative, LineItem } from '@local-types';
import { useAdGroupCreatives } from '@components/hooks/apis/creatives';
import { useGetAdGroup } from '@components/hooks/apis/adGroups';

export interface AdGroupCreativeTableContextProviderProps {
  campaignId: number;
  adGroupId: number;
  children: React.ReactNode;
}

export interface AdGroupCreativeTableContextType
  extends Omit<AdGroupCreativeTableContextProviderProps, 'children'> {
  adGroup: LineItem | null;
  rowCount: number;
  isLoading: boolean;
  adGroupsCreatives: Creative[];
}

export const AdGroupCreativeTableContext = createContext<AdGroupCreativeTableContextType>({
  campaignId: 0,
  adGroupId: 0,
  rowCount: 0,
  adGroup: null,
  adGroupsCreatives: [],
  isLoading: false,
});

export const AdGroupCreativeTableContextProvider = ({
  children,
  campaignId,
  adGroupId,
  ...rest
}: AdGroupCreativeTableContextProviderProps) => {
  const { adGroup } = useGetAdGroup(adGroupId);
  const { adGroupsCreatives, isLoading } = useAdGroupCreatives(adGroup);

  return (
    <AdGroupCreativeTableContext.Provider
      value={{
        ...rest,
        adGroup,
        adGroupsCreatives: adGroupsCreatives ?? [],
        campaignId,
        adGroupId,
        rowCount: adGroupsCreatives?.length ?? 0,
        isLoading,
      }}
    >
      {children}
    </AdGroupCreativeTableContext.Provider>
  );
};

export const useAdGroupCreativeTable = () => {
  return useContext(AdGroupCreativeTableContext);
};
