import React from 'react';
import { Box, Grid } from '@mui/material';
import { WizardProvider } from './WizardProvider';
import { useWizard } from './hooks/useWizard';
import { Sections } from './components';
import moment from 'moment-timezone';
import { getSectionProps } from './utils';
import { routes } from './routes';
import { WizardProps } from './types';

moment.tz.setDefault('America/New_York');

export const Wizard = ({
  children,
  base = routes.campaign,
  ...other
}: WizardProps) => {
  const sectionsWrapper = children.find(child => child.type === Sections);
  const { sections, sectionNames } = getSectionProps(
    sectionsWrapper?.props.children
  );

  const { navigation } = useWizard({ sections, sectionNames, base });

  return (
    <WizardProvider
      state={'state' in other ? other.state : {}}
      updateState={'updateState' in other ? other.updateState : () => {}}
      navigation={navigation}
    >
      <Box sx={{ px: 6, py: 6 }}>
        <Grid container spacing={4} justifyContent="space-between">
          {children}
        </Grid>
      </Box>
    </WizardProvider>
  );
};
