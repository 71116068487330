import React from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import QCStatusCell from '../QCStatusComponents';
import { CreativeAdGroupV1 } from '@local-types';
import { Box } from '@mui/material';

export const CreativeQCStatusCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>
) => {
  const { qc } = params.row;

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <QCStatusCell creative={params.row} qc={qc} />
    </Box>
  );
};
