import { includes } from 'lodash';
import { entityStatus } from './constants';
import moment from 'moment';
import * as yup from 'yup';
import { timeIsSameOrAfterDate } from '@v2/components/ui/SelectDate';
import { BID_STRATEGIES } from '@v2/components/campaign/CampaignAdGroupSection/BidStrategy/constants';

const requiredMessage = 'Required field';
const boundingError =
  "Ad Group dates must be within the Campaign's start and end dates.";
const bidError = 'Please enter a bid';

export const getDate = (date, time) => {
  const momentDate = moment(date);
  const momentTime = moment(time);

  return momentDate.set({
    hours: momentTime.hours(),
    minutes: momentTime.minutes(),
  });
};

export const isDateTimeBefore = (date, time, beforeDate) => {
  const mDate = moment(date),
    bDate = moment(beforeDate);

  const mTime = time ? moment(time) : bDate;

  return getDate(mDate, mTime).isSameOrBefore(bDate);
};

export const startDateSimpleValidation = (
  endDateFieldName,
  { min, max } = {},
) =>
  yup
    .mixed()
    .required(requiredMessage)
    .test(
      'is-date',
      'Start date must be a valid date',
      value => moment.isMoment(value) && value.isValid(),
    )
    .test(
      'is-before-the-end-date',
      'Start date must be before the end date',
      function (value) {
        if (!this.parent[endDateFieldName]) return true;

        return moment(value).isSameOrBefore(
          moment(this.parent[endDateFieldName]),
          'day',
        );
      },
    )
    .test('in-bound-min', boundingError, function (value) {
      // Don't validate ad groups that were already saved
      if (!this.parent.temporary) return true;

      if (!min) return true;

      return moment(value).isSameOrAfter(moment(min));
    })
    .test('in-bound-max', boundingError, function (value) {
      if (!max) return true;

      return moment(value).isSameOrBefore(moment(max));
    });

export const startTimeSimpleValidation = (
  startDateFieldName,
  endDateFieldName,
  endTimeFieldName,
  { min, max } = {},
) =>
  yup
    .mixed()
    .required(requiredMessage)
    .when({
      is: value => !!value,
      then: yup
        .mixed()
        .required(requiredMessage)
        .test(
          'is-date',
          'Start date must be a valid date',
          value => moment.isMoment(value) && value.isValid(),
        ),
    })
    .test(
      'is-before-the-end-date',
      'Start time must be before the end time',
      function (value) {
        if (!this.parent[endDateFieldName]) return true;

        return getDate(this.parent[startDateFieldName], value).isSameOrBefore(
          getDate(
            this.parent[endDateFieldName],
            this.parent[endTimeFieldName],
          ),
        );
      },
    )
    .test('in-bound-min', boundingError, function (value) {
      // Don't validate ad groups that were already saved
      if (!this.parent.temporary) return true;

      if (!min) return true;

      return getDate(this.parent[startDateFieldName], value).isSameOrAfter(
        moment(min),
      );
    })
    .test('in-bound-max', boundingError, function (value) {
      if (!max) return true;

      return getDate(this.parent[startDateFieldName], value).isSameOrBefore(
        moment(max),
      );
    });

export const getMinDate = (startDate, startTime = moment()) => {
  return startDate ? getDate(startDate, startTime) : moment();
};

export const getMinDateAndtime = (startDate, startTime, endDate) => {
  const minEndDate = startDate ? moment(startDate) : moment();
  const minEndTime =
    startTime &&
    startDate &&
    endDate &&
    moment(startDate).isSame(endDate, 'day')
      ? moment(startTime).add({
          minutes: 1,
        })
      : moment();

  return { minEndDate, minEndTime };
};

export const getBoundingDates = (startDate, endDate) => {
  const minStartDate = startDate ? moment(startDate) : moment();

  const minEndDate = moment(minStartDate);
  const maxEndDate = endDate ? moment(endDate) : null;
  const maxStartDate = endDate ? moment(endDate) : null;

  return { minEndDate, maxEndDate, minStartDate, maxStartDate };
};

export const startDateValidation = () =>
  yup
    .mixed()
    .required(requiredMessage)
    .test(
      'is-date',
      'Start date must be a valid date',
      value => moment.isMoment(value) && value.isValid(),
    )
    .when('status', {
      is: v =>
        includes(
          [entityStatus.draft, entityStatus.paused, entityStatus.pending],
          v,
        ),
      then: yup
        .date()
        .test(
          'is-after-current-date',
          'Start date must be today or after',
          function (value) {
            return moment(value).isSameOrAfter(moment().startOf('day'));
          },
        ),
    });

export const startTimeValidation = startDateFieldName =>
  yup
    .mixed()
    .required(requiredMessage)
    .test(
      'is-date',
      'Start date must be a valid date',
      value => moment.isMoment(value) && value.isValid(),
    )
    .when('status', {
      is: v =>
        includes(
          [entityStatus.draft, entityStatus.paused, entityStatus.pending],
          v,
        ),
      then: () =>
        yup
          .date()
          .nullable()
          .when({
            is: value => !!value,
            then: yup
              .date()
              .test(
                'is-after-current-time',
                'Start time must be after current time',
                function (value) {
                  return timeIsSameOrAfterDate(
                    value,
                    this.parent[startDateFieldName],
                  );
                },
              ),
          }),
    });

export const endDateValidation = (
  startDateFieldName,
  endTimeFieldName,
  { min, max } = {},
) =>
  yup
    .mixed()
    .nullable()
    .when({
      is: value => !!value,
      then: yup
        .mixed()
        .test(
          'is-date',
          'Start date must be a valid date',
          value => moment.isMoment(value) && value.isValid(),
        )
        .test(
          'is-after-start-date',
          'End date must be the same or after start date',
          function (value) {
            if (!value) return true;
            return moment(value).isSameOrAfter(
              moment(this.parent[startDateFieldName]).startOf('day'),
            );
          },
        )
        .test('in-bound-min', boundingError, function (value) {
          if (!min) return true;
          return moment(value).isSameOrAfter(moment(min));
        })
        .test('in-bound-max', boundingError, function (value) {
          if (!max) return true;
          const combinedValue = getDate(value, this.parent[endTimeFieldName]);
          return moment(combinedValue).isSameOrBefore(moment(max));
        }),
    });

export const endTimeValidation = (
  startDateFieldName,
  startTimeFieldName,
  endDateFieldName,
  { min, max } = {},
) =>
  yup
    .mixed()
    .nullable()
    .when(endDateFieldName, {
      is: value => !!value,
      otherwise: yup.mixed().nullable(),
      then: yup
        .mixed()
        .required(requiredMessage)
        .test(
          'is-date',
          'Start date must be a valid date',
          value => moment.isMoment(value) && value.isValid(),
        )
        .test(
          'is-after-start-time',
          'End time must be after start time',
          function (value) {
            if (!value) return false;

            return getDate(this.parent[endDateFieldName], value).isAfter(
              getDate(
                this.parent[startDateFieldName],
                this.parent[startTimeFieldName],
              ),
            );
          },
        )
        .test('in-bound-min', boundingError, function (value) {
          if (!min) return true;

          return getDate(this.parent[endDateFieldName], value).isSameOrAfter(
            moment(min),
          );
        })
        .test('in-bound-max', boundingError, function (value) {
          if (!max) return true;

          return getDate(this.parent[endDateFieldName], value).isSameOrBefore(
            moment(max),
          );
        }),
    });

export const getBidStrategyNameToIdMap = bidStrategySet => {
  return Object.values(bidStrategySet).reduce((acc, bs) => {
    if (bs.display_name) {
      acc[bs.display_name] = bs.id;
    }
    return acc;
  }, {});
};

export const bidStrategyCPMValidation = ({ min }) =>
  yup
    .number()
    .typeError(bidError)
    .min(min, `Please provide value above $${min}`)
    .required(bidError);

export const maxCPMValidation = ({
  maxCPMBidEnabledFieldName,
  bidStrategyFieldName,
  bidStrategyToIdMap,
  roles,
  domain,
}) => {
  const isPeacockExternal = domain.peacock && !roles.TENANT_ADMIN;

  return yup
    .number()
    .nullable()
    .when([maxCPMBidEnabledFieldName, bidStrategyFieldName], {
      is: (maxCPMBidEnabled, bidStrategy) =>
        maxCPMBidEnabled === true ||
        [
          bidStrategyToIdMap?.[BID_STRATEGIES.MANUAL_BID],
          bidStrategyToIdMap?.[BID_STRATEGIES.FIXED_CPM],
        ].includes(bidStrategy),
      then: isPeacockExternal
        ? bidStrategyCPMValidation({ min: 22 })
        : yup.number().typeError(bidError).required(bidError),
      otherwise: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        ),
    });
};

export const bidStrategyEventValidation = bidStrategyToIdMap =>
  yup.number().when('bid_strategy', {
    is: bidStrategyToIdMap?.[BID_STRATEGIES.MAX_OUTCOMES],
    then: yup.number().nullable().required('Please select an event'),
    otherwise: yup.number().nullable(),
  });
