import { useContext } from 'react';
import AuthContext from '../../providers/AuthContext';

type AuthUser = {
  sub: string;
  name: string;
  email: string;
};

interface AuthContextType {
  auth: {
    getUser: () => Promise<AuthUser | null>;
  };
  authState: {
    isAuthenticated: boolean;
    accessToken: string;
  };
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth AuthContext was used outside of its Provider');
  }
  return context;
};
