import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
} from '@mui/material';
import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledDangerButton,
  StyledModalWrapper,
  StyledUnderline,
  StyledWarningTypography,
} from './styles';
import { type UnsavedChangesProps } from './types';
import { faClose } from '@fortawesome/pro-solid-svg-icons';

export const UnsavedChanges = ({
  onCancel,
  onConfirm,
  workflowName,
}: UnsavedChangesProps) => {
  return (
    <StyledModalWrapper open onClose={onCancel}>
      <Fade in={true} timeout={350}>
        <StyledContainer disableGutters>
          <StyledCloseButton onClick={onCancel}>
            <StyledCloseIcon icon={faClose} />
          </StyledCloseButton>
          <DialogTitle
            sx={{
              paddingX: 0,
            }}
          >
            Unsaved changes
          </DialogTitle>
          <StyledUnderline />
          <DialogContent
            sx={{
              paddingX: 0,
            }}
          >
            <StyledWarningTypography>
              Are you sure that you want to leave {workflowName}? <br />
              Your unsaved changes will be lost.
            </StyledWarningTypography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} data-testid="cancel-unsaved-changes">
              Cancel
            </Button>
            <StyledDangerButton
              variant="contained"
              color="error"
              onClick={onConfirm}
              data-testid="confirm-unsaved-changes"
            >
              I&apos;m sure
            </StyledDangerButton>
          </DialogActions>
        </StyledContainer>
      </Fade>
    </StyledModalWrapper>
  );
};
