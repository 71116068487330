import React, { ReactNode, useId, useState } from 'react';
import {
  Button,
  InputAdornment,
  Popover,
  PopoverProps,
  Stack,
  TextField,
} from '@mui/material';
import {
  faBarsFilter,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type DataGridFilterMenuItem = {
  id: string;
  label: ReactNode;
  onClick: () => void;
};

export type DataGridFilterProps = {
  onFilterChange: (filter: string) => void;
  filter: string;
  placeholder?: string;
  menuPosition?: 'left' | 'right';
  popoverChildren?: ReactNode;
  popoverProps?: PopoverProps;
  filterLabel?: ReactNode;
};

export const DataGridFilter = ({
  onFilterChange,
  filter,
  filterLabel = 'Filter',
  placeholder = 'Search...',
  menuPosition = 'right',
  popoverChildren,
  popoverProps,
}: DataGridFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = useId();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const ActionMenu = popoverChildren ? (
    <>
      <Button
        data-testid="data-grid-filter-button"
        data-align={menuPosition}
        onClick={handleOpen}
        sx={{
          color: 'primary.main',
          borderStyle: 'solid',
          borderColor: 'grey.300',
          borderRadius: 0,
          ...(menuPosition === 'right' && {
            borderLeftWidth: 1,
          }),
          ...(menuPosition === 'left' && {
            borderRightWidth: 1,
          }),
        }}
        variant="text"
        size="small"
        startIcon={<FontAwesomeIcon icon={faBarsFilter} />}
      >
        {filterLabel}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...popoverProps}
      >
        {popoverChildren}
      </Popover>
    </>
  ) : null;

  return (
    <Stack
      direction={menuPosition === 'right' ? 'row' : 'row-reverse'}
      sx={{
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderWidth: 1,
        borderBottomWidth: 0,
        borderStyle: 'solid',
        borderColor: 'grey.300',
      }}
    >
      <label className="sr-only" htmlFor={id}>Search</label>
      <TextField
        id={id}
        data-testid="data-grid-filter-input"
        sx={{
          flexGrow: 1,
          '.MuiInputBase-root': { borderRadius: 0 },
          '.MuiOutlinedInput-notchedOutline': {
            border: 0,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          },
        }}
        onChange={e => {
          onFilterChange(e.target.value);
        }}
        placeholder={placeholder}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
      />

      {ActionMenu}
    </Stack>
  );
};
