import React from 'react';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { fields } from '../constants';
import { useSection } from '../hooks/useSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';

export const NameSection = () => {
  const {
    form: { control },
  } = useSection();
  return (
    <Stack gap={4}>
      <Controller
        name={fields.name.path}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            {...field}
            id="campaign-name"
            inputProps={{
              'data-testid': 'campaign-name',
            }}
            label="Campaign Name"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name={fields.advertiserName.path}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            {...field}
            id="advertiser-name"
            label="Advertiser Name"
            disabled
            fullWidth
            inputProps={{
              'data-testid': 'advertiser-name',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faLock} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
    </Stack>
  );
};
