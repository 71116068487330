import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OutOfSyncErrorIcon, OutOfSyncDisabledIcon } from '../icons';
import { OutOfSyncTooltip } from '../styledComponents';
import { renderBWOverrideTitle } from './BWOverrideTitle';
import { BWOverrideTooltip } from './styles';

const PREFIX = 'OutOfSync';

const classes = {
  info: `${PREFIX}-info`,
  error: `${PREFIX}-error`,
  fields: `${PREFIX}-fields`,
  fix: `${PREFIX}-fix`,
};

const StyledOutOfSyncTooltip = styled(OutOfSyncTooltip)(
  ({ theme: { spacing, palette } }) => ({
    [`& .${classes.info}`]: {
      display: 'inline-block',
      padding: spacing(1),
    },

    [`& .${classes.error}`]: {
      color: palette.error.main,
      fontWeight: 600,
    },

    [`& .${classes.fields}`]: {
      marginTop: spacing(1),
      fontWeight: 600,
    },

    [`& .${classes.fix}`]: {
      marginTop: spacing(2),
    },
  })
);

const FIELD_LABELS = {
  active: 'Active',
  beeswax_aid: 'BW advertiser ID',
  beeswax_campaign: 'BW campaign',
  beeswax_cid: 'BW campaign ID',
  beeswax_creative: 'BW creative',
  beeswax_crid: 'BW creative ID',
  beeswax_lid: 'BW line item ID',
  beeswax_targeting: 'Targeting',
  beeswax_teid: 'BW targeting expression ID',
  beeswax_vfids: 'BW campaign vendor fees ID',
  budget: 'Budget',
  campaign_budget: 'Campaign budget',
  creative_name: 'Creative name',
  daily_budget: 'Daily budget',
  duration: 'Duration',
  end_date: 'End date',
  preview_url: 'Preview URL',
  video_asset: 'Video asset',
};

const getLabel = key => {
  return FIELD_LABELS[key] || key;
};

const OutOfSync = ({ adGroup, diff, isDisabled, isBWOverride }) => {
  const safeDiff = diff ?? {};
  const diffList = Object.entries(safeDiff);
  const fieldsText = diffList.reduce(
    (acc, item) =>
      acc ? `${acc}, ${getLabel(item[0])}` : `${getLabel(item[0])}`,
    ''
  );

  const errorTitle = (
    <div className={classes.info}>
      <Typography className={classes.error}>
        Out of sync with Beeswax
      </Typography>
      <Typography className={classes.fields}>
        Field(s): {fieldsText}
      </Typography>
      <Typography className={classes.fix}>
        <b>To fix:</b> Update these fields in the tvScientific UI to be
        re-sent to Beeswax
      </Typography>
    </div>
  );

  const bwOverrideTitle =
    adGroup && isBWOverride ? renderBWOverrideTitle({ adGroup }) : null;

  const disabledTitle = (
    <div className={classes.info}>
      <Typography>
        Ad Server Sync status will display once Activated
      </Typography>
    </div>
  );

  return isDisabled ? (
    <StyledOutOfSyncTooltip title={disabledTitle} placement="bottom" arrow>
      <OutOfSyncDisabledIcon data-testid="out-of-sync-disabled-icon" />
    </StyledOutOfSyncTooltip>
  ) : isBWOverride ? (
    <BWOverrideTooltip
      title={bwOverrideTitle}
      placement="top"
      leaveDelay={250}
      arrow
    >
      <OutOfSyncErrorIcon data-testid="out-of-sync-error-icon" />
    </BWOverrideTooltip>
  ) : (
    <StyledOutOfSyncTooltip
      title={errorTitle}
      placement="bottom"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <OutOfSyncErrorIcon data-testid="out-of-sync-error-icon" />
    </StyledOutOfSyncTooltip>
  );
};

OutOfSync.propTypes = {
  adGroup: PropTypes.object,
  diff: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  isBWOverride: PropTypes.bool,
};

export default OutOfSync;
