import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAPI } from '../../hooks/api';
import { useLoader } from '../../hooks/loader';
import {
  formValuesToApi,
  getInitialValues,
  validationSchema,
  parseReferralSourcesToChannels,
} from './formConfig';
import { LookerTypeSelect } from '../shared/LookerTypeSelect';
import { AttributionRoleFieldset } from '../shared/AttributionRoleFieldset';
import { ChannelItem } from './ChannelItem';

const PREFIX = 'ReportingForm';

const classes = {
  submit: `${PREFIX}-submit`,
  successMsg: `${PREFIX}-successMsg`,
  form: `${PREFIX}-form`,
  download: `${PREFIX}-download`,
  dropdown: `${PREFIX}-dropdown`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { spacing, typography, palette }
}) => ({
  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.form}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  [`& .${classes.download}`]: {
    width: 35,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.dropdown}`]: {
    width: '100%',
    maxWidth: '300px',
  }
}));

export const ReportingForm = ({ advertiser, setAdvertiser }) => {

  const { usePatch, useGetAll, usePost, useDelete } = useAPI();
  const { isLoading: isFetching, setIsLoading: setIsFetching } = useLoader();

  const [sources, setSources] = React.useState([]);
  const [sourcesCost, setSourcesCost] = React.useState([]);

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';
  const isSuccess = status === 'success';
  const isLoadingChannels = sources.length === 0 || sourcesCost.length === 0;

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: getInitialValues(advertiser),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(getInitialValues(advertiser));

    useGetAll(`/referral_source_costs/`, [], items => {
      setSourcesCost(items);
    });
  }, [advertiser]);

  useEffect(() => {
    setIsFetching(true);

    useGetAll('/referral_sources', [], items => {
      setSources(items);
      setIsFetching(false);
    });
  }, []);

  useEffect(() => {
    setValue(
      'channels',
      parseReferralSourcesToChannels({ sources, sourcesCost })
    );
  }, [sources, sourcesCost]);

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    try {
      const updateAdvertiserPromise = usePatch(
        `/advertisers/${advertiser.id}/`,
        formValuesToApi(values)
      );

      const updateChannelsPromises = values.channels.map(
        ({ selected, cost, source, cost_per_click }) => {
          const isItemAdded = selected && !cost
          const isItemUpdated = selected && +(cost?.cost_per_click || 0) !== +cost_per_click
          const isItemDeleted = !selected && cost

          if (isItemAdded)
            return usePost('/referral_source_costs', {
              advertiser: advertiser.url,
              referral_source: source.url,
              cost_per_click,
            });

          if (isItemUpdated)
            return usePatch(`/referral_source_costs/${cost.id}`, {
              cost_per_click,
            });

          if (isItemDeleted)
            return useDelete(`/referral_source_costs/${cost.id}`);

          return Promise.resolve()
        }
      );

      const [updateAdvertiser] = await Promise.all([
        updateAdvertiserPromise,
        ...updateChannelsPromises,
      ]);

      setStatus('success');
      setAdvertiser(updateAdvertiser.data);
    } catch (error) {
      setStatus('error', error);
    }
  });

  const channelControl = useFieldArray({
    control,
    name: 'channels',
  });

  return (
    (<Root>
      <form
        className={classes.form}
        noValidate
        onSubmit={onSubmit}
        onReset={() => reset(getInitialValues(advertiser))}
      >
        <Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant="h4">Dashboard Type</Typography>
            </Box>

            <LookerTypeSelect control={control} name="looker" />

            <AttributionRoleFieldset
              control={control}
              primaryOutcomeName="outcome"
              attModelName="attrModel"
              attWindowName="attrWindow"
            />
          </Box>

          {isLoadingChannels && isFetching && <CircularProgress />}
          {!isLoadingChannels && !isFetching && (
            <>
              {channelControl.fields.map((channel, index) => (
                <ChannelItem
                  key={index}
                  channel={channel}
                  onChange={partial => {
                    const newChannel = {
                      ...channelControl.fields[index],
                      ...partial,
                    };
                    channelControl.update(index, newChannel);
                  }}
                />
              ))}
            </>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
          width="100%"
        >
          {isSuccess && (
            <Typography component={'span'} className={classes.successMsg}>
              Your Settings have been saved!
            </Typography>
          )}

          <Button
            disabled={isLoading}
            disableElevation
            disableRipple
            type="reset"
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            className={classes.submit}
            disableElevation={isSuccess}
            disableRipple
            variant="contained"
            type="submit"
            color="secondary"
          >
            {isLoading ? 'Saving...' : 'Save Settings'}
          </Button>
        </Box>
      </form>
    </Root>)
  );
};

ReportingForm.propTypes = {
  advertiser: PropTypes.object.isRequired,
  setAdvertiser: PropTypes.func.isRequired,
};
