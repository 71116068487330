import { BaseSchema, object } from 'yup';
import { fields } from './constants';
import { defaultCampaignName } from './utils';
import { isFunction } from 'lodash';
import { Campaign } from '@local-types/campaign';
import { Field } from './types';
import { type Moment } from 'moment';

export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: 'defaultValue' in value ? value.defaultValue : null,
    }),
    {} as Record<
      string,
      string | null | boolean | Moment | Record<string, unknown>
    >
  );
  // set campaign default name explictly here as it needs real-time update
  defaultVals[fields.name.path] = defaultCampaignName();
  return defaultVals;
};

const objectiveGoalsRelatedPaths = [
  fields.objectiveGoalEvent.path,
  fields.objectiveGoalType.path,
  fields.objectiveGoalValueCost.path,
  fields.objectiveGoalValueMultiplier.path,
];

export const validationSchema = ({
  campaign,
  objectiveGoalsAllowed,
  relatedFields = fields,
}: {
  campaign?: Campaign;
  objectiveGoalsAllowed: boolean;
  relatedFields?: Record<string, Field>;
}) => {
  const schema = Object.keys(relatedFields).reduce((acc, key) => {
    const nextField = relatedFields[key];
    if (
      nextField.rule &&
      (objectiveGoalsAllowed ||
        !objectiveGoalsRelatedPaths.includes(nextField.path))
    ) {
      acc[nextField.path] = isFunction(nextField.rule)
        ? nextField.rule({
            campaign,
          })
        : nextField.rule;
    }
    return acc;
  }, {} as Record<string, BaseSchema>);

  return object().shape(schema);
};
