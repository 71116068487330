import { pick, uniqueId, values } from 'lodash';
import { geoUrl } from '../../../../components/hooks/geo';
import {
  distinctNames,
  distinctToGeoType,
  geoTypes,
  geoTypesToDistinct,
} from './constants';
import {
  DistinctNamesUploadableValues,
  DistinctNamesValues,
  GeoItem,
  GeoSearchParams,
  GeoTypesValues,
  GeoZipItem,
  MapBoxItem,
} from './types';

const validateZipCodeWithMapBox = async (
  zip: string,
  params?: GeoSearchParams
) => {
  try {
    const response = await fetch(geoUrl(zip, params)).then(res => res.json());
    return response.features.length > 0;
  } catch (error) {
    console.error(error);

    return false;
  }
};

export const validateCodes = (codes: GeoZipItem[]): Promise<GeoZipItem[]> => {
  return Promise.all(
    codes
      .filter(({ zip }) => zip)
      .map(async ({ zip }) => {
        if (zip.length !== 5 || isNaN(zip as unknown as number)) {
          return Promise.resolve({ zip, valid: false });
        }
        const valid = await validateZipCodeWithMapBox(zip, {
          types: [geoTypes.postcode],
        });
        return Promise.resolve({ zip, valid });
      })
  );
};

export const idToKey = (id: string) => {
  const [name] = id.split('.');

  return distinctNames[name as keyof typeof distinctNames];
};

export const fromGeoItemResultToZip = (result: GeoItem) => {
  return {
    ...result,
    text: String(
      result.type === distinctNames.zipcodes
        ? result.text.match(/\d+/)?.[0] ?? ''
        : result.text
    ),
  };
};

export const distinctToType = (distinct: GeoItem) => {
  if (/\(DMA\)/.test(distinct.text)) {
    return distinctNames.dma;
  }

  return idToKey(distinct.id);
};

export const mapBoxItemToGeoItem = (item: MapBoxItem): GeoItem => {
  const data = pick(item, [
    'place_name',
    'text',
    'name',
    'label',
    'id',
    'type',
    'code',
  ]) as GeoItem;

  return {
    ...data,
    name: data.name ? data.name : data.place_name,
  };
};

export const getActualField = (value?: GeoItem) => {
  if (!value) return '';

  if (!value.place_name) {
    return value.text ?? value.name ?? '';
  }

  if (value.place_name.endsWith('(DMA)')) {
    return value.place_name;
  }

  const splitted = value.place_name.split(',');
  const placeName = splitted.slice(0, splitted.length - 1).join(',');

  if (value.id.startsWith('place')) {
    return `${placeName} (City)`;
  }

  if (value.id.startsWith('region')) {
    return `${placeName} (State)`;
  }

  return placeName;
};

export const getActualFieldShorten = <T>(value?: GeoItem<T>) => {
  if (!value) return '';

  if (!value.place_name) {
    return value.text ?? value.name ?? '';
  }

  if (value.place_name.endsWith('(DMA)')) {
    return value.place_name.replace(' (DMA)', '');
  }

  const splitted = value.place_name.split(',');

  return splitted.slice(0, splitted.length - 1).join(',');
};

export const getGeoWithTextByDistinct = (geo: GeoItem) => {
  return {
    ...geo,
    text:
      geo.type === distinctNames.zipcodes
        ? geo.text
        : getActualFieldShorten(geo),
  };
};

export const getActualFieldPlain = (value: GeoItem) =>
  value.place_name ?? value.name ?? value.text ?? '';

export const findOption = (options: GeoItem[], value: GeoItem) =>
  options.find(o => getActualField(o) === getActualField(value));

export const getTemporaryGeo = (
  text: string,
  type: DistinctNamesUploadableValues
) => ({
  text,
  id: uniqueId(),
  name: '',
  type,
  temporary: true,
});

export const normalizeGeoItems = (
  geo: Record<DistinctNamesValues, GeoItem<GeoTypesValues>[]>
): Record<DistinctNamesValues, GeoItem[]> => {
  const geoTypesValues = values(geoTypes);
  return Object.entries(geo).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.map(v => {
        const updatedItem = {
          ...v,
          place_name: v.place_name ?? v.name,
          type: geoTypesValues.includes(v.type)
            ? geoTypesToDistinct[v.type]
            : v.type,
        } as GeoItem;

        return fromGeoItemResultToZip({
          ...updatedItem,
          text:
            v.type === geoTypes.country
              ? getActualFieldPlain(updatedItem)
              : getActualFieldShorten(updatedItem),
        });
      }),
    }),
    {} as Record<DistinctNamesValues, GeoItem[]>
  );
};

export const denormalizeGeoItems = (
  geo: Record<DistinctNamesValues, GeoItem[]>
): Record<DistinctNamesValues, GeoItem<GeoTypesValues>[]> => {
  return Object.entries(geo).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.map(v => ({
        ...v,
        type: distinctToGeoType[v.type],
      })),
    }),
    {} as Record<DistinctNamesValues, GeoItem<GeoTypesValues>[]>
  );
};
