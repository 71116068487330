import { useWizardContext } from '@components/Wizard';
import { useContext, useMemo } from 'react';
import { WizardPageContext, sectionGetter } from '../constants';

export const useWizardPageContentData = () => {
  const { groups, retargetingAdGroups } = useContext(WizardPageContext);
  const {
    navigation: { nextSectionIndex, nextSection },
  } = useWizardContext();

  const sectionsContentList = [...groups, ...retargetingAdGroups];

  const nextSectionContent = useMemo(() => {
    switch (nextSection) {
      case sectionGetter.review():
        return nextSection;
      default:
        return sectionsContentList[nextSectionIndex! - 1];
    }
  }, [nextSectionIndex, sectionsContentList, nextSection]);

  return {
    nextSectionContent,
  };
};
