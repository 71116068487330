import React from 'react';

import { AccordionSummary, AccordionSummaryProps } from '@mui/material';

export const SectionSummary = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & AccordionSummaryProps) => {
  return (
    <AccordionSummary
      {...props}
      expandIcon={null}
      aria-controls="panel-content"
      id="panel-header"
    >
      {children}
    </AccordionSummary>
  );
};
