interface BaseMenuItem {
  id: string;
  name: string;
  children?: BaseMenuItem[];
}

export const getInitialPath = <T extends BaseMenuItem>(items: T[]): T[] => {
  if (!items || items.length === 0) return [];
  const firstItem = items[0];
  if (!firstItem.children) return [firstItem];
  return [firstItem, ...getInitialPath(firstItem.children as T[])];
};
