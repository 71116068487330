export const BID_STRATEGIES = {
  MAX_IMPRESSIONS: 'Max Impressions',
  AUTO_BID: 'Auto Bid',
  MAX_ROAS: 'Max ROAS',
  MANUAL_BID: 'Manual Bid',
  FIXED_CPM: 'Fixed CPM',
  MAX_OUTCOMES: 'Max Outcomes',
};

export const trackingLockedInfo =
  'This bid strategy will become available once tracking is set up.';
