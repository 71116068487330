import React, { forwardRef } from 'react';
import { ChipSelectorProps } from './types';
import { useChipSelector } from './hooks/useChipSelector';
import {
  StyledChipSelector,
  StyledChipSelectorInner,
  StyledDropzone,
  StyledUploadText,
} from './styles';
import { ChipSelectorItem } from './ChipSelectorItem';
import ChipPopper from './ChipPopper';
import { ChipPopperContext, geoTypes } from './constants';
import { values } from 'lodash';
import { CloudFileUploadIcon } from '@v2/components/icons/CloudFileUploadIcon';

const ChipSelector = forwardRef<HTMLDivElement, ChipSelectorProps>(
  (
    {
      value,
      onChange,
      onBlur,
      type,
      types = values(geoTypes),
      ...props
    }: ChipSelectorProps,
    ref
  ) => {
    const {
      input,
      selectorRef,
      changeItem,
      remove,
      data,
      setInput,
      isLoading,
      focus,
      focusedIndex,
      focusNext,
      focusPrev,
      focusStale,
      showDropzone,
      Copy,
      getInputProps,
      getRootProps,
      isDragActive,
      onPopperKeyboardEvent,
      onPopperSelect,
    } = useChipSelector({
      value,
      onChange,
      onBlur,
      type,
      types,
    });

    return (
      <ChipPopperContext.Provider
        value={{ focusedIndex, focus, focusNext, focusPrev, focusStale }}
      >
        <StyledChipSelector
          ref={ref}
          sx={{ position: 'relative', ...props.sx }}
          {...getRootProps({
            onClick: () => {
              selectorRef.current?.focus();
            },
          })}
        >
          {showDropzone && (
            <StyledDropzone isDragActive={isDragActive}>
              <input {...getInputProps()} />
              <CloudFileUploadIcon sx={{ width: '120px', height: '93px' }} />
              <StyledUploadText sx={{ mt: 4 }}>
                {Copy.upload[type]}
              </StyledUploadText>
            </StyledDropzone>
          )}
          <StyledChipSelectorInner>
            {value.map((v, idx) => (
              <ChipSelectorItem
                type={type}
                index={idx}
                key={v.id}
                value={v}
                types={types}
                onChange={newValue => {
                  changeItem(v, newValue);
                }}
                onRemove={() => remove(v)}
              />
            ))}
            <ChipPopper
              onKeyDown={onPopperKeyboardEvent}
              onFocus={() => {
                focus(undefined);
              }}
              type={type}
              ref={selectorRef}
              value={input}
              onInput={setInput}
              data={data}
              isLoading={isLoading}
              onSelect={onPopperSelect}
            />
          </StyledChipSelectorInner>
        </StyledChipSelector>
      </ChipPopperContext.Provider>
    );
  }
);

ChipSelector.displayName = 'ChipSelector';

export default ChipSelector;
