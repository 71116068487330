import { Autocomplete, Box } from '@mui/material';
import { omit } from 'lodash';
import { ReactNodeLike } from 'prop-types';
import React, { forwardRef, startTransition } from 'react';
import SelectedSegmentItem from '../../ui/SelectedSegmentItem';
import { HighlightedContent, toHighlightedContent } from '../Advanced';
import IncludeExcludeToggle from './IncludeExcludeToggle';
import { useGeoTargeter } from './hooks/useGeoTargeter';
import { GeographyBox, StyledBox, TextFieldStyled } from './styles';
import { GeoTargeterProps } from './types';
import { getActualField, mapBoxItemToGeoItem } from './utils';

const GeoTargeter = forwardRef<HTMLDivElement, GeoTargeterProps>(
  (
    {
      selectionType = 'include',
      onChangeSelectionType,
      disabled,
      onChange,
      label,
      value,
      onBlur,
      onFocus,
      isFocused = false,
      ...props
    },
    ref
  ) => {
    const {
      searchResults,
      inputValue,
      setInputValue,
      selectedValue,
      isLoading,
      blur,
    } = useGeoTargeter(value, {
      disabled,
      onBlur,
    });

    return (
      <StyledBox alignItems="center" spacing={1} ref={ref}>
        {isFocused ? (
          <GeographyBox alignItems="center">
            <Autocomplete
              {...props}
              value={selectedValue}
              disablePortal
              loading={isLoading}
              options={searchResults}
              autoComplete={false}
              autoSave="false"
              getOptionLabel={option => {
                return getActualField(option);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <HighlightedContent
                    data={toHighlightedContent(
                      getActualField(option),
                      inputValue
                    )}
                  />
                </Box>
              )}
              onChange={(_, newValue) => {
                startTransition(() => {
                  onChange({
                    ...mapBoxItemToGeoItem(newValue),
                    blacklist: selectionType === 'exclude',
                  });
                });
              }}
              renderInput={params => {
                return (
                  <TextFieldStyled
                    inputProps={{
                      ...omit(params.inputProps, 'value'),
                    }}
                    value={inputValue}
                    autoFocus
                    fullWidth
                    placeholder="Geography selector"
                    onChange={e => {
                      setInputValue(e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        paddingRight: 15,
                      },
                    }}
                    onBlur={blur}
                  />
                );
              }}
              sx={{ width: '100%' }}
            />
          </GeographyBox>
        ) : (
          <SelectedSegmentItem
            icon={
              (
                <IncludeExcludeToggle
                  value={selectionType}
                  onChange={onChangeSelectionType}
                />
              ) as ReactNodeLike
            }
            label={label ?? getActualField(value) ?? 'Geography selector'}
            onEdit={onFocus}
            placeholderText="Geography selector"
          />
        )}
      </StyledBox>
    );
  }
);

GeoTargeter.displayName = 'GeoTargeter';

export default GeoTargeter;
