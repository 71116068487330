import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useAPI } from './hooks/api';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import { valuetext } from './util';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import { Gender, GenderCopies, Themes } from '../constants';

const PREFIX = 'DemoTargeting';

const classes = {
  root: `${PREFIX}-root`,
  buttonRoot: `${PREFIX}-buttonRoot`,
  divider: `${PREFIX}-divider`,
  smallTitle: `${PREFIX}-smallTitle`,
  height100: `${PREFIX}-height100`,
  bottomNav: `${PREFIX}-bottomNav`,
  slider: `${PREFIX}-slider`,
  sliderRoot: `${PREFIX}-sliderRoot`,
  valueLabel: `${PREFIX}-valueLabel`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonRoot}`]: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.smallTitle}`]: {
    textAlign: 'center',
    color: 'rgb(114, 115, 131)',
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.height100}`]: {
    height: '100%',
  },

  [`& .${classes.bottomNav}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.slider}`]: {
    ['& .MuiSlider-track']: {
      backgroundColor: theme.palette.grey.main,
    },
  },

  [`& .${classes.sliderRoot}`]: {
    width: 600
  },

  [`& .${classes.valueLabel}`]: {
    fontSize: 10
  }
}));

const [ageMin, ageMax] = [1, 8];
const [incomeMin, incomeMax] = [29, 251];

const incomeMarks = [
  {
    value: incomeMin,
    label: '<$30k',
  },
  {
    value: 30,
    label: '$30k',
  },
  {
    value: 40,
    label: '$40k',
  },
  {
    value: 50,
    label: '$50k',
  },
  {
    value: 60,
    label: '$60k',
  },
  {
    value: 75,
    label: '$75k',
  },
  {
    value: 100,
    label: '$100k',
  },
  {
    value: 125,
    label: '$125k',
  },
  {
    value: 150,
    label: '$150k',
  },
  {
    value: 200,
    label: '$200k',
  },
  {
    value: 250,
    label: '$250k+',
  },
];

const ageMarks = [
  {
    value: 1,
    label: 'P2+',
  },
  {
    value: 2,
    label: '18',
  },
  {
    value: 3,
    label: '25',
  },
  {
    value: 4,
    label: '35',
  },
  {
    value: 5,
    label: '45',
  },
  {
    value: 6,
    label: '55',
  },
  {
    value: 7,
    label: '65',
  },
  {
    value: 8,
    label: '65+',
  },
];

const ageKeys = {
  1: 2,
  2: 18,
  3: 25,
  4: 35,
  5: 45,
  6: 55,
  7: 65,
  8: 66,
  9: 66
};

const ageValues = {
  2: 1,
  18: 2,
  25: 3,
  35: 4,
  45: 5,
  55: 6,
  65: 7,
  66: 8,
};

const demographicsTip = `If you choose demographic targeting, your data CPM will be $1.50 CPM. Targeting may limit scale. Keep in mind you are targeting at the household level.`;

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    FooterBack: 'Geo-Targeting',
    gender: GenderCopies[Themes.DEFAULT],
    GenderButton: [
      {
        gender: Gender.MALE,
        value: "male",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.MALE);
        },
      },
      {
        gender: Gender.FEMALE,
        value: "female",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.FEMALE);
        },
      }
    ]

  },
  [Themes.NBCU]: {
    INTRO: <>Select the gender, age, and household (HH) income of the audience you wish to target. To select additional targeting options, ensure the <b>Define advanced behaviors & interests</b> toggle is on.</>,
    FooterBack: 'Geotargeting',
    gender: GenderCopies[Themes.NBCU],
    GenderButton: [
      {
        gender: Gender.FEMALE,
        value: "female",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.FEMALE);
        },
      },
      {
        gender: Gender.MALE,
        value: "male",
        handler: (handleSetGender) => () => {
          handleSetGender(Gender.MALE);
        },
      },
    ]
  },
};

// TODO:
// - Lift state to custom hook
const DemoTargeting = props => {

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    combinedTargetingData,
    save,
    triggerSave,
    setAdjustRecommendedBid,
    setIsDefaultDemo = () => {},
  } = props;

  const { usePatch } = useAPI();
  const { saveProgress } = useSaveExit();
  const { isLoading, setIsLoading } = useLoader();
  const Copy = useCopy(Copies);

  const initialIncome = () =>
    props.income ? props.income : [incomeMin, incomeMax];

  const [gender, setGender] = useState(props.gender ? props.gender : 'All');
  const [age, setAge] = useState([ageMin, ageMax]);
  const [income, setIncome] = useState(initialIncome());

  const [isRangeError, setIsRangeError] = useState(false);

  useEffect(() => {
    if (!props.age) {
      return;
    }

    // NOTE: this is to handle the old age targeting
    if (props.age[0] < ageKeys[2] && props.age[1] >= ageKeys[7]) {
      setAge([ageValues[2], ageValues[66]]);

      return;
    }

    const min = ageValues[props.age[0]] ? ageValues[props.age[0]] : 1;
    const max = ageValues[props.age[1]] ? ageValues[props.age[1]] : 7;

    setAge([min, max]);
  }, [props.age]);

  useEffect(() => {
    if (props.gender) {
      setGender(props.gender);
    }

    if (props.income) {
      setIncome(props.income);
    }
  }, [props.age, props.gender, props.income]);

  useEffect(() => {
    let isDefault = true;

    if (gender.toLowerCase() !== 'all') {
      isDefault = false;
    }

    if (age[0] !== 1 || age[1] !== 7) {
      isDefault = false;
    }

    if (income[0] !== 30 || income[1] !== 250) {
      isDefault = false;
    }

    setIsDefaultDemo(isDefault);
  }, [age, gender, income]);

  useEffect(() => {
    if (
      age &&
      age[0] === ageValues[2] &&
      age[1] === ageValues[18]
    ) {
      if (age && age[0] === ageValues[2] && age[1] === ageValues[18]) {
        setIsRangeError(true);
      } else {
        setIsRangeError(false);
      }
    }
  }, [age]);

  useEffect(() => {
    if (isRangeError) {
      enqueueSnackbar(
        'In order to restrict targeting please select a maximum age over 18 or a starting age over 18.',
        {
          autoHideDuration: 10000,
          variant: 'warning',
        }
      );

      return;
    }

    closeSnackbar();
  }, [isRangeError]);

  useEffect(() => {
    saveProgress(save, 'DemoTargeting', handleSave, triggerSave);
  }, [save]);

  ////////////////////////////////////
  // Local state handlers
  ////////////////////////////////////
  const handleSetGender = value => {
    setGender(value);

    if (props.setGender) {
      props.setGender(value);
    }
  };

  const handleActiveGender = value => {
    return gender === value ? 'contained' : 'outlined';
  };

  const handleAgeSlider = (event, value) => {
    setAge(value);

    if (props.setAge) {
      props.setAge([ageKeys[value[0]], ageKeys[value[1]]]);
    }
  };

  const handleIncomeSlider = (event, newValue) => {
    // Determine whether the user is moving the lower or upper thumb
    const index = newValue[0] !== income[0] ? 0 : 1;

    // Find the closest mark to the new value
    const closestMark = incomeMarks.reduce((prev, curr) =>
      Math.abs(curr.value - newValue[index]) < Math.abs(prev.value - newValue[index]) ? curr : prev
    );

    // Set the slider's value to the closest mark
    const newIncome = [...income];
    newIncome[index] = closestMark.value;
    setIncome(newIncome);

    if (props.setIncome) {
      props.setIncome(newIncome);
    }
  };

  const advanced = props.isAdvanced;

  const handleData = () => {
    const dataObj = {
      age: [ageKeys[age[0]], ageKeys[age[1]]],
      gender,
      income,
      advanced,
    };

    if (props.handleDemoData) {
      props.handleDemoData(dataObj);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    handleData();

    const data = {
      targeting: JSON.stringify({
        ...combinedTargetingData,
        advanced,
        age: [ageKeys[age[0]], ageKeys[age[1]]],
        income,
        gender,
      }),
    };

    try {
      const response = await usePatch(
        `/lineitems/${props.currentAdGroup.id}/`,
        data
      );

      setIsLoading(false);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'DemoTargeting') {
        if (save.exit) {
          history.push('/');
        }
      }

      // Handle next button click
      if (!save.exit) {
        if (props.isAdvanced) {
          props.setStep('TargetingSegments');
        } else {
          props.setStep('Inventory');
        }

        if (props.isAdvanced) {
          props.updateBreadcrumbs('targeting', 50);
        } else {
          props.updateBreadcrumbs('targeting', 75);
        }
      }

      return response;
    } catch (error) {
      console.error('Error in saving DemoTargeting', error);

      setIsLoading(false);

      return error;
    }
  };

  const checkDemoData = () => {
    if (
      age[0] !== ageMin ||
      age[1] !== ageMax ||
      income[0] !== incomeMin ||
      income[1] !== incomeMax ||
      gender !== 'All'
    ) {
      props.setDemographicsTouched(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!props.isEditing) {
      if (checkDemoData() && props.isAdvanced) {
        setAdjustRecommendedBid(true);
      } else if (checkDemoData() && !props.isAdvanced) {
        setAdjustRecommendedBid(true);
      } else if (!checkDemoData() && !props.isAdvanced) {
        setAdjustRecommendedBid(false);
      }
    }
  }, [age, income, gender, advanced]);

  return (
    <StyledGrid container style={{ height: '100%' }} justifyContent="space-between">
      <Box width="100%">
        {!props.isEditing && (
          <Fragment>
            <Title>
              Select Demographics{' '}
              <Tooltip title={demographicsTip}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </Tooltip>
            </Title>

            {Copy.INTRO && <p>{Copy.INTRO}</p>}

            <Divider className={classes.divider} />
          </Fragment>
        )}

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.root}>
            <Typography className={classes.smallTitle}>
              <strong>Gender:</strong> {gender}
            </Typography>

            <ButtonGroup
              color="secondary"
              aria-label="contained secondary button group"
              disableRipple
              fullWidth
              disableElevation
            >
              <Button
                onClick={() => {
                  handleSetGender('All');
                }}
                variant={handleActiveGender('All')}
                value="all"
                disableElevation
              >
                All
              </Button>

              {Copy.GenderButton.map(({ gender, handler, value }) => {
                return (
                  <Button
                    key={gender}
                    onClick={handler(handleSetGender)}
                    variant={handleActiveGender(gender)}
                    value={value}
                    disableElevation
                  >
                    {Copy.gender[gender]}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>

          <Grid item className={classes.sliderRoot}>
            <Typography
              className={clsx([classes.smallTitle, 'range-slider'])}
            >
              <strong>Age</strong>
            </Typography>

            <Slider
              className={isRangeError ? classes.slider : ''}
              value={age}
              min={1}
              max={8}
              marks={ageMarks}
              onChange={handleAgeSlider}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              name="age"
              step={null}
              color="secondary"
              valueLabelFormat={v => ageKeys[v]}
            />
          </Grid>

          <Grid item className={classes.sliderRoot}>
            <Typography
              className={clsx([classes.smallTitle, 'range-slider'])}
            >
              <strong>HH Income</strong>
            </Typography>

            <Slider
             classes={{
              track: classes.track,
              rail: classes.rail,
              thumb: classes.thumb,
              markLabel: classes.valueLabel
            }}
              value={income}
              min={incomeMin}
              max={incomeMax}
              marks={incomeMarks}
              onChange={handleIncomeSlider}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              name="income"
              color="secondary"
            />
          </Grid>

          <Grid item className={classes.root}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.isAdvanced}
                  color="secondary"
                  name="define-adv"
                  onChange={event =>
                    props.setIsAdvanced(event.target.checked)
                  }
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  Define advanced behaviors &amp; interests
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container item xs={12} justfy="flex-end">
        {!props.isEditing && (
          <CampaignFooter
            isDisabled={isRangeError}
            isLoading={isLoading}
            back={Copy.FooterBack}
            next={props.isAdvanced ? 'Targeting Segments' : 'Inventory'}
            onBack={() => {
              props.setStep('GeoTargeting');
              props.updateBreadcrumbs('targeting', 0);
            }}
            onNext={() => {
              setIsLoading(true);
              triggerSave(
                'DemoTargeting',
                false,
                props.isAdvanced ? 'TargetingSegments' : 'Inventory'
              );
            }}
            page={2}
          />
        )}
      </Grid>
    </StyledGrid>
  );
};

DemoTargeting.propTypes = {
  isAdvanced: PropTypes.bool,
  isEditing: PropTypes.bool,
  setIsAdvanced: PropTypes.func,
  setIsRangeError: PropTypes.func,
  age: PropTypes.array,
  gender: PropTypes.string,
  income: PropTypes.array,
  setAge: PropTypes.func,
  setGender: PropTypes.func,
  setIncome: PropTypes.func,
  setStep: PropTypes.func,
  handleDemoData: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  advertiserId: PropTypes.number,
  currentAdGroup: PropTypes.object,
  combinedTargetingData: PropTypes.object,
  demoData: PropTypes.array,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  setAdjustRecommendedBid: PropTypes.func,
  setDemographicsTouched: PropTypes.func,
  setIsDefaultDemo: PropTypes.func
};

export default DemoTargeting;
