import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined } from '@mui/icons-material';
import { Button, Chip, Stack, styled, Table, TextField } from '@mui/material';

export const StatusChipStyled = styled(Chip)(({ theme }) => ({
  height: theme.spacing(3),
  cursor: 'default',
}));

export const StatusChipExistingStyled = styled(StatusChipStyled, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.green[10] : theme.palette.grey[7],
  color: active ? theme.palette.green[4] : theme.palette.grey[1],
}));

export const StatusChipUploadingStyled = styled(StatusChipStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[11],
    color: theme.palette.grey[4],
  })
);

export const StatusChipFailedStyled = styled(StatusChipStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.red[5],
    color: theme.palette.red[4],
  })
);

export const ActionButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  height: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: theme.spacing(0.5),
}));

export const AssignButtonStyled = styled(ActionButtonStyled, {
  shouldForwardProp: prop => prop !== 'assigned',
})(({ theme, assigned }) => ({
  textWrap: 'nowrap',
  backgroundColor: assigned ? theme.palette.blue[11] : theme.palette.blue[6],
  color: assigned ? theme.palette.blue[6] : theme.palette.common.white,

  '&:hover': {
    color: assigned ? theme.palette.red[4] : theme.palette.common.white,
    backgroundColor: assigned ? theme.palette.red[5] : theme.palette.blue[7],
  },
}));

export const UploadingAssignButtonStyled = styled(ActionButtonStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[11],
    color: theme.palette.grey[4],
  })
);

export const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: theme.spacing(41.25),
  minWidth: theme.spacing(20),
  width: '100%',

  '& .MuiOutlinedInput-root': {
    height: theme.spacing(4.25),

    '& input': {
      padding: theme.spacing(2),
      height: '100%',
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
    },

    '& fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`,
    },

    '&:hover fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`,
    },

    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.blue['6']} !important`,
    },

    '&.Mui-error fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  '.MuiInputBase-input': {
    color: theme.palette.grey['5'],
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },

  '.MuiInputAdornment-root': {
    marginLeft: 0,
  },
}));

export const StyledSearchIcon = styled(SearchOutlined)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  color: theme.palette.grey['2'],
}));

// Define the styled component for the clear icon (FontAwesome "x" icon)
export const StyledClearIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.grey[6],
  cursor: 'pointer',
}));

export const TableStyled = styled(Table)(({ theme }) => ({
  '.sticky-left': {
    left: 0,
  },
  '.sticky-right': {
    right: 0,
  },
  thead: {
    zIndex: 4,
    tr: {
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      '.sticky': {
        backgroundColor: theme.palette.common.white,
        zIndex: 3,
      },
      top: 0,
      zIndex: 4,
    },
  },
  'tbody tr': {
    backgroundColor: theme.palette.common.white,
    '.sticky': {
      zIndex: 3,
      backgroundColor: theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[12],
      '.sticky': {
        backgroundColor: theme.palette.grey[12],
      },
    },
    td: {
      wordBreak: 'break-word',
      p: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
    },
  },
  'tbody, thead': {
    '.sticky': {
      position: 'sticky',
    },
  },
}));

export const CreativeWrapperStyled = styled(Stack)(() => ({
  flex: 1,
  overflowY: 'auto',
}));
