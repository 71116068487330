import { useWizardContext } from './useWizardContext';
import { useFlags } from '@hooks/flags';
import { useUser } from '@hooks/user';

export const useWizardPreview = ({
  key,
  group,
}: {
  key: string;
  group?: { out_of_sync: { beeswax_targeting: boolean } };
}) => {
  const { user } = useUser();
  const {
    navigation: { activeSection },
    state,
  } = useWizardContext();
  const { flags, Flags } = useFlags();

  const isTargetingOverrideEnabled =
    flags[Flags.TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE];

  const showOutOfSync =
    user?.is_tvsci_employee &&
    Boolean(group?.out_of_sync?.beeswax_targeting) &&
    isTargetingOverrideEnabled;

  return {
    active: key === activeSection,
    showOutOfSync,
    state,
  };
};
