import { useContext } from 'react';
import UserContext from '../../providers/UserContext';
import { User } from '@local-types';

interface UserContextType {
  user: User | null;
  getUser: () => Promise<void>;
}

export const useUser = (): UserContextType => {
  const userContext = useContext(UserContext);

  if (userContext === null || userContext === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }

  return userContext;
};
