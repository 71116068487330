import React, { memo } from 'react';
import { CreativeQCAlerts } from '../CreativeQCAlerts';
import { usePermissions } from '@hooks/index';
import { Scopes } from '@constants/index';
import { StyledBox } from './styles';
import { Creative } from '@local-types';
import { CreativeLibraryHeader } from '@components/CreativeLibrary/CreativeLibraryHeader';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import {
  CreativeLibraryContext,
  CreativeLibraryContextProvider,
} from '@components/CreativeLibrary/CreativeLibraryContext';
import { CreativeLibraryTable } from './CreativeLibraryTable';
import { CreativeLibraryTableV2 } from './CreativeLibraryTableV2';

// TODO
export interface CreativeLibraryProps {
  assigned: Creative[];
  handleBatchAssign: (creatives: Creative[]) => void;
  handleUnassign: (creative: Creative) => void;
  setCurrentPreview: (creative: Creative) => void;
  setIsPreview: (isPreview: boolean) => void;
}

const CreativeLibrary = ({
  assigned,
  handleBatchAssign,
  handleUnassign,
  setCurrentPreview,
  setIsPreview,
}: CreativeLibraryProps) => {
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);
  const { v2CreativesTable } = useLDFlags();

  return (
    <CreativeLibraryContextProvider
      handleBatchAssign={handleBatchAssign}
      handleUnassign={handleUnassign}
      setCurrentPreview={setCurrentPreview}
      setIsPreview={setIsPreview}
      assigned={assigned}
    >
      <CreativeLibraryContext.Consumer>
        {({ creatives }) => {
          return (
            <StyledBox>
              <CreativeLibraryHeader />

              {showQCFunctionality && (
                <CreativeQCAlerts
                  email={'support@tvscientific.com'}
                  items={creatives || []}
                />
              )}

              {v2CreativesTable ? (
                <CreativeLibraryTableV2 />
              ) : (
                <CreativeLibraryTable />
              )}
            </StyledBox>
          );
        }}
      </CreativeLibraryContext.Consumer>
    </CreativeLibraryContextProvider>
  );
};

export default memo(CreativeLibrary);
