import React from 'react';
import ReportsPage from './ReportsPage';
import { LookerFiltersContextProvider } from '@providers/LookerFiltersContext';

const Reports = () => {
  return (
    <LookerFiltersContextProvider>
      <ReportsPage />
    </LookerFiltersContextProvider>
  );
}

export default Reports;
