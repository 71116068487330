import React, { useMemo } from 'react';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks';
import {
  Box,
  CircularProgress,
  Fade,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { classes } from '@components/CreativeLibrary/styles';
import SortableTableHead from '@components/ui/SortableTableHead';
import { useCreativeLibrary } from '@components/CreativeLibrary/CreativeLibraryContext';
import { useSort } from '@hooks/sort';
import { CreativeLibraryTableRow } from '@components/CreativeLibrary/CreativeLibraryTableRow';
import { Creative } from '@local-types';

export const CreativeLibraryTable = () => {
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);
  const { isLoading, creatives, handleSelectAll, isSelectAll } =
    useCreativeLibrary();

  const { order, orderBy, handleRequestSort, getComparator, stableSort } =
    useSort();

  const columns = useMemo(() => {
    const columns = [
      { id: 'id', label: 'ID', align: 'left', sortable: true, class: '' },
      {
        id: 'name',
        label: 'Creative',
        align: 'left',
        sortable: true,
        class: '',
      },
      {
        id: 'adGroups',
        label: 'Ad groups',
        align: 'left',
        sortable: false,
        class: 'adGroupHeader',
      },
      {
        id: 'resolution',
        label: 'Resolution',
        align: 'left',
        sortable: false,
        class: '',
      },
      {
        id: 'fileSize',
        label: 'File size',
        align: 'left',
        sortable: false,
        class: 'fileSize',
      },
      {
        id: 'media_upload_date',
        align: 'left',
        label: 'Date created',
        sortable: true,
        class: '',
      },
    ];

    if (showQCFunctionality) {
      columns.push({
        id: 'qcStatus',
        label: '',
        align: 'left',
        sortable: false,
        class: '',
      });
    }

    columns.push({
      id: 'action',
      label: 'Action',
      align: 'left',
      sortable: false,
      class: 'action',
    });

    return columns;
  }, [showQCFunctionality]);

  return isLoading ? (
    <Box className={classes.spinner}>
      <CircularProgress color="secondary" />
    </Box>
  ) : creatives.length === 0 ? (
    <Box className={classes.spinner}>
      <Typography className={classes.none}>No results</Typography>
    </Box>
  ) : (
    <Fade in={!isLoading}>
      <TableContainer className={classes.table}>
        <Table stickyHeader>
          <SortableTableHead
            dense
            headCells={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onSelectAll={handleSelectAll}
            isSelectAll={isSelectAll}
          />

          <TableBody>
            {stableSort(
              creatives as never[],
              getComparator(order, orderBy),
            ).map((c, index) => (
              <CreativeLibraryTableRow
                key={index}
                creative={c as unknown as Creative}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
};
