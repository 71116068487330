import { GeoItem, GroupedGeoItem } from '../GeoTargeter/types';
import { distinctGroupsNames, distinctNames } from '../GeoTargeter/constants';
import { Creative } from '@local-types';

export const getActualGeoNameField = (value: GeoItem) =>
  value.place_name || value.text;

export const getGroupedLabel = (
  values: string[],
  type: (typeof distinctNames)[keyof typeof distinctNames]
) => {
  let label = values.slice(0, 3).join(', ');
  if (values.length > 3) {
    label += ` + ${values.length - 3} more...`;
  }
  label += ` ${distinctGroupsNames[type]}`;
  return label;
};

export const groupGeo = <T extends GeoItem>(
  geos: T[] = []
): GroupedGeoItem | undefined => {
  if (!geos.length) return;

  const type = geos[0].type;

  return {
    id: `geo-${type}-${geos[0].blacklist ? 'exclude' : 'include'}`,
    list: geos,
    type,
    blacklist: geos[0].blacklist ?? false,
  };
};

export const getTargetingValue = (
  key: string,
  targeting: { key: string; value: string }[] = []
) => targeting.find(t => t.key === key);

export const mapCreativesWithLocalAssets = (
  creatives: Creative[],
  localAssets: Map<number, string>
) =>
  creatives.map(creative => ({
    ...creative,
    preview_url: creative.preview_url ?? localAssets.get(creative.id) ?? null,
  }));
