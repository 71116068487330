import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { useSection } from '../hooks/useSection';
import { fields } from '../constants';
import { StyledLockIcon } from '../styles';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { NumberTextField } from '@v2/components/ui/NumberTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BudgetSection = ({ campaignId }: { campaignId?: number }) => {
  const {
    form: { control, watch, setValue },
  } = useSection();

  const handleBudgetTypeChange = (
    _: unknown,
    newAlignment: string | null,
    budgetTypeField: ControllerRenderProps
  ) => {
    if (newAlignment === null) return;

    const name = budgetTypeField.value.split('-');
    setValue(`${name[0]}_campaign_budget`, '', { shouldValidate: true });
    budgetTypeField.onChange(newAlignment);
  };

  const [budgetType] = watch([fields.budgetType.path]);

  return (
    <Stack gap={4}>
      <Controller
        name={fields.budgetType.path}
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup
            {...field}
            color="primary"
            exclusive
            onChange={(event, newAlignment) =>
              handleBudgetTypeChange(event, newAlignment, field)
            }
            aria-label="Budget Type"
          >
            <ToggleButton value="lifetime_budget">
              <Box component="span">Lifetime Budget</Box>
              <Box component="span" sx={{ ml: 1 }}>
                <Tooltip title="Lifetime budget info" placement="top" arrow>
                  <StyledLockIcon icon={faCircleInfo} />
                </Tooltip>
              </Box>
            </ToggleButton>
            <ToggleButton value="daily_budget">
              <Box component="span">Daily Budget</Box>
              <Box component="span" sx={{ ml: 1 }}>
                <Tooltip title="Daily budget info" placement="top" arrow>
                  <StyledLockIcon icon={faCircleInfo} />
                </Tooltip>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
      <Grid container spacing={1}>
        {budgetType === 'lifetime_budget' && (
          <Grid item xs={6}>
            <Controller
              name={fields.lifetimeBudget.path}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <NumberTextField
                    {...field}
                    id="lifetime-campaign-budget"
                    key="lifetime-campaign-budget"
                    label="Lifetime Campaign Budget"
                    placeholder="Enter Campaign Budget"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faDollarSign} />
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                    variant="outlined"
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid>
        )}

        {budgetType === 'lifetime_budget' && (
          <Grid item xs={6}>
            <Controller
              name={fields.dailyBudgetCap.path}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <NumberTextField
                  {...field}
                  id="daily-budget-cap"
                  label="Daily Budget Cap"
                  placeholder="Daily Budget Cap (optional)"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        )}

        {budgetType === 'daily_budget' && (
          <Grid item xs={12}>
            <Controller
              name={fields.dailyBudget.path}
              control={control}
              render={({ field, fieldState }) => (
                <NumberTextField
                  {...field}
                  {...fieldState}
                  error={!!fieldState.error}
                  id="daily-campaign-budget"
                  key="daily-campaign-budget"
                  label="Daily Campaign Budget"
                  placeholder="Enter Campaign Budget"
                  fullWidth
                  InputProps={{
                    autoFocus: !campaignId,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
