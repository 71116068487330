import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Title from './Title';
import googleTagIcon from '../images/icon-google-tag-manager/icon-google-tag-manager.png';
import * as eventScripts from '../constants/trackingEventsCode';
import { SetupWizardSteps } from '../constants';
import { trackingEvents } from '@hooks/tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PREFIX = 'InstallTrackingCode';

const classes = {
  red: `${PREFIX}-red`,
  alertInfo: `${PREFIX}-alertInfo`,
  codeBlock: `${PREFIX}-codeBlock`,
  ecommerce: `${PREFIX}-ecommerce`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.red}`]: {
    color: '#f30000',
  },

  [`& .${classes.alertInfo}`]: {
    color: '#0081c8',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: 'rgba(29, 175, 255, 0.25)',
  },

  [`& .${classes.codeBlock}`]: {
    whiteSpace: 'pre',
    overflowX: 'auto',
    paddingTop: 0,
  },

  [`& .${classes.ecommerce}`]: {
    width: 460,
  }
}));

const ecommerceOptions = [
  { value: 'none', label: 'None' },
  { value: 'big-commerce', label: 'Big Commerce' },
  { value: 'magento', label: 'Magento' },
  { value: 'salesforce', label: 'Salesforce' },
  { value: 'sap', label: 'SAP' },
  { value: 'shopify', label: 'Shopify' },
  { value: 'squarespace', label: 'Squarespace' },
  { value: 'woo-commerce', label: 'Woo Commerce' },
  { value: 'wordpress', label: 'Wordpress' },
  { value: 'custom', label: 'Custom' },
];

const InstallTrackingCode = ({
  handleInstallCompleted,
  handleInstallLater,
  installCompleted,
  installLater,
  isCampaign,
  ecommerce,
  setEcommerce,
  outcomeLid,
  selectedEvents,
  setStep,
  triggerSave,
  updateBreadcrumbs,
  isUsingGA4,
  measurementId
}) => {
  const { reportsNewGa4Script } = useFlags();

  const selectedEventsList = useMemo(() => {
    if (ecommerce === 'shopify') {
      return selectedEvents.filter(
        event => event.slug === 'complete_purchase'
      );
    }
    return selectedEvents;
  }, [selectedEvents, ecommerce]);

  const totalVisibleScripts = useMemo(() => {
    // selected events + 1 for the universal pixel
    // eslint-disable-next-line prefer-const
    let total = selectedEventsList.length + 1;

    // google will be shown only if there is no shopify
    // if (ecommerce !== 'shopify') {
    //   total += 1;
    // }

    return total;
  }, [selectedEventsList]);

  const handleEmailCode = text => {
    window.location = `mailto:?subject=tvScientific tracking code&body=${text}`;
  };

  const [ga4Script, setGa4Script] = useState('');

  useEffect(() => {
    if (isUsingGA4 && reportsNewGa4Script) {
      eventScripts.getGA4Script({ measurementId, outcomeLid }).then(setGa4Script);
    }
  }, [isUsingGA4, measurementId, outcomeLid]);

  const parseScript = useCallback((template, ecommerce) => {
    const result = [];

    if (typeof template !== 'string') return template;

    let lastIndex = 0;
    template.trim().replace(/{{.*?}}/g, (match, startIndex) => {
      // take the part before the match
      const part = template.slice(lastIndex, startIndex + 1);
      result.push(part);

      // take the match and wrap it in a span
      if (ecommerce !== 'shopify') {
        result.push(<span className={classes.red}>{match}</span>);
      }
      // update the lastIndex
      lastIndex = startIndex + 1 + match.length;
    });

    // take the last part
    const lastPart = template.slice(lastIndex, -1);
    result.push(lastPart);

    return (
      <code className={classes.codeBlock}>
        {result.map((part, index) => (
          <Root key={index}>{part}</Root>
        ))}
      </code>
    );
  }, []);

  const handleCopyCode = text => {
    window.navigator.clipboard.writeText(text);
  };

  const renderPixelDescription = slug => {
    const instructions = (
      <p>
        Add the following code snippet to the page where {slug} occurs. For a
        page load event, add the code immediately below the {'<head>'} tag.
        For an inline action, add the code to the relevant HTML element or on
        a button click via a tag manager like GTM. For additional questions,
        please see documentation{' '}
        <Link
          href="https://help.tvscientific.app/en/collections/3984298-technical-tracking"
          target="_blank"
          underline="hover">
          here
        </Link>
        .
      </p>
    );

    if (slug === 'complete_purchase' && ecommerce === 'shopify')
      return (
        <>
          {instructions}
          <p>Shopify Instructions for placing conversion pixels:</p>
          <ol>
            <li>Add conversion tracking to your order status page</li>
            <li>From your Shopify admin, go to Settings {'>'} Checkout.</li>
            <li>
              Under Order Processing, go to the Additional Scripts text box.
            </li>
            <li>Add the tvScientific Pixel and save.</li>
          </ol>
        </>
      );

    if (slug === 'complete_purchase')
      return (
        <>
          Copy this code snippet and modify the values{' '}
          {'{{in double curly brackets}}'} with dynamic variables. Add your
          modified code snippet at the top of the HTML element of the order
          confirmation page of your site, or use a tag manager to add this
          code snippet to load the order confirmation page. If you would like
          to add additional parameters check out our documentation portal or
          contact out integration team.
        </>
      );

    if (slug === 'complete_registration')
      return (
        <>
          Copy this code snippet and modify the values{' '}
          {'{{ in double curly brackets }}'} with dynamic variables. Add your
          modified code snippet at the top of the HTML element of the
          registration confirmation page of your site, or use a tag manager to
          add this code snippet to load the registration confirmation page.
        </>
      );

    if (slug === 'viewed_product')
      return (
        <>
          Copy this code snippet and modify the values{' '}
          {'{{in double curly brackets}}'} with dynamic variables. Add your
          modified code snippet to load when a product is viewed.
        </>
      );

    if (['start_checkout', 'add_to_cart'].includes(slug))
      return (
        <>
          Copy this code snippet and modify the values{' '}
          {'{{in double curly brackets}}'} with dynamic variables. Add your
          modified code snippet to load on start checkout events.
        </>
      );

    return instructions;
  };

  const renderPixelCode = (slug, value) => {
    if (ecommerce === 'shopify' && slug === 'complete_purchase')
      return eventScripts.complete_purchase_shopify({ outcomeLid });

    // eslint-disable-next-line import/namespace
    const func = eventScripts[slug];

    if (typeof func !== 'function')
      return eventScripts.generic_pixel({ outcomeLid, value });

    return func({ outcomeLid });
  };

  const ga4ScriptToUse = reportsNewGa4Script && ga4Script ?
    `\n<script>\n ${ga4Script}\n</script>` :
    eventScripts.ga4({ outcomeLid, measurementId });

  // eslint-disable-next-line react/prop-types
  const RenderScriptBlock = ({ title, script, description, index, ecommerce }) => (
    <Fragment key={index}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <div className="Pill--primary">
            Step {index} of {totalVisibleScripts}
          </div>
        </Grid>

        <Grid item container xs={6} alignContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">
              {title}
            </Typography>

            {description}
          </Grid>
        </Grid>

        <Grid item container xs={6}>
          <Grid item xs={12}>
            {parseScript(script, ecommerce)}
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={4}>
              <Button
                onClick={() => handleEmailCode(script)}
                color="secondary"
                size="small"
                className="Button--small"
                startIcon={<MailOutlineIcon />}
              >
                Share via Email
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                onClick={() => handleCopyCode(script)}
                color="secondary"
                size="small"
                className="Button--small"
                startIcon={<FileCopyOutlinedIcon />}
              >
                Copy Snippet
              </Button>
            </Grid>

            <Fragment>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  size="small"
                  className="Button--small"
                  href="https://tagmanager.google.com/"
                  target="_blank"
                >
                  <img src={googleTagIcon} style={{ marginRight: 5 }} />
                  Google Tag Manager
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </Grid>
      </Grid>

      <Box p={3} />
    </Fragment>
  );

  const getTitleForSlug = slug => {
    const e = trackingEvents.find(event => event.slug === slug);
    if (!e) return `Install code for ${slug}`
    return `${e.name} Pixel`
  }

  return (
    <Root>
      {isCampaign && (
        <>
          <Title>Install Tracking Code</Title>

          <TextField
            id="e-commerce-dropdown"
            className={classes.ecommerce}
            select
            label="Select E-commerce Provider"
            placeholder="Select E-commerce Provider"
            value={ecommerce}
            onChange={({ target: { value } }) => setEcommerce(value)}
            variant="outlined"
          >
            {ecommerceOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}

      <Grid container style={{ position: 'relative' }}>
        <Box
          border={1}
          borderColor="grey.300"
          borderRadius="9px"
          p={4}
          mt={2}
          style={{ maxHeight: 509, overflow: 'auto' }}
        >
          {!isUsingGA4 && (
            <RenderScriptBlock
              index={1}
              title="Install Universal Site Pixel"
              script={eventScripts.universal_pixel({ outcomeLid })}
              description={
                <p>
                  Copy this code snippet within the global{' '}
                  {'<head> </head>'} HTML element of your site, or
                  in a tag manager as a custom HTML tag. The universal site
                  pixel should fire on all pages of your website.
                </p>
              }
              ecommerce={ecommerce}
            />
          )}

          {isUsingGA4 && (
            <RenderScriptBlock
              ecommerce={ecommerce}
              index={1}
              title="tvScientific Google Analytics 4 Plug-In"
              script={ga4ScriptToUse}
              description={
                <p>
                  Copy this code snippet.
                  <br />
                  To install the GA4 script in Google Tag Manager:
                  <ol>
                    <li>
                      Navigate to your Google Tag Manager container and click
                      &quot;Tags.&quot;
                    </li>
                    <li>
                      Create a new custom HTML tag and copy your modified code
                      snippet in the &quot;Tag Configuration.&quot;
                    </li>
                    <li>
                      Set the trigger as a page view where you only fire when
                      the referrer is not equal to your website domain (ie.
                      yoursite.com)
                    </li>
                    <li>Click &quot;Save&quot; and deploy.</li>
                  </ol>
                </p>
              }
            />
          )}

          {selectedEventsList.map((trackingEvent, index) => {
            return (
              <RenderScriptBlock
                key={index}
                index={index + 2}
                title={getTitleForSlug(trackingEvent.slug)}
                script={renderPixelCode(
                  trackingEvent.slug,
                  trackingEvent.value
                )}
                description={renderPixelDescription(trackingEvent.slug)}
                ecommerce={ecommerce}
              />
            );
          })}
        </Box>
      </Grid>

      {isCampaign && (
        <>
          <Box my={2}>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="completedAllSteps"
                      color="secondary"
                      checked={installCompleted}
                      onChange={event =>
                        handleInstallCompleted(event.target.checked)
                      }
                    />
                  }
                  label="I have completed all steps"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="willCompleteLater"
                      color="secondary"
                      checked={installLater}
                      onChange={event =>
                        handleInstallLater(event.target.checked)
                      }
                    />
                  }
                  label="I'll do this later"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid container item xs={6} justifyContent="flex-start">
                <Button
                  color="secondary"
                  className="Button-campaign-nav"
                  onClick={() => {
                    setStep(SetupWizardSteps.TRACKING_EVENTS);
                    updateBreadcrumbs('tracking', 66);
                  }}
                  startIcon={<ArrowBackIcon />}
                  variant="outlined"
                  disableElevation
                >
                  Select Tracking Events
                </Button>
              </Grid>

              <Grid
                item
                container
                xs={6}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  color="secondary"
                  className="Button--medium"
                  disabled={!(installCompleted || installLater)}
                  onClick={() => {
                    triggerSave(
                      'InstallTrackingCode',
                      false,
                      'LaunchCampaign'
                    );

                    setStep('LaunchCampaign');
                    updateBreadcrumbs('tracking', 100, 'launch', 0);
                  }}
                  size="medium"
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Root>
  );
};

InstallTrackingCode.propTypes = {
  handleInstallCompleted: PropTypes.func,
  handleInstallLater: PropTypes.func,
  installCompleted: PropTypes.bool,
  installLater: PropTypes.bool,
  isCampaign: PropTypes.bool,
  ecommerce: PropTypes.string,
  setEcommerce: PropTypes.func,
  exposureLid: PropTypes.string,
  outcomeLid: PropTypes.string,
  selectedEvents: PropTypes.array,
  setStep: PropTypes.func,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  isUsingGA4: PropTypes.bool,
  measurementId: PropTypes.string
};

export default InstallTrackingCode;
