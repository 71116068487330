import { useContext } from 'react';
import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import {
  useGetAllCreatives,
  useGetCreativeLineItemTrigger,
  usePatchCreative,
  usePatchCreativeLineItem,
} from '@apis/creatives';
import {
  useAllDisplays,
  usePatchStaticDisplayCreative,
} from '@apis/displays';
import { GROUP_TYPE, useGroups } from '@apis/groups';
import { useWizardSection } from '@components/Wizard';
import { useUser } from '@components/hooks';
import { useFlags } from '@hooks/flags';
import { pick } from 'lodash';
import { useSnackbar } from 'notistack';
import { useGeneralNavigation } from '../../hooks/useGeneralNavigation';
import {
  removeEmptyValues,
  removeNullValues,
  handleErrors,
} from '../../utils';
import {
  transformAdGroup,
  showOutOfSyncSubmitPrompt,
  getOutOfSyncItems,
  isWeightedDelivery,
  filter,
  mustIncludePostFields,
  mustIncludePatchFields,
} from '../utils';
import { bwSyncParams } from '@components/containers/WizardPage/constants';
import DialogContext from '@providers/DialogContext';
import { adServerOutOfSyncPrompt } from '@v2/components/ui/AdServerOutOfSyncPrompt';
import { targetingOverrideParams } from '../constants';
import { wizardGeneralErrorFields } from '../../constants';
import { fields } from '@components/CampaignWizard/AdGroupSettingsSection/formConfig';
import { useGetBudgetPacingOptions } from '@components/hooks/apis/budgetPacingOptions';

export const useGroupSectionSubmit = ({
  isDisplay,
  campaign,
  form,
  sectionKey,
  adGroup,
}) => {
  const { user } = useUser();
  const { flags, Flags } = useFlags();
  const { setDialog } = useContext(DialogContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state, toCampaignDetails } = useGeneralNavigation();
  const { data: allowedTargeting } = useGetAllowedTargeting();
  const { data: bidStrategies } = useGetAllBidStrategies();
  const { trigger: updateLineItemCreative, isMutating: isPostMutating } =
    usePatchCreativeLineItem();
  const { data: budgetPacingOptions } = useGetBudgetPacingOptions();

  const isTargetingOverrideEnabled =
    flags[Flags.TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE];
  const shouldShowOutOfSyncSubmitPrompt = showOutOfSyncSubmitPrompt({
    adGroup,
    user,
    isTargetingOverrideEnabled,
  });

  const { invalidate: invalidateCreatives } = useGetAllCreatives({
    disabled: !!isDisplay,
  });
  const { invalidate: invalidateDisplay } = useAllDisplays({
    disabled: !isDisplay,
  });

  const {
    update: updateAdGroup,
    create: createAdGroup,
    isLoading: isAdGroupProcessing,
  } = useGroups(GROUP_TYPE.AD_GROUP, campaign?.id);

  const {
    update: updateStaticDisplayAdGroup,
    create: createStaticDisplayAdGroup,
    isLoading: isStaticDisplayAdGroupProcessing,
  } = useGroups(GROUP_TYPE.STATIC_GROUP, campaign?.id);

  const { mutate: invalidateAdGroupSync } = useGroups(
    GROUP_TYPE.AD_GROUP,
    campaign?.id,
    {
      ...(shouldShowOutOfSyncSubmitPrompt && { params: bwSyncParams }),
    }
  );

  const { mutate: invalidateStaticAdGroupSync } = useGroups(
    GROUP_TYPE.STATIC_GROUP,
    campaign?.id,
    {
      ...(shouldShowOutOfSyncSubmitPrompt && { params: bwSyncParams }),
    }
  );

  const { trigger: updateCreative } = usePatchCreative();
  const { trigger: getCreativeLineItem } = useGetCreativeLineItemTrigger();

  const { trigger: updateStaticDisplayCreative } =
    usePatchStaticDisplayCreative();

  const { goToNext } = useWizardSection({
    key: sectionKey,
  });

  const handleSubmit = async (options = {}) => {
    const values = form.getValues();
    const { updateAdGroupParams } = options;
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      budgetPacingOptions,
      campaign,
      adGroup,
      allowedTargeting,
      isDisplay,
      filter: filter(
        adGroup.temporary ? mustIncludePostFields : mustIncludePatchFields
      ),
    });

    const res = await (!adGroup?.temporary
      ? updateAdGroup({
          id: adGroup.id,
          params: updateAdGroupParams,
          ...rest,
        })
      : createAdGroup(rest));

    const lineItems = await getCreativeLineItem(res.id);

    await Promise.all(
      creativesAdditionalData.map(
        async ({ id, weighting, name, click_url, pixels }) => {
          const updatedData = pick(
            lineItems.find(({ creative }) => creative === id),
            ['id', 'lineitem', 'creative']
          );

          await updateCreative(
            removeEmptyValues({
              name,
              id: updatedData.creative,
              pixels,
              click_url,
            })
          );
          // only update line item creative if it is a weighted creative delivery
          if (isWeightedDelivery(values) && !isDisplay) {
            await updateLineItemCreative({
              id: updatedData.id,
              creative: updatedData.creative,
              lineitem: updatedData.lineitem,
              weighting,
            });
          }
        }
      )
    ).catch(e => console.log(e));

    invalidateCreatives();
    if (shouldShowOutOfSyncSubmitPrompt) {
      invalidateAdGroupSync();
    }

    if (state.singleEdit && campaign.active) {
      toCampaignDetails({ campaign });
    } else {
      goToNext({ checkDirty: false });
    }
  };

  const handleStaticDisplaySubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      budgetPacingOptions,
      campaign,
      adGroup,
      isDisplay,
      filter: filter(
        adGroup.temporary ? mustIncludePostFields : mustIncludePatchFields
      ),
    });

    const res = await (!adGroup?.temporary
      ? updateStaticDisplayAdGroup({ id: adGroup.id, ...rest })
      : createStaticDisplayAdGroup({ id: adGroup.id, ...rest }));

    if (res.id) {
      try {
        await Promise.all(
          res.creatives.map(async staticDisplayCreativeId => {
            const additionalData = creativesAdditionalData.filter(
              ({ id }) => id === staticDisplayCreativeId
            )[0];

            await updateStaticDisplayCreative(
              removeNullValues({
                ...additionalData,
                id: staticDisplayCreativeId,
              })
            );
          })
        );

        invalidateDisplay();
        if (shouldShowOutOfSyncSubmitPrompt) {
          invalidateStaticAdGroupSync();
        }

        if (state.singleEdit && campaign.active) {
          toCampaignDetails({ campaign });
        } else {
          goToNext({ checkDirty: false });
        }
      } catch (error) {
        console.error('Error updating static display creatives:', error);
      }
    }
  };

  const handleGeneralError = message => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const submit = handleErrors(
    isDisplay ? handleStaticDisplaySubmit : handleSubmit,
    {
      ...form,
      generalError: handleGeneralError,
      generalErrorFields: [
        ...wizardGeneralErrorFields,
        fields.targeting.path,
      ],
    }
  );

  const handleNormalSubmit = async () => {
    await submit();
  };

  const handleOutOfSyncSubmit = async () => {
    setDialog(
      adServerOutOfSyncPrompt({
        open: true,
        handleClose: () => {
          setDialog(null);
        },
        handleMerge: () => {
          submit();
        },
        handleOverwrite: () => {
          submit({ updateAdGroupParams: targetingOverrideParams });
        },
        outOfSyncItems: getOutOfSyncItems({ adGroup }),
      })
    );
  };

  return {
    submit: shouldShowOutOfSyncSubmitPrompt
      ? handleOutOfSyncSubmit
      : handleNormalSubmit,
    mutating:
      isPostMutating ||
      isAdGroupProcessing ||
      isStaticDisplayAdGroupProcessing,
  };
};
