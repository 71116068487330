import {
  GeoItem,
  SelectionType,
} from '@v2/components/campaign/GeoTargeter/types';
import { omit, pick } from 'lodash';
import {
  fromGeoItemResultToZip,
  getActualFieldPlain,
  getGeoWithTextByDistinct,
} from '../../../GeoTargeter/utils';
import { distinctNames } from '@v2/components/campaign/GeoTargeter';
import { useCheckCity } from '@components/hooks/checkCity';
import { useSnackbar } from 'notistack';

export const useGeographyItem = ({
  value,
  onBlur,
  onUpdate,
}: {
  value: GeoItem;
  onBlur: () => void;
  onUpdate: (value: Partial<GeoItem>) => void;
}) => {
  const { checkCity } = useCheckCity();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (newValue: GeoItem) => {
    const actualValue = newValue ?? {
      id: '',
      place_name: '',
    };
    let isValid = true;
    if (actualValue.type === distinctNames['place']) {
      isValid = await checkCity(actualValue.place_name);
    }

    if (isValid) {
      onUpdate(
        fromGeoItemResultToZip({
          ...pick(actualValue, ['id', 'code', 'place_name', 'type']),
          ...omit(getGeoWithTextByDistinct(actualValue), 'index'),
          name: getActualFieldPlain(actualValue),
          blacklist: newValue?.blacklist ?? false,
        })
      );

      if (newValue) {
        onBlur();
      }
    } else {
      enqueueSnackbar('Selected location not supported', {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'warning',
      });
    }
  };

  const handleChangeSelectionType = (selectionType: SelectionType) => {
    onUpdate({
      ...value,
      blacklist: selectionType === 'exclude',
    });
  };

  return {
    changeSelectionType: handleChangeSelectionType,
    change: handleChange,
  };
};
