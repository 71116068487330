import pencil from '../../../icons/pencil.svg';
import gender from '../../../icons/gender.svg';
import age from '../../../icons/age.svg';
import hhi from '../../../icons/hhi.svg';
import copy from '../../../icons/copy.svg';
import levelLeft from '../../../icons/level-left.svg';
import reactive from '../../../icons/reactive.svg';
import campaign from '../../../icons/campaign.svg';
import calendar from '../../../icons/calendar.svg';
import budget from '../../../icons/budget.svg';
import targeting from '../../../icons/targeting.svg';
import tv from '../../../icons/tv.svg';
import launch from '../../../icons/launch.svg';
import done from '../../../icons/done.svg';
import flaskGear from '../../../icons/flask-gear.svg';
import comingSoon from '../../../icons/coming-soon.svg'
import tvsciq from '../../../icons/tvSciQ.svg'

export const icons = {
  pencil,
  gender,
  age,
  hhi,
  copy,
  reactive,
  levelLeft,
  campaign,
  calendar,
  budget,
  targeting,
  tv,
  launch,
  done,
  flaskGear,
  comingSoon,
  tvsciq
};
