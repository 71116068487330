import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { usePinnedColumns } from './hooks/usePinnedColumns';
import { DataGridProps } from './types';

export const DataGrid = (props: DataGridProps) => {
  const { columns, checkboxSelection, hasHeader = false, disableVirtualization = false } = props;
  if (!columns) {
    return null;
  }

  const { adjustedColumns, pinnedStyles, hasPinnedColumns } =
    usePinnedColumns({ columns, checkboxSelection });

  return (
    <>
      <MuiDataGrid
        {...props}
        disableVirtualization={disableVirtualization || hasPinnedColumns} // Disable virtualization if there are pinned columns or else the pinned columns will not render
        columns={adjustedColumns}
        sx={{
          '.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input': {
            paddingRight: 6
          },
          '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(even)':
            {
              backgroundColor: 'rgba(250, 250, 250, 1)',
            },
          ...pinnedStyles,
          ...(hasHeader && {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }),
        }}
      />
    </>
  );
};
