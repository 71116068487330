/* eslint-disable @typescript-eslint/no-explicit-any */

import { last } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Key, useSWRConfig } from 'swr';
import useSWRInfinite, { unstable_serialize } from 'swr/infinite';
import { useCurrentSession } from '../currentSession';
import type { Page } from '@local-types/general';

export const useInfiniteGlobalCacheUpdate = () => {
  const { mutate } = useSWRConfig();

  return {
    trigger: (key: Key, data: any) => {
      const options = {
        revalidate: false,
        populateCache: true,
      };

      mutate(key, data, options);
      mutate(
        unstable_serialize(() => key),
        data,
        options
      );
    },
  };
};

export const useInfiniteSizer = <T>(
  data: T[] | Page<T>[] | undefined,
  {
    isLoading,
    setSize,
  }: {
    isLoading: boolean;
    setSize: (pager: (prev: number) => number) => void;
  }
) => {
  useEffect(() => {
    if (!isLoading && last(data as Page<T>[])?.next) {
      setSize(prev => prev + 1);
    }
  }, [data, isLoading]);
};

export const useInfiniteCacheUpdate = <T>(
  data: Page<T>[] | undefined,
  keyGenerator?: ((v: T) => Key) | null
) => {
  const [cacheKeys, setCacheKeys] = useState<Key[]>([]);
  const keyGeneratorRef = useRef<((v: T) => Key) | undefined | null>(
    keyGenerator
  );
  const { mutate: swrMutate } = useSWRConfig();

  useEffect(() => {
    const updatedCacheKeys: Key[] = [];
    if (data && keyGeneratorRef.current) {
      data.forEach(page => {
        page.results.forEach(element => {
          const key = keyGeneratorRef.current!(element);
          swrMutate(key, element, {
            populateCache: true,
          });
          updatedCacheKeys.push(key);
        });
      });
    }
    setCacheKeys(updatedCacheKeys);
  }, [data]);

  useEffect(() => {
    keyGeneratorRef.current = keyGenerator;
  }, [keyGenerator]);

  return {
    cacheKeys,
  };
};

export const useLoadInfinite = <T>(
  url: string,
  options: {
    ignoreAdvertiser?: boolean;
    params?: {
      disabled?: boolean;
      v1?: boolean;
      cacheProps?: Record<string, string | number>;
      [key: string]: any;
    };
  } = {},
  mutationOptions = {}
) => {
  const {
    params: { disabled, v1, cacheProps, ...otherParams } = {},
    ignoreAdvertiser,
  } = options;

  const { apiIsReady, currentAdvertiser, get, getV1 } = useCurrentSession();

  const getter = v1 ? getV1 : get;

  const fetcher = ({
    url,
    params,
  }: {
    url: string;
    params: { advertiser?: number; [key: string]: any };
  }) => {
    // The advertiser param is only used to generate the cache key; it is not
    // needed here in the fetcher. We are simply removing it from the params.
    const { advertiser, ...innerParams } = params;
    return getter(url, { v1, ...innerParams, ...otherParams }).then(
      res => res.data
    );
  };

  const advertiser = currentAdvertiser.id;

  const { data, error, isLoading, isValidating, mutate, setSize, size } =
    useSWRInfinite<Page<T>>(
      (pageIndex, previousPageData) => {
        if (disabled || !apiIsReady || (!ignoreAdvertiser && !advertiser))
          return null;

        if (previousPageData && !previousPageData.next) return null;

        return {
          url,
          params: {
            advertiser,
            page: pageIndex + 1,
            ...cacheProps,
          },
        };
      },
      fetcher,
      {
        revalidateFirstPage: false,
        ...mutationOptions,
      }
    );

  useInfiniteSizer<T>(data, { isLoading, setSize });

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    setSize,
    size,
    items: useMemo<T[]>(
      () =>
        data
          ? [...(data?.map(page => page?.results) ?? [])].flat()
          : ([] as T[]),
      [data]
    ),
  };
};
