import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const PREFIX = 'CreativeLibrary';

export const classes = {
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`,
  adGroup: `${PREFIX}-adGroup`,
  adGroupCell: `${PREFIX}-adGroupCell`,
  adGroupHeader: `${PREFIX}-adGroupHeader`,
  assigned: `${PREFIX}-assigned`,
  assignedRow: `${PREFIX}-assignedRow`,
  check: `${PREFIX}-check`,
  displayButton: `${PREFIX}-displayButton`,
  fileSize: `${PREFIX}-fileSize`,
  libraryHeader: `${PREFIX}-libraryHeader`,
  name: `${PREFIX}-name`,
  play: `${PREFIX}-play`,
  na: `${PREFIX}-na`,
  none: `${PREFIX}-none`,
  spinner: `${PREFIX}-spinner`,
  tableCell: `${PREFIX}-tableCell`,
  table: `${PREFIX}-table`,
  unassigned: `${PREFIX}-unassigned`
};


export const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      borderBottom: 'unset'
    }
  },

  [`& .${classes.action}`]: {
    width: 115
  },

  [`& .${classes.adGroup}`]: {
    color: '#727383',
    fontWeight: 600,
    marginTop: 0
  },

  [`& .${classes.adGroupCell}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 160
  },

  [`& .${classes.adGroupHeader}`]: {
    minWidth: 130
  },

  [`& .${classes.assigned}`]: {
    color: palette.secondary.main,
    marginLeft: -8 // aligning based on the left edge of the text
  },

  [`& .${classes.assignedRow}`]: {
    background: '#0000000a',
    opacity: 0.9
  },

  [`& .${classes.check}`]: {
    width: '100%',
    marginLeft: -10
  },

  [`& .${classes.displayButton}`]: {
    marginLeft: -3 // aligning based on the visible left edge of the button
  },

  [`& .${classes.fileSize}`]: {
    minWidth: 100
  },

  [`& .${classes.libraryHeader}`]: {
    marginBottom: spacing(4)
  },

  [`& .${classes.name}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220
  },

  [`& .${classes.play}`]: {
    marginTop: 4
  },

  [`& .${classes.na}`]: {
    fontWeight: 500
  },

  [`& .${classes.none}`]: {
    color: '#727383'
  },

  [`& .${classes.spinner}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center'
  },

  [`& .${classes.tableCell}`]: {
    minWidth: 120,
    minHeight: 81,
    height: 81
  },

  [`& .${classes.table}`]: {
    maxHeight: 375,
    minHeight: 375
  },

  [`& .${classes.unassigned}`]: {
    color: '#e57373',
    marginLeft: -8 // aligning based on the left edge of the text
  }
}));
