import React, { createContext, useContext, useState } from 'react';
import { useCreatives } from '@apis/creatives';
import { Creative } from '@local-types';

export interface CreativesTableContextProviderProps {
  children: React.ReactNode;
}

export interface CreativesTableContextType
  extends Omit<CreativesTableContextProviderProps, 'children'> {
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (model: { page: number; pageSize: number }) => void;
  count: number;
  creatives: Creative[];
  handleArchiveUnarchiveCreative: (creative: Creative) => void;
}

export const CreativesTableContext = createContext<CreativesTableContextType>(
  {
    isLoading: false,
    paginationModel: {
      page: 0,
      pageSize: 25,
    },
    setPaginationModel: () => {},
    count: 0,
    creatives: [],
    handleArchiveUnarchiveCreative: () => {},
  },
);

export const CreativesTableContextProvider = ({
  children,
  ...rest
}: CreativesTableContextProviderProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const {
    creatives,
    count = 0,
    isLoading,
    archiveTrigger,
  } = useCreatives({
    page: paginationModel.page + 1,
    params: {
      page_size: paginationModel.pageSize,
      expand: 'lineitem_set,stats_caches_by_lineitem_id',
    },
    options: {
      keepPreviousData: true,
    },
  });

  const handleArchiveUnarchiveCreative = async (creative: Creative) => {
    await archiveTrigger({
      id: creative.id as never,
      isArchiving: !creative.archived,
    });
  };

  return (
    <CreativesTableContext.Provider
      value={{
        ...rest,
        paginationModel,
        setPaginationModel,
        count,
        creatives,
        isLoading,
        handleArchiveUnarchiveCreative,
      }}
    >
      {children}
    </CreativesTableContext.Provider>
  );
};

export const useCreativesTable = () => {
  return useContext(CreativesTableContext);
};
