import { deburr, defaults, reduce, values } from 'lodash';
import useSWR from 'swr';
import { useAPI } from '@hooks/api';
import { dmaData } from '@components/util';
import { DMAItem, GeoItem, GeoSearchParams } from '../types';
import {
  distinctNames,
  geoBaseUrl,
  geoSearchDefaultParams,
  geoTypes,
} from '../constants';

const searchDMAs = (search = '', limit = 5): GeoItem[] => {
  const lowerSearch = search.toLowerCase();
  return reduce<DMAItem, GeoItem[]>(
    dmaData,
    (result: GeoItem[], dma: DMAItem) => {
      const shouldAdd =
        result.length < limit &&
        dma.text.toLowerCase().startsWith(lowerSearch);

      return [
        ...result,
        ...(shouldAdd
          ? [
              {
                ...result,
                name: dma.text,
                text: dma.text,
                place_name: dma.text,
                id: dma.code.toString(),
                code: dma.code.toString(),
                type: distinctNames.dma,
              },
            ]
          : []),
      ];
    },
    []
  );
};

export const useGeoSearchV2 = (
  search = '',
  { disabled = false, ...otherParams }: Partial<GeoSearchParams> = {
    disabled: false,
    country: 'us',
    types: values(geoTypes),
    autocomplete: true,
  }
) => {
  const { unhandledGet } = useAPI();

  const params = defaults(otherParams, geoSearchDefaultParams);

  const fetcher = async ({
    url,
    params,
  }: {
    url: string;
    params: typeof geoSearchDefaultParams;
  }): Promise<GeoItem[]> => {
    const { types, ...other } = params;
    const actualTypes = types.filter(v => v !== geoTypes.dma);
    const shouldSearchDMA = types.includes(geoTypes.dma);

    const dmas = shouldSearchDMA ? searchDMAs(search, 2) : [];

    if (!actualTypes.length) {
      return dmas;
    }

    return unhandledGet(url, {
      ...other,
      types: actualTypes.join(','),
    }).then(res => {
      const { features } = res.data;
      return [
        ...dmas,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...features.map((v: any) => {
          const type =
            distinctNames[v.place_type[0] as keyof typeof distinctNames];

          return {
            ...v,
            type,
          };
        }),
      ];
    });
  };

  const inputValue = deburr(search.trim()).toLowerCase();

  const url = `${geoBaseUrl}/${encodeURIComponent(inputValue)}.json`;
  // TODO: If we use geoBaseUrlV2, the base URL is all we need, plus the params (with q param)
  // const url_v2 = geoBaseUrlV2;

  return useSWR(
    inputValue.length >= 3 && !disabled ? { url, params } : null,
    fetcher
  );
};
