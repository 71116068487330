import { classes } from '@components/CreativeLibrary/styles';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import React from 'react';
import { useDomain } from '@hooks/domain';
import { useCreativeLibrary } from '@components/CreativeLibrary/CreativeLibraryContext';

export const CreativeLibraryHeader = () => {
  const domain = useDomain();
  const { search, setSearch, selected, handleAssignSelected } =
    useCreativeLibrary();

  return (
    <Grid
      className={classes.libraryHeader}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={6}>
        <Typography variant="h2">Creative</Typography>

        {domain.peacock && (
          <p>
            Select the checkbox next to a previously saved creative to assign
            it to your campaign.
          </p>
        )}
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        xs={6}
      >
        <Grid item xs={9}>
          <TextField
            color="secondary"
            fullWidth
            inputProps={{
              'data-testid': 'creative-search',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={event => setSearch(event.target.value)}
            size="small"
            placeholder="Search creative"
            value={search}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            disabled={selected.length === 0}
            color="secondary"
            onClick={handleAssignSelected}
            startIcon={<PlayCircleFilledIcon />}
            variant="contained"
          >
            Assign
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
