import { GridCellParams } from '@mui/x-data-grid';

// Renders a string formatted as a currency
export const CurrencyCellRenderer = (params: Partial<GridCellParams>) => {
  if (!params.value) {
    return '-';
  }

  const value = parseFloat(params.value as string);
  if (isNaN(value)) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
};
