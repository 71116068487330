import { useSnackbar } from 'notistack';
import { every } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { fields, TABS } from '../../constants';
import { Scopes } from '@constants/scopes';
import { useCreativeContext } from '../../hooks/useCreativeContext';
import { handlers } from '../utils';
import { useDimensions } from '@components/hooks/dimensions';
import { WizardPageContext } from '@components/containers/WizardPage/constants';
import { usePermissions } from '@components/hooks/permissions';

export const useUpload = ({ mediaType }) => {
  const { localAssets } = useContext(WizardPageContext);
  const [uploadingIds, setUploadingIds] = useState(new Set());
  const [isUploading, setIsUploading] = useState(false);
  const { drop, toggleAssignCreative, tab, setTab, cancelUploading } =
    useCreativeContext();
  const { watch, setValue } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();
  const { dimensions } = useDimensions();

  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const files = watch(fields.files.path);

  const visibleUploadingFiles = files.filter(({ id }) =>
    uploadingIds.has(id)
  );

  const handleDrop = useCallback(async files => {
    const assetLink = {};
    setIsUploading(true);

    const filesToUpload = await handlers[mediaType](
      files,
      dimensions,
      enqueueSnackbar
    );

    drop(
      filesToUpload,
      files => {
        files.forEach(({ video_asset, id }) => {
          assetLink[video_asset] = id;
        });

        setUploadingIds(new Set(files.map(({ id }) => id)));
      },
      ({ current }) => {
        localAssets.set(current.id, current.video_asset);
        setUploadingIds(prevState => {
          const newState = new Set(prevState);
          newState.delete(assetLink[current.video_asset]);
          newState.add(current.id);
          return newState;
        });
      }
    ).finally(() => {
      setIsUploading(false);
    });
  }, []);

  const handleDeleteLocal = id => {
    setValue(
      fields.files.path,
      files.filter(f => f.id !== id)
    );
  };

  const handleRemove = id => {
    handleDeleteLocal(id);
    toggleAssignCreative(
      files.find(f => f.id === id),
      false
    );
  };

  const handleCancel = id => {
    handleDeleteLocal(id);
    cancelUploading(id);
  };

  useEffect(() => {
    if (tab.value?.files?.length) {
      handleDrop(tab.value.files);
    }
  }, [tab, handleDrop]);

  const uploading = isUploading;
  const processing =
    uploading &&
    every(visibleUploadingFiles, ({ progress }) => progress === 100);

  const nextButtonContent = useMemo(() => {
    if (processing) {
      return 'Processing...';
    }
    if (uploading) {
      return 'Uploading...';
    }

    return 'Next: Manage Creative';
  }, [uploading, processing]);

  useEffect(() => {
    if (tab.value?.files?.length && !uploading && !processing) {
      setTab({
        key: TABS.UPLOAD_ASSET,
      });
    }
  }, [tab, processing, uploading]);

  return {
    drop: handleDrop,
    remove: handleRemove,
    cancel: handleCancel,
    uploading,
    processing,
    disabled: !visibleUploadingFiles.length || uploading || processing,
    nextButtonContent,
    files,
    showQCFunctionality,
  };
};
