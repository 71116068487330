import { LookerEmbedDashboard } from '@looker/embed-sdk';
import React, { createContext, useState, useContext } from 'react';

export interface Filters {
  [key: string]: string
}

interface LookerFilterContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  updateDashboardRefs: (dashboardId: string, dashboard: LookerEmbedDashboard) => void;
  updateFilters: (dashboardId: string) => void;
  dashboardRefs: { [key: string]: LookerEmbedDashboard } | null;
}

export const LookerFiltersContext = createContext<LookerFilterContextType>({
  filters: {},
  setFilters: () => { },
  updateDashboardRefs: () => { },
  updateFilters: () => { },
  dashboardRefs: null,
});

export const LookerFiltersContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [filters, setFilters] = useState({});
  const [dashboardRefs, setDashboardRefs] = useState<{ [key: string]: LookerEmbedDashboard } | null>(null);

  const updateFilters = (dashboardId: string) => {
    if (dashboardRefs && dashboardRefs[dashboardId]) {
      dashboardRefs[dashboardId].updateFilters(filters);
    }
  };

  const updateDashboardRefs = (dashboardId: string, dashboard: LookerEmbedDashboard) => {
    setDashboardRefs((prev) => {
      return {
        ...prev,
        [dashboardId]: dashboard,
      };
    });
  }

  return (
    <LookerFiltersContext.Provider value={{ filters, setFilters, updateDashboardRefs, updateFilters, dashboardRefs }}>
      {children}
    </LookerFiltersContext.Provider>
  );
};

export const useLookerFiltersContext = () => {
  return useContext(LookerFiltersContext);
};
