import { useGroups, GROUP_TYPE } from '@hooks/apis/groups';
import { getCampaignId, getGroupType } from './utils';
import { useCampaignAdGroupsPage } from '@hooks/apis/adGroups';
import { useFlags } from '@hooks/flags';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { LineItem } from '@local-types';

export const useBWOverride = (adGroup: LineItem) => {
  const campaignId = getCampaignId(adGroup);
  const groupType = getGroupType(adGroup);
  const url =
    groupType === GROUP_TYPE.AD_GROUP
      ? '/lineitems/'
      : '/static_display_lineitems/';

  const { update: updateGroup } = useGroups(groupType, campaignId);
  const { v2AdgroupsLineItems } = useLDFlags();
  const { flags } = useFlags();
  const isArchivingEnabled = flags.USER_CAN_ARCHIVE;
  const v2Endpoint = v2AdgroupsLineItems || isArchivingEnabled;

  const { mutate } = useCampaignAdGroupsPage(
    campaignId,
    {
      v1: !v2Endpoint,
      sync: 1,
    },
    url
  );

  const handleBWOverride = async () => {
    await updateGroup({
      id: adGroup.id,
      params: { targeting_override: 1 },
    });
    mutate();
  };

  return {
    handleBWOverride,
  };
};
