import React from 'react';
import { Controller } from 'react-hook-form';
import { Stack, MenuItem } from '@mui/material';
import { TextFieldStyled } from '@v2/components/ui/styles';
import { fields } from '../constants';
import { EventPickerType } from './types';

export const EventPicker = ({
  control,
  bidStrategyEventSet,
}: EventPickerType) => (
  <Stack>
    <Controller
      control={control}
      name={fields.adGroupBidStrategyEvent.path}
      render={({ field, fieldState }) => {
        return (
          <TextFieldStyled
            {...field}
            {...fieldState}
            select
            fullWidth
            id="bid-strategy-event"
            data-testid="bid-strategy-event"
            label="Conversion Event"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            sx={{ marginLeft: 0, marginBottom: 2.5 }}
          >
            {bidStrategyEventSet?.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                data-testid={`bid-strategy-event-${id}`}
              >
                {name}
              </MenuItem>
            ))}
          </TextFieldStyled>
        );
      }}
    />
  </Stack>
);
