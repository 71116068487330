import useSWR from 'swr';

import { Flags } from '../../../constants/flags';
import { useCurrentSession } from '../currentSession';

export const useGetFlags = () => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params })
      .then(res => res.data)
      .catch(error => {
        if (error.response.status !== 403) {
          return {};
        }

        throw error;
      });

  const swr = useSWR(
    apiIsReady
      ? {
          url: 'flags/cm/',
          advertiser: currentAdvertiser.id ?? '-',
        }
      : null,
    fetcher,
    {
      shouldRetryOnError: false,
    }
  );

  const { data, error } = swr;

  const isFlagsLoading = !data && !error;

  const flags = Object.keys(Flags).reduce(
    (acc, key) => ({
      ...acc,
      [key]: data?.[key] ?? false,
    }),
    {}
  );

  return {
    flags,
    error,
    isFlagsLoading,
  };
};
