import { RoutePaths } from '@constants/routes';
import { getPrimaryOrgId } from '@helpers/getPrimaryOrgId';
import { generatePath, useHistory } from 'react-router';
import { useAdvertisers } from './advertisers';
import { useQuery } from './query';
import { Flags } from '@constants/flags';
import { useFlags } from './flags';
import { Advertiser } from '@local-types';

interface QueryParameters {
  [key: string]: string;
}

export const useWizardRedirect = () => {
  const urlQuery = useQuery();
  const history = useHistory();
  const { currentAdvertiser } = useAdvertisers() as { currentAdvertiser: Advertiser | null };
  const { flags } = useFlags();

  const handleSetQuery = (): void => {
    if (currentAdvertiser) {
      const primaryOrgId = getPrimaryOrgId(currentAdvertiser.primary_org);
      if (primaryOrgId !== null) {
        urlQuery.set('org', primaryOrgId);
      }
      urlQuery.set('adAccount', currentAdvertiser.id.toString());
    }
  };

  const handleReplicateQuery = (additionalQueryParameters: QueryParameters = {}): URLSearchParams => {
    const newQuery = new URLSearchParams(urlQuery.toString());
    Object.keys(additionalQueryParameters).forEach(key => {
      newQuery.set(key, additionalQueryParameters[key]);
    });
    return newQuery;
  };

  const handleEditCampaign = ({ campaignId, query }: { campaignId: string; query?: QueryParameters }): void => {
    handleSetQuery();
    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, { campaignId }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  const handleEditGroup = ({ campaignId, groupId, query }: { campaignId: string; groupId: string; query?: QueryParameters }): void => {
    handleSetQuery();
    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
        campaignId,
        sectionId: groupId,
      }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  const handleNewGroup = ({ campaignId, query }: { campaignId: string; query?: QueryParameters }): void => {
    handleSetQuery();
    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
        campaignId,
        sectionId: 'new',
      }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  return {
    isWizardEnabled: flags[Flags.USER_GETS_VERTICAL_CAMPAIGN],
    newGroup: handleNewGroup,
    editCampaign: handleEditCampaign,
    editGroup: handleEditGroup,
  };
};
