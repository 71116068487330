import PropTypes from 'prop-types';
import { ReactElement } from 'react';
import { routes } from './routes';

export const sectionProps = {
  sectionName: PropTypes.string,
  sectionKey: PropTypes.string,
  dirty: PropTypes.bool,
  adGroup: PropTypes.object,
  campaignId: PropTypes.number,
  localAssets: PropTypes.any, // it's a Map<number, string> but PropTypes doesn't support that
};

export const wizardProps = {
  sections: PropTypes.arrayOf(PropTypes.string),
  campaign: PropTypes.object,
  groups: PropTypes.array,
};

export const subwayProps = {
  ...sectionProps,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  menuDisabled: PropTypes.bool,
};

export type GoToOptions = {
  checkDirty?: boolean;
  workflowName?: string;
  base?: RouteBase;
};

export type NavigationLink = string | number;

export type RouteBase = (typeof routes)[keyof typeof routes];

export type BaseWizardProps = {
  children: ReactElement[];
  base?: RouteBase;
};

export type WizardQueryRedirectAdditionalOptions = {
  base?: RouteBase;
};

export type WizardProps =
  | BaseWizardProps
  | (BaseWizardProps & {
      state: Record<string, unknown>;
      updateState: (state: Record<string, unknown>) => void;
    });
