import React, { useContext } from 'react';
import OrgContext from '../../providers/OrgContext';
import { Org } from '@local-types';

interface OrgContextType {
  org: Org | null;
  setOrg: React.Dispatch<React.SetStateAction<Org | null>>;
}

export const useOrg = (): OrgContextType => {
  const orgContext = useContext(OrgContext);

  if (orgContext === null) {
    throw new Error('useOrg must be used within an OrgContextProvider');
  }

  return orgContext;
};
