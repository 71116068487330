import { useFormContext } from 'react-hook-form';
import { Copies, fields } from '../../constants';
import React, { useContext, useEffect } from 'react';
import AdvertiserContext from '@components/AdvertiserContext';
import { useCopy } from '@hooks/copy';
import { useDomain } from '@hooks/domain';
import { useFlags } from '@hooks/flags';
import { useRoles } from '@hooks/roles';
import { getPacingStrings } from '../../../PacingFieldsGroup/utils';
import { useGetBudgetPacingOptions } from '@apis/budgetPacingOptions';
import { GroupOptionsType } from '../types';
import {
  getRecommendedBidRange,
  getRangeText,
  getUnlockedContent,
  getLockedStatus,
  getLockedInfo,
} from '../utils';
import { BID_STRATEGIES, trackingLockedInfo } from '../constants';

export const useBidStrategy = ({
  onAdvanced,
}: {
  onAdvanced: () => void;
}) => {
  const { flags, Flags } = useFlags();

  const { setValue, watch, trigger, getValues, control } = useFormContext();

  const domain = useDomain();
  const roles = useRoles();

  const Copy = useCopy(Copies);
  const adContext = useContext(AdvertiserContext);

  const {
    data: budgetPacingOptions = { behavior: [], catchup: [], pacing: [] },
  } = useGetBudgetPacingOptions();

  const isPacingAllowed = flags[Flags.USER_GETS_VERTICAL_PACING];

  const bidStrategyEvents = adContext?.bidstrategyevent_set;

  const shouldShowBidStrategy =
    adContext?.bidstrategy_set?.length > 0 &&
    adContext.tvsciq &&
    adContext.tvsciq !== 'DISABLED';

  const [bundles, inventoryOption, bidStrategy] = watch([
    fields.advancedCustomInventory.path,
    fields.inventoryOption.path,
    fields.adGroupBidStrategy.path,
  ]);

  const [
    frequencyCap = [],
    dayparting = [],
    isMaxCPMBidEnabled,
    isPacingEnabled,
    pacing,
    pacingBehavior,
    pacingCatchupBehavior,
  ] = watch([
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.maxCPMBidEnabled.path,
    fields.adGroupPacingEnabled.path,
    fields.adGroupPacing.path,
    fields.adGroupPacingBehavior.path,
    fields.adGroupPacingCatchupBehavior.path,
  ]);

  const handleAdvanced = (index: number, type: string) => {
    setValue(
      fields.configureAdGroupFrequencyCap.path,
      frequencyCap?.map((v: typeof frequencyCap, i: number) => ({
        ...v,
        isEditing: i === index && type === 'frequencyCap',
      })) ?? []
    );

    setValue(
      fields.configureAdGroupDayparting.path,
      dayparting?.map((v: typeof dayparting, i: number) => ({
        ...v,
        isEditing: i === index && type === 'dayparting',
      })) ?? []
    );

    onAdvanced();
  };

  const pacingStrings = getPacingStrings({
    budgetPacingOptions,
    pacing,
    pacingBehavior,
    pacingCatchupBehavior,
  });

  const handleCPMSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(fields.maxCPMBidEnabled.path, event.target.checked, {
      shouldDirty: true,
    });
    if (!event.target.checked) {
      setValue(fields.maxCPMBid.path, null, { shouldDirty: true });
    }
  };

  const groupOptions = ({
    control,
    isMaxCPMBidEnabled,
    bidStrategySet,
    bidStrategyEventSet,
    bundles,
    inventoryOption,
    domain,
    roles,
    handleCPMSwitchChange,
  }: GroupOptionsType) => {
    const recommendedBidRange = getRecommendedBidRange({
      bundles,
      inventoryOption,
      peacock: domain.peacock,
      isInternal: roles.TENANT_ADMIN,
    });
    const rangeText = getRangeText(recommendedBidRange);

    return bidStrategySet.map(option => ({
      value: option.id,
      label: option.display_name,
      description: option.description,
      unlockedContent: getUnlockedContent({
        option,
        control,
        bidStrategyEventSet,
        rangeText,
        handleCPMSwitchChange,
        isMaxCPMBidEnabled,
      }),
      locked: getLockedStatus({ option, bidStrategyEventSet }),
      lockedInfo: getLockedInfo({
        option,
        lockedInfo: { trackingLockedInfo },
      }),
      infoText: option.tooltip,
    }));
  };

  const bidStrategyOptions = groupOptions({
    control,
    isMaxCPMBidEnabled,
    bidStrategySet: adContext?.bidstrategy_set ?? [],
    bidStrategyEventSet: adContext?.bidstrategyevent_set ?? [],
    bundles,
    inventoryOption,
    domain,
    roles,
    handleCPMSwitchChange,
  });

  const selectedBidStrategy = bidStrategyOptions.find(
    option => option.value === bidStrategy
  );

  const handleRemoveEvent = () => {
    setValue(fields.adGroupBidStrategyEvent.path, null);
  };

  useEffect(() => {
    const bidStrategyEvent = getValues(fields.adGroupBidStrategyEvent.path);

    switch (selectedBidStrategy?.label) {
      case BID_STRATEGIES.MAX_OUTCOMES:
        if (bidStrategyEvents.length === 1 && !bidStrategyEvent) {
          setValue(
            fields.adGroupBidStrategyEvent.path,
            bidStrategyEvents[0].id
          );
        }
        break;
    }
  }, [selectedBidStrategy, bidStrategyEvents]);

  return {
    setValue,
    control,
    frequencyCap,
    Copy,
    trigger,
    dayparting,
    removeEvent: handleRemoveEvent,
    shouldShowBidStrategy,
    advanced: handleAdvanced,
    isPacingAllowed,
    isPacingEnabled,
    pacingStrings,
    bidStrategyOptions,
  };
};
