import React from 'react';
import {
  styled,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

export const InfoText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.caption.fontSize,
}));

export const WarningHeader = styled(InfoText)({
  color: '#FF4B76',
});

export const BWOverrideTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[2],
    width: theme.spacing(24.625),
    padding: theme.spacing(1.5),
  },
}));
