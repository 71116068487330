import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { useGetTableHeaders } from '@components/containers/AdGroupsIndexPage/hooks/useGetTableHeaders';
import SortableTableHead from '@components/ui/SortableTableHead';
import { useFlags } from '@hooks/flags';
import { useSort } from '@hooks/sort';
import { LineItem } from '@local-types';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Table, TableBody, TableContainer } from '@mui/material';
import React, { useState } from 'react';
import { AdGroupsTableLineItemRow } from './AdGroupsTableLineItemRow';
import { AdGroupsTableStaticDisplayRow } from './AdGroupsTableStaticDisplayRow';
import { classes } from './styles';

export const renderStatus = (status: number | boolean, type: string) => {
  if (status) {
    return (
      <>
        <PauseCircleOutlineOutlinedIcon
          fontSize="small"
          className={classes.menuIcon}
        />
        Pause {type}
      </>
    );
  }

  return (
    <>
      <PlayCircleOutlineIcon fontSize="small" className={classes.menuIcon} />
      Activate {type}
    </>
  );
};

export const AdGroupsTable = () => {
  const {
    campaignId,
    campaign,
    handleDuplicateAdGroup,
    handleIsDuplicatable,
    handleOpenWeighting,
    handleOpenBid,
    sortedAdGroups,
    handlePauseActive,
    handleOpenRealTimeModal,
    handleArchiveUnarchiveAdGroup,
    ...rest
  } = useAdGroupsTable();

  const { flags, Flags } = useFlags();
  const { order, orderBy, handleRequestSort } = useSort();

  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];

  const [currentAnchorEl, setCurrentAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [targetMenu, setTargetMenu] = useState<number | null>(null);
  const headCells = useGetTableHeaders();

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number
  ) => {
    setCurrentAnchorEl(event.currentTarget);
    setTargetMenu(rowId);
  };

  const handleCloseMenu = () => {
    setCurrentAnchorEl(null);
    setTargetMenu(null);
  };

  const handleManageDisplay = (display: LineItem) => {
    if (isVerticalCampaignFlowEnabled) {
      handleCloseMenu();
    }
    return rest.handleManageDisplay(display);
  };

  const handleManageAdGroup = (adGroup: LineItem) => {
    if (isVerticalCampaignFlowEnabled) {
      handleCloseMenu();
    }
    return rest.handleManageAdGroup(adGroup);
  };

  return (
    <TableContainer>
      <Table>
        <SortableTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedAdGroups.map((adGroup, index) => {
            if (adGroup.url && adGroup.url.includes('static')) {
              return (
                <AdGroupsTableStaticDisplayRow
                  key={adGroup.id}
                  display={adGroup}
                  campaignId={campaignId}
                  campaign={campaign}
                  index={index}
                  handleOpenMenu={handleOpenMenu}
                  handleCloseMenu={handleCloseMenu}
                  currentAnchorEl={currentAnchorEl}
                  targetMenu={targetMenu}
                  handleOpenRealTimeModal={handleOpenRealTimeModal}
                  handleManageDisplay={handleManageDisplay}
                />
              );
            }

            return (
              <AdGroupsTableLineItemRow
                key={adGroup.id}
                adGroup={adGroup}
                campaignId={campaignId}
                campaign={campaign}
                index={index}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
                currentAnchorEl={currentAnchorEl}
                targetMenu={targetMenu}
                handleManageAdGroup={handleManageAdGroup}
                handleDuplicateAdGroup={handleDuplicateAdGroup}
                handleOpenWeighting={handleOpenWeighting}
                handlePauseActive={handlePauseActive}
                handleOpenBid={handleOpenBid}
                handleIsDuplicatable={handleIsDuplicatable}
                handleOpenRealTimeModal={handleOpenRealTimeModal}
                handleArchiveUnarchiveAdGroup={handleArchiveUnarchiveAdGroup}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
