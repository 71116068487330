import React, { useMemo } from 'react';
import { Button, Collapse, ListItem, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { INestedListItemProps } from './types';
import { List } from './styled';

const NestedListItem: React.FC<INestedListItemProps> = ({ item, path = [], selectedPath, onSelectItem }) => {
  const currentPath = useMemo(() => [...path, item], [path, item]);
  const isSelected = selectedPath.some(selectedItem => selectedItem.id === item.id);
  const isExpanded = selectedPath.slice(0, currentPath.length).every((selectedItem, index) => 
    currentPath[index].id === selectedItem.id
  );

  const hasChildren = useMemo(
    () => item.children && item.children.length > 0,
    [item.children],
  );

  const findFirstLeafNode = (
    item: INestedListItemProps['item'],
    currentPath: INestedListItemProps['item'][] = []
  ): INestedListItemProps['item'][] => {
    const newPath = [...currentPath, item];
    if (!item.children?.length) {
      return newPath;
    }
    return findFirstLeafNode(item.children[0], newPath);
  };

  const handleItemClick = (
    item: INestedListItemProps['item'],
    path: INestedListItemProps['path'] = []
  ) => {
    if (!hasChildren) {
      onSelectItem([...path, item]);
    } else if (item.children?.length) {
      const leafNodePath = findFirstLeafNode(item);
      onSelectItem(leafNodePath);
    }
  };

  return (
    <>
      <ListItem
        component={Button}
        onClick={() => handleItemClick(item, path)}
        sx={{ padding: '4px' }}
      >
        <ListItemText
          primary={item.name}
          sx={{
            margin: 0,

            '.MuiListItemText-primary': {
              fontWeight: isSelected ? 'bold' : 'normal',
              fontSize: '0.875rem',
            },
          }}
        />

        {hasChildren && isExpanded && <ExpandLess />}
        {hasChildren && !isExpanded && <ExpandMore />}
      </ListItem>

      {hasChildren && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            {item.children?.map((child, index) => (
              <NestedListItem
                key={index}
                item={child}
                path={currentPath}
                selectedPath={selectedPath}
                onSelectItem={onSelectItem}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default NestedListItem;
