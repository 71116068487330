/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, Dispatch, SetStateAction } from 'react';
import { AxiosProgressEvent, AxiosResponse } from 'axios/index.d';

import { useAuth } from './auth';
import { useAPI } from './api';
import { useDimensions } from './dimensions';
import AdvertiserContext from '../AdvertiserContext';
import apiInstance from '../../connection/apiClient';

const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
// Max file size limit is 500 mb
const fileMax = 500 * 1024 * 1024;

export const formatFileName = (file: string): string =>
  file && file.trim().replace(/[^a-z0-9.]/gi, '_');

const formatUploadSize = (num: number): string => {
  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1024)),
    units.length - 1
  );
  const size = (num / Math.pow(1024, exponent)).toFixed(2);
  const unit = units[exponent];

  return `${size} ${unit}`;
};

interface UploadMetadata {
  key: string;
  title: string;
  fileName: string;
  fileSize: string;
  resolution: string;
  uploadable: boolean;
  weighting?: number;
  duration?: number | null;
}

interface UploadProps {
  uploadMetadataState?: [UploadMetadata[], Dispatch<SetStateAction<UploadMetadata[]>>];
}

interface Dimension {
  creative_width: number;
  creative_height: number;
}

interface UploadHookResult {
  formatFileName: (file: string) => string;
  isUploading: boolean;
  setIsUploading: Dispatch<SetStateAction<boolean>>;
  isUploadSuccess: boolean;
  setIsUploadSuccess: Dispatch<SetStateAction<boolean>>;
  progressBars: Record<string, number>;
  setProgressBars: Dispatch<SetStateAction<Record<string, number>>>;
  deleteFile: (url: string) => Promise<any>;
  uploadMetadata: UploadMetadata[];
  uploadError: string[] | null;
  setUploadError: Dispatch<SetStateAction<string[] | null>>;
  setUploadMetadata: Dispatch<SetStateAction<UploadMetadata[]>>;
  uploadCreative: (files: File[]) => Promise<AxiosResponse<any>[] | undefined>;
  uploadDisplay: (files: File[]) => Promise<AxiosResponse<any>[] | undefined>;
}

export const useUpload = (props?: UploadProps): UploadHookResult => {
  const {
    uploadMetadataState = useState<UploadMetadata[]>([]),
  } = props || {};
  const [uploadMetadata, setUploadMetadata] = uploadMetadataState;

  const adContext = useContext(AdvertiserContext);
  const { authState } = useAuth();
  const { useDelete } = useAPI();
  const { dimensions } = useDimensions();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [progressBars, setProgressBars] = useState<Record<string, number>>({});
  const [uploadError, setUploadError] = useState<string[] | null>(null);

  const handleCheckDimensions = (width: number, height: number): boolean =>
    dimensions.some(
      (d: Dimension) => d.creative_width === width && d.creative_height === height
    );

  const fetchDimensions = (image: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;

      const reader = new FileReader();

      reader.onloadend = function (ended: ProgressEvent<FileReader>) {
        img.src = ended.target?.result as string;
      }

      reader.readAsDataURL(image);
    });
  };

  const handleProgressUpload = (fileName: string) => (progressEvent: AxiosProgressEvent) => {
    const total = progressEvent.total ?? null;

    if (total !== null) {
      setProgressBars((prev) => ({
        ...prev,
        [fileName]: Math.round((progressEvent.loaded * 100) / total),
      }));
    }
  };

  const deleteFile = (url: string) =>
    useDelete(url)
      .then((response) => {
        console.log('response from delete file', response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });

  const uploadDisplay = async (files: File[]) => {
    const nextUploadMetadata = [...uploadMetadata];

    const invalidFilesErrors: string[] = [];
    const filesToUpload: File[] = [];

    const dimensions = await Promise.all(files.map(file => fetchDimensions(file)));

    files.forEach((file, index) => {
      const name = file.name || '';

      if (file.size && file.size > fileMax) {
        invalidFilesErrors.push(`File ${name} size cannot exceed 100 MB`);
        return;
      }

      const { width, height } = dimensions[index];
      const isDimensionValid = handleCheckDimensions(width, height);

      if (!isDimensionValid) {
        invalidFilesErrors.push(`${name} has invalid width x height combination: ${width}x${height}. If you are uploading other files, do not leave this page.`);
        return;
      }

      filesToUpload.push(file);
    });

    if (invalidFilesErrors.length > 0)
      setUploadError(invalidFilesErrors);

    setIsUploadSuccess(false);

    const uploadPromises = filesToUpload.map((file) => {
      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authState.accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newDisplay: UploadMetadata = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        resolution: 'Calculating...',
        uploadable: true,
      };

      setIsUploading(true);

      setProgressBars(prev => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newDisplay);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);
      formData.append('active', 'true');

      return apiInstance.post('/image_assets/', formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then(responses => {
        const nextResponses = responses.filter(r => r !== null);
        console.log('responses', nextResponses);
        setIsUploadSuccess(true);

        return nextResponses;
      })
      .catch(error => {
        console.log('Error in uploadDisplay', error);

        return error;
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const uploadCreative = (files: File[]): Promise<AxiosResponse<any>[] | undefined> => {
    setIsUploadSuccess(false);

    const nextUploadMetadata = [...uploadMetadata];

    const validFiles = files.filter(file => {
      if (file.size && file.size > fileMax) {
        setUploadError(['File size cannot exceed 500 MB']);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) {
      return Promise.resolve(undefined);
    }

    const uploadPromises = validFiles.map((file) => {
      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authState.accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newCreative: UploadMetadata = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        weighting: 100,
        duration: null,
        resolution: 'Calculating...',
        uploadable: true,
      };

      setIsUploading(true);

      setProgressBars((prev) => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newCreative);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);

      return apiInstance.post('/video_assets/', formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then((responses) => {
        setIsUploadSuccess(true);
        return responses;
      })
      .catch((error) => {
        console.error(error);
        return undefined;
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return {
    formatFileName,
    isUploading,
    setIsUploading,
    isUploadSuccess,
    setIsUploadSuccess,
    progressBars,
    setProgressBars,
    deleteFile,
    uploadMetadata,
    uploadError,
    setUploadError,
    setUploadMetadata,
    uploadCreative,
    uploadDisplay,
  };
};

