import React from 'react';
import { getActualField } from './utils';
import { GeographyPreviewProps } from './types';
import { GeographyPreviewSegment } from './GeographyPreviewSegment';

export const GeographyPreview = ({
  value,
  onChange,
  onEdit,
  placeholder = 'Geography selector',
}: GeographyPreviewProps) => {
  return (
    <GeographyPreviewSegment
      blacklist={value.blacklist ?? false}
      onChange={({ blacklist }) => {
        onChange({ ...value, blacklist });
      }}
      label={getActualField(value) ?? placeholder}
      onEdit={onEdit}
      placeholder={placeholder}
    />
  );
};
