import React from 'react';
import { WizardContext } from './constants/wizard';

import PropTypes from 'prop-types';

export const WizardProvider = ({
  children,
  navigation,
  state,
  updateState,
}: {
  children: React.ReactNode;
  navigation: ReturnType<
    typeof import('./hooks/useWizardNavigation')['useWizardNavigation']
  >;
  state?: Record<string, unknown>;
  updateState?: (state: Record<string, unknown>) => void;
}) => {
  return (
    <WizardContext.Provider
      value={{
        navigation,
        state,
        updateState,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.object,
  sections: PropTypes.shape({
    statuses: PropTypes.object,
    updateStatus: PropTypes.func,
  }),
};
