/* eslint-disable @typescript-eslint/no-explicit-any */

import { last, map, omit, uniqueId } from 'lodash';
import moment from 'moment';
import { useCallback, useRef } from 'react';
import { Key, unstable_serialize, useSWRConfig } from 'swr';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import { useCurrentSession } from '@hooks/currentSession';
import { useInfiniteGlobalCacheUpdate, useLoadInfinite } from './general';
import type { AdGroup } from '@local-types/group';
import type { Advertiser, Values, Page } from '@local-types';

export const GROUP_TYPE = {
  AD_GROUP: 'ad-group',
  STATIC_GROUP: 'static-group',
} as const;

const TYPE_LINK = {
  [GROUP_TYPE.AD_GROUP]: '/lineitems/',
  [GROUP_TYPE.STATIC_GROUP]: '/staticdisplaylineitems/',
} as const;

export const updateGroup = (
  data: Partial<AdGroup>,
  prevData: Page<AdGroup>[] = []
) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.id ? { ...result, ...data } : result
    ),
  }));

export const removeGroup = (id: number, prevData: Page<AdGroup>[] = []) =>
  map(prevData, page => ({
    ...page,
    results: page.results.filter(result => result.id !== id),
  }));

export const addGroup = (data: AdGroup, prevData: Page<AdGroup>[] = []) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.temporaryId ? data : result
    ),
  }));

export const pushGroup = (data: AdGroup, prevData: Page<AdGroup>[] = []) => {
  const lastPage = last(prevData);
  return [
    ...(prevData?.slice(0, -1) ?? []),
    {
      ...lastPage,
      results: [...(lastPage?.results ?? []), data],
    },
  ] as Page<AdGroup>[];
};

export const defaultAdGroupName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Ad Group`;
};

export const findDataPage = (id: number, data: Page<AdGroup>[]) => {
  const index = data.findIndex(page =>
    page.results.find(result => result.id === id)
  );
  return index === -1 ? 0 : index + 1;
};

export const buildPageCacheKey = (
  type: Values<typeof GROUP_TYPE>,
  page: number,
  currentAdvertiser: Advertiser,
  params: Record<string, string | number> = {}
) => {
  const url = TYPE_LINK[type];

  const advertiser = currentAdvertiser.id;

  return {
    url,
    params: {
      advertiser,
      page,
      ...params,
    },
  };
};

type PatchExtraArg = {
  id: number;
  params?: Record<string, string | number>;
} & Partial<AdGroup>;

export const usePatchGroup = (
  type: Values<typeof GROUP_TYPE>,
  options?: SWRMutationConfiguration<
    AdGroup,
    any,
    Key,
    PatchExtraArg,
    Partial<AdGroup>
  >
) => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const updateAdGroup = async (
    url: string,
    {
      arg: { id, params, ...data },
    }: {
      arg: PatchExtraArg;
    }
  ) => patch(`${url}${id}/`, data, params).then(res => res.data);

  return useSWRMutation<AdGroup, any, Key, PatchExtraArg, Partial<AdGroup>>(
    apiIsReady && currentAdvertiser.id ? TYPE_LINK[type] : null,
    updateAdGroup,
    {
      revalidate: false,
      populateCache: true,
      ...options,
    }
  );
};

type DeleteExtraArg = {
  id: number;
};

export const useDeleteGroup = (
  type: Values<typeof GROUP_TYPE>,
  options?: SWRMutationConfiguration<AdGroup, any>
) => {
  const { patchV1, patch, delV1, del, apiIsReady, currentAdvertiser } =
    useCurrentSession();

  const patchMethod = type === GROUP_TYPE.STATIC_GROUP ? patch : patchV1;
  const deleteMethod = type === GROUP_TYPE.STATIC_GROUP ? del : delV1;

  const deleteAdGroup = async (
    url: string,
    {
      arg: { id },
    }: {
      arg: DeleteExtraArg;
    }
  ) => {
    return patchMethod(`${url}${id}/`, { creatives: [] })
      .then(() => deleteMethod(`${url}${id}/`))
      .then(res => res.data);
  };

  return useSWRMutation<AdGroup, any, Key, DeleteExtraArg>(
    apiIsReady && currentAdvertiser.id ? TYPE_LINK[type] : null,
    deleteAdGroup,
    {
      revalidate: false,
      populateCache: false,
      ...options,
    }
  );
};

type CreateExtraArg = {
  temporaryId: number;
} & Omit<AdGroup, 'id'>;

export const useCreateGroup = (
  type: Values<typeof GROUP_TYPE>,
  options?: SWRMutationConfiguration<AdGroup, any, Key, CreateExtraArg>
) => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = async (
    url: string,
    { arg: { temporaryId, ...data } }: { arg: CreateExtraArg }
  ) =>
    await post(url, data).then(res => ({
      ...res.data,
      temporaryId,
    }));

  return useSWRMutation<AdGroup, any, Key, CreateExtraArg>(
    apiIsReady && currentAdvertiser.id ? TYPE_LINK[type] : null,
    fetcher,
    {
      revalidate: false,
      populateCache: true,
      ...options,
    }
  );
};

type DuplicateExtraArg = {
  id: number;
  overload: Partial<AdGroup>;
  item?: AdGroup;
};

export const useDuplicateGroup = (
  type: Values<typeof GROUP_TYPE>,
  options?: SWRMutationConfiguration<AdGroup, any>
) => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = async (
    url: string,
    { arg: { id, overload, item } }: { arg: DuplicateExtraArg }
  ) =>
    await post(`${url}${id}/duplicate/`, overload).then(res => ({
      ...item,
      ...overload,
      ...res.data,
    }));

  return useSWRMutation<AdGroup, any, Key, DuplicateExtraArg>(
    apiIsReady && currentAdvertiser.id ? `${TYPE_LINK[type]}` : null,
    fetcher,
    {
      revalidate: false,
      populateCache: true,
      ...options,
    }
  );
};

export const useGroups = (
  type: Values<typeof GROUP_TYPE>,
  campaignId?: number,
  options?: {
    keyGenerator?: (v: AdGroup) => Key;
    params?: {
      disabled?: boolean;
      ordering?: string;
      v1?: boolean;
      sync?: boolean | number | null;
    };
  }
) => {
  const localAssets = useRef(new Map());
  const { cache } = useSWRConfig();
  const { currentAdvertiser } = useCurrentSession();

  const { params: optionsParams, ...otherOptions } = options ?? {};

  const { trigger: triggerGlobalCacheUpdate } =
    useInfiniteGlobalCacheUpdate();

  const url = TYPE_LINK[type];

  const {
    data = [],
    error,
    isLoading,
    mutate,
    items,
  } = useLoadInfinite<AdGroup>(
    url,
    {
      params: {
        campaign: Number(campaignId),
        disabled: !campaignId,
        cacheProps: {
          campaign: Number(campaignId),
        },
        ...optionsParams,
      },
      ...otherOptions,
    },
    {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  const { trigger: triggerGroupUpdate, isMutating: isPatching } =
    usePatchGroup(type, {
      onSuccess: updatedGroup => {
        triggerGlobalCacheUpdate(
          buildPageCacheKey(
            type,
            findDataPage(updatedGroup.id, data),
            currentAdvertiser,
            {
              campaign: Number(campaignId),
            }
          ),
          updateGroup(updatedGroup, data)
        );
      },
    });

  const { trigger: triggerGroupDuplicate, isMutating: isDuplicating } =
    useDuplicateGroup(type, {
      onSuccess: newGroup => {
        if (data) {
          triggerGlobalCacheUpdate(
            buildPageCacheKey(type, data.length, currentAdvertiser, {
              campaign: Number(campaignId),
            }),
            pushGroup(newGroup, data)
          );
        }
      },
    });

  const { trigger: triggerGroupDelete, isMutating: isDeleting } =
    useDeleteGroup(type);

  const { trigger: triggerGroupCreate, isMutating: isCreating } =
    useCreateGroup(type, {
      onSuccess: newGroup => {
        if (data) {
          triggerGlobalCacheUpdate(
            buildPageCacheKey(type, data.length, currentAdvertiser, {
              campaign: Number(campaignId),
            }),
            addGroup(newGroup, data)
          );
        }
      },
    });

  const update = useCallback(
    (updatedData: Partial<AdGroup>, params: any = {}) =>
      triggerGroupUpdate({ ...updatedData, ...params }),
    [triggerGroupUpdate]
  );

  const resetCache = () => {
    const searchKey = unstable_serialize(
      buildPageCacheKey(type, 1, currentAdvertiser, {
        campaign: Number(campaignId),
      })
    ).replace(/#page:1/g, '#page:\\d+');

    Array.from(cache.keys())
      .filter(key => key.match(searchKey))
      .forEach(key => {
        cache.delete(key);
      });
  };

  const add = useCallback(
    (predefinedData: Partial<AdGroup>) =>
      mutate(prevData => {
        if (!prevData) return prevData;

        const lastPage = { ...last(prevData) };

        const newResult = {
          isDisplay: type === GROUP_TYPE.STATIC_GROUP,
          id: (lastPage?.results?.length ?? 0) + 1,
          temporary: true,
          creatives: [],
          ...predefinedData,
        };

        return [
          ...(prevData?.slice(0, -1) ?? []),
          {
            ...lastPage,
            results: [...(lastPage?.results ?? []), newResult],
          },
        ] as Page<AdGroup>[];
      }, false),
    [mutate]
  );

  const remove = useCallback(
    async (id: number) => {
      if (!data) return;

      const pageWithItem = data.find(page =>
        page.results.find(result => result.id === id)
      );
      const { temporary = false } =
        pageWithItem?.results.find(result => result.id === id) || {};

      if (temporary) {
        mutate(async prevData => {
          if (!prevData) return prevData;
          return removeGroup(id, prevData);
        }, false);

        return;
      }

      await triggerGroupDelete({
        id,
      });

      triggerGlobalCacheUpdate(
        buildPageCacheKey(type, findDataPage(id, data), currentAdvertiser, {
          campaign: Number(campaignId),
        }),
        removeGroup(id, data)
      );
    },
    [mutate, triggerGlobalCacheUpdate]
  );

  const create = useCallback(
    (data: Omit<AdGroup, 'id'>) =>
      triggerGroupCreate({
        ...(omit(data, ['id']) as AdGroup),
        temporaryId: data.id,
      }),
    [triggerGroupCreate, remove]
  );

  const duplicate = useCallback(
    (id: number, options?: { remote?: boolean; data: Partial<AdGroup> }) => {
      const pageWithItem = data.find(page =>
        page.results.find(result => result.id === id)
      );
      const itemToDuplicate = pageWithItem?.results.find(
        result => result.id === id
      );

      if (options?.remote) {
        triggerGroupDuplicate({
          id,
          item: itemToDuplicate,
          overload: options?.data,
        });

        return;
      }

      mutate(prevData => {
        if (!prevData || !itemToDuplicate) return prevData;

        const duplicatedItem = {
          ...itemToDuplicate,
          name: itemToDuplicate.name
            ? `${itemToDuplicate.name} Duplicate`
            : defaultAdGroupName(),
          id: Number(uniqueId()),
          temporary: true,
          ...(options?.data ?? {}),
        };

        return pushGroup(duplicatedItem, prevData);
      }, false);
    },
    [mutate, data]
  );

  return {
    items,
    data,
    error,
    isLoading:
      isLoading || isPatching || isCreating || isDeleting || isDuplicating,
    add,
    create,
    remove,
    update,
    duplicate,
    resetCache,
    mutate,
    localAssets: localAssets.current,
  };
};
