import { values } from 'lodash';
import {
  customInventoryTabValues,
  inventoryCategoryName,
} from '../constants';

export const customInventoryTypes = {
  [customInventoryTabValues.appName]: 'app_name',
  [customInventoryTabValues.appBundleList]: 'app_bundle_list',
  [customInventoryTabValues.dealId]: 'deal_id',
};

export const tableTypes = {
  AUDIENCE: 'audience',
  INVENTORY: 'inventory',
  CUSTOM_INVENTORY: 'customInventory',
};

export const customInventoryAccessibleTypes = values(inventoryCategoryName);

export const audienceExtensionTooltipTitle =
  'Audience Extension enhances matching by leveraging a device graph to bid \
  on additional device IDs belonging to the users in your audience. Note: \
  This does not create or utilize a lookalike audience.';
