import React from 'react';
import { INestedListProps } from './types';
import { List } from './styled';
import NestedListItem from './NestedListItem';

const NestedList: React.FC<INestedListProps> = ({ items, selectedPath, onSelectItem }) => (
  <List disablePadding>
    {items.map((item, index) => (
      <NestedListItem
        key={index}
        item={item}
        selectedPath={selectedPath}
        onSelectItem={onSelectItem}
      />
    ))}
  </List>
);

export default NestedList;
