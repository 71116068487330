/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWizardContext } from './useWizardContext';

export const useWizardSection = ({ key }: { key: string }) => {
  const { navigation, state, updateState } = useWizardContext();

  const active = navigation.activeSection === key;

  const { updateEntityState, activePane } = navigation;

  return {
    ...navigation,
    updateEntityState: (state: any) => updateEntityState(key, state),
    isActivePane: (paneKey: string) => activePane === paneKey,
    active,
    state,
    updateState,
  };
};
