import { useContext } from 'react';
import BWSyncContext from '../../providers/BWSyncContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BWSyncContextType = any;

export const useBWSync = (): BWSyncContextType => {
  const bwSyncContext = useContext(BWSyncContext);

  if (bwSyncContext === undefined) {
    throw new Error('useBWSync BWSyncContext was used outside of its Provider');
  }

  return bwSyncContext;
};
