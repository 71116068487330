import { useAPI } from './api';

export const useCheckCity = () => {
  const url = `/check-city/`;
  const { useGet } = useAPI();

  const checkCity = async (city: string | undefined) => {
    try {
      const response = await useGet(`${url}${city}`);
      return typeof response === 'object' ? response.data : response;
    } catch (error) {
      console.error('Error in checkCity', error);
      return false;
    }
  };

  return { checkCity };
};
