import { useContext, useEffect } from 'react';
import UserContext from '../../providers/UserContext';

import AdvertiserContext from '../AdvertiserContext';
import intercomWrapper from '../../helpers/intercomWrapper';
import { useDomain } from './domain';
import { Advertiser } from '@local-types';

interface User {
  email?: string;
  name?: string;
  external_user_hash?: string;
  user_hash?: string;
}

interface IntercomUser {
  email?: string;
  name?: string;
  userHash?: string;
  customAttributes: {
    current_page_key: string;
    [key: string]: string | boolean | undefined;
  };
}

interface UserContextType {
  user: User | null;
  getUser: () => Promise<void>;
}

export const useIntercom = ({ advertiser }: { advertiser: Advertiser }) => {
  const userContext = useContext(UserContext) as UserContextType | null;  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { boot, update } = intercomWrapper.useIntercom();
  const isPeacock = domain.peacock;

  useEffect(() => {
    if (!userContext) return;

    const urlParams = new URLSearchParams(window.location.search);
    const intercomEnvValue = urlParams.get('intercom-env');
    const isStaging = intercomEnvValue === 'staging';

    // Set the intercom user
    if (userContext?.user?.email) {
      const userHash = isPeacock
        ? userContext.user.external_user_hash
        : isStaging
        ? undefined
        : userContext.user.user_hash;

      const intercomUser: IntercomUser = {
        email: userContext.user.email,
        name: userContext.user.name,
        userHash,
        customAttributes: {
          current_page_key: location.pathname,
        },
      };

      boot(intercomUser);
    }
  }, [userContext, adContext, isPeacock]);

  useEffect(() => {
    // Set the current advertiser
    if (advertiser && advertiser.id) {
      update({
        customAttributes: {
          current_advertiser_id: advertiser.id,
          current_advertiser_name: advertiser.name,
        },
      });
    }
  }, [advertiser]);

  useEffect(() => {
    // Set the current advertiser
    if (advertiser && advertiser.id) {
      update({
        customAttributes: {
          current_advertiser_id: advertiser.id,
          current_advertiser_name: advertiser.name,
          current_advertiser_role: advertiser.adv_role,
          feature_tvsciq: advertiser.tvsciq,
        },
      });
    }
  }, [update, advertiser]);
};
