import { useState, useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';

interface ErrorMessages {
  cpm: string;
  detail: string;
  error: string;
  name: string;
  targeting: string;
  400: string;
  401: string;
  403: string;
  500: string;
}

const errors: ErrorMessages = {
  cpm: 'A valid number is required for Max CPM.',
  detail: 'Refreshing token... Please try again.',
  error: 'There was an error with your request. Please contact support. Your error ID is {CID}.',
  name: 'You must give your ad group a name.',
  targeting: 'There is a conflict in your targeting selection. Please contact support.',
  400: 'There was an error with your request. Please contact support.',
  401: 'The token has expired. Please try again.',
  403: 'Authentication credentials were not provided.',
  500: 'There was an error with your request. Please contact support.',
};

export const useErrors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsError(false);
    };
  }, []);

  const handleErrorMsg = async (err: string | string[], variant: VariantType) => {
    const errorMsgs = Array.isArray(err) ? err : [err];
    errorMsgs.forEach(errorMsg => enqueueSnackbar(errorMsg, {
      autoHideDuration: 10000,
      preventDuplicate: true,
      variant,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseError = (error: any, variant: VariantType, refreshToken?: () => void) => {
    if (error && error.response && error.response.status) {
      const { data, status } = error.response;
      const dataErrors = Object.keys(data);
      if (typeof data === 'string') {
        const message = data.length > 120 ? `${data.substring(0, 120)}...` : data;
        handleErrorMsg(message, variant);
        return;
      }
      if (status === 403 && data.detail === "You do not have permission to perform this action.") {
        enqueueSnackbar("You don't have permissions to perform this change, please check with your Org Admin", {
          autoHideDuration: 5000,
          preventDuplicate: true,
          variant: 'warning',
        });
        return;
      }
      if (dataErrors && dataErrors.length > 0) {
        dataErrors.forEach(d => {
          if (errors[d as keyof ErrorMessages]) {
            if (d === 'detail' && status === 403) {
              handleErrorMsg(errors[d as keyof ErrorMessages], 'warning');
              if (refreshToken) {
                refreshToken();
              }
            } else if (d === 'error') {
              const errorMessage = errors[d as keyof ErrorMessages].replace(/{CID}/g, data[d]);
              handleErrorMsg(errorMessage, variant);
            } else if (d !== 'targeting' && data[d] !== errors[d as keyof ErrorMessages]) {
              handleErrorMsg(data[d], variant);
            } else {
              handleErrorMsg(errors[d as keyof ErrorMessages], variant);
            }
          } else {
            handleErrorMsg(data[d], 'error');
          }
        });
      } else {
        if (errors[status as keyof ErrorMessages]) {
          handleErrorMsg(
            errors[status as keyof ErrorMessages],
            status === 403 ? 'warning' : variant
          );
        }
      }
      if (Object.values(data).length > 0) {
        Object.values(data).forEach(e => {
          handleErrorMsg(e as string, 'error');
        });
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any, variant: VariantType, refreshToken?: () => void) => {
    setIsError(true);
    parseError(error, variant, refreshToken);
  };

  return {
    isError,
    handleError,
  };
};
