import React, { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import ListFilterForm from "@components/forms/ListFilterForm";
import { IFormValues, IItem } from '@components/forms/ListFilterForm/types';
import { useGetAdGroups } from '@apis/adGroups';

interface AdGroupIdFilterProps {
  onSubmit: (params: Record<string, string>) => void;
  data: Record<string, string>;
}

const AdGroupIdFilter: React.FC<AdGroupIdFilterProps> = ({ data, onSubmit }) => {
  const [searchValue, setSearchValue] = useState('');

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    
    if (searchValue) {
      params.id = searchValue;
    }
    
    if (data.campaignIds) {
      params.campaign_ids = data.campaignIds;
    }
    
    return params;
  }, [searchValue, data.campaignIds]);

  const { data: adGroups, isLoading } = useGetAdGroups(queryParams);

  const formData = useMemo(() => {
    const selectedIds = data.adGroupIds ? data.adGroupIds?.split(',').map(Number) : [];
    
    return {
      items: adGroups?.results?.map((adGroup: IItem) => ({
        id: adGroup.id,
        name: adGroup.id.toString(),
        checked: selectedIds.includes(adGroup.id),
      })),
      selectedIds,
      isLoading,
    };
  }, [adGroups, data.adGroupIds, isLoading]);

  const handleSearch = useCallback(
    debounce((value: string | undefined) => {
      setSearchValue(value || '');
    }, 300),
    []
  );

  const handleSubmit = (formData: IFormValues) => {
    onSubmit({
      adGroupIds: formData.selectedIds.join(',')
    });
  };

  return (
    <ListFilterForm
      fieldName="Ad Group ID"
      data={formData}
      onSearch={handleSearch}
      onSubmit={handleSubmit}
    />
  );
};

export default AdGroupIdFilter;
