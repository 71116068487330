import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import CheckIcon from '../../images/icon-check--blue.png';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import MiddleEllipsis from '@helpers/ellipsis';
import moment from 'moment-timezone';
import QCStatusCell from '@components/Creatives/QCStatusComponents';
import { Creative } from '@local-types';
import { classes } from './styles';
import { useCreativeLibrary } from '@components/CreativeLibrary/CreativeLibraryContext';
import { usePermissions } from '@components/hooks';
import { Scopes } from '@constants/scopes';

export const CreativeLibraryTableRow = ({
  creative,
}: {
  creative: Creative;
}) => {
  const {
    assigned,
    selected,
    setSelected,
    handlePreview,
    handleToggleAssign,
  } = useCreativeLibrary();
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const isAssigned = assigned.findIndex(a => a.url === creative.url) > -1;
  const isSelected = selected.includes(creative.id);
  const labelId = `creative-${creative.id}`;

  const renderMegaBytes = (bytes: string | number) => {
    return `${((typeof bytes === 'string' ? parseInt(bytes, 10) : bytes) / 1000000).toFixed(2)} MB`;
  };

  const handleSelected = (id: number) => {
    if (selected.includes(id)) {
      setSelected(prev => prev.filter(p => p !== id));
    } else {
      setSelected(prev => [...prev, id]);
    }
  };

  const { qc } = creative;

  return (
    <Fragment key={creative.id}>
      <TableRow className={clsx(isAssigned ? classes.assignedRow : '')}>
        <TableCell padding="none">
          {!isAssigned ? (
            <Checkbox
              checked={isSelected}
              disabled={isAssigned}
              inputProps={{ 'aria-labelledby': labelId }}
              onChange={() => handleSelected(creative.id)}
              size="small"
            />
          ) : (
            <Box
              display="flex"
              width={18}
              alignItems="center"
              justifyContent="center"
              my={0}
              mx="auto"
            >
              <img src={CheckIcon} className={classes.check} />
            </Box>
          )}
        </TableCell>

        <TableCell>{creative.id}</TableCell>

        <TableCell component="th" id={labelId} scope="row" align="left">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton
                className={classes.displayButton}
                onClick={() => handlePreview(creative)}
                size="small"
              >
                <PlayCircleFilledIcon color="secondary" />
              </IconButton>
            </Grid>

            <Grid className={classes.name} item xs>
              <Tooltip title={creative.name}>
                <div>
                  <MiddleEllipsis>
                    <span>{creative.name}</span>
                  </MiddleEllipsis>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>

        <TableCell
          className={classes.adGroupCell}
          component="th"
          scope="row"
          align="left"
          data-testid={
            creative.adgroup_count &&
            creative.adgroup_count > 0 &&
            creative.adgroup_count < 25
              ? 'assignable-creative'
              : 'not-assignable-creative'
          }
        >
          {creative.adgroup_count && creative.adgroup_count > 0 ? (
            `${creative.adgroup_count} Ad group${
              creative.adgroup_count > 1 ? 's' : ''
            }`
          ) : (
            <span className={classes.na} data-testid="not-assigned-creative">
              Not Assigned
            </span>
          )}
        </TableCell>

        <TableCell align="left">
          {creative.resolution ? creative.resolution : 'n/a'}
        </TableCell>

        <TableCell className={classes.fileSize} align="left">
          {creative.file_size_bytes
            ? renderMegaBytes(creative.file_size_bytes)
            : 'n/a'}
        </TableCell>

        <TableCell align="left">
          {creative.media_upload_date
            ? moment(creative.media_upload_date).format('l')
            : 'n/a'}
        </TableCell>

        {showQCFunctionality && (
          <TableCell align="center">
            <QCStatusCell creative={creative} qc={qc} />
          </TableCell>
        )}

        <TableCell align="left" className={classes.action}>
          <Button
            className={isAssigned ? classes.unassigned : classes.assigned}
            disableRipple
            onClick={() => handleToggleAssign(creative)}
          >
            {isAssigned ? 'UNASSIGN' : 'ASSIGN'}
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
