import axios, { isCancel } from 'axios';
import LogRocket from 'logrocket';

// API CONSTANTS
const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

if (process.env.USE_LOG_ROCKET === 'true') {
  LogRocket.getSessionURL(a => (BASE_HEADERS['X-LogRocket-URL'] = a));
}

const apiInstance = axios.create({
  baseURL: `${process.env.API_URL}/v1/`,
  headers: {
    ...BASE_HEADERS,
  },
});

apiInstance.interceptors.request.use(config => {
  const { baseURL, url, params } = config;
  const relativeUrl = url.startsWith('/') ? url.substring(1) : url;
  const urlObject = new URL(relativeUrl, baseURL);

  if (params) {
    Object.keys(params).forEach(key => urlObject.searchParams.set(key, params[key]));
  }

  if (!urlObject.pathname.endsWith('/')) {
    urlObject.pathname += '/';
  }

  return {
    ...config,
    url: urlObject.toString(),
    params: undefined,
  };
}, error => {
  return Promise.reject(error);
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !isCancel(error)
      && error.config
      && !error.config.__isRetryRequest
      && error.response
      && error.response.status === 403
      && error.response.headers['X-TVS-OrganizationContext']
    ) {
      const newConfig = {
        ...error.config,
        __isRetryRequest: true,
        headers: {
          ...error.config.headers,
          'X-TVS-OrganizationContext': undefined,
        },
      };

      return apiInstance(newConfig);
    }

    return Promise.reject(error);
  }
);

export default apiInstance;
