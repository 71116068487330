import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Stripe } from '@stripe/stripe-js/dist/stripe-js/stripe.d';
import { useAdvertiserStripeSetupIntent } from './apis/advertisers';

interface StripeOptions {
  clientSecret: string | null;
}

interface UseStripeReturn {
  stripe: Promise<Stripe | null> | null;
  options: StripeOptions | null;
}

export const useStripe = (options: {
  revalidateIfStale?: boolean;
  revalidateOnFocus?: boolean;
  revalidateOnReconnect?: boolean;
  suspense?: boolean;
} = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  suspense: true
}): UseStripeReturn => {
  const { data: advertiserStripeSetupIntent } = useAdvertiserStripeSetupIntent(options);

  return useMemo<UseStripeReturn>(
    () => {
      if (advertiserStripeSetupIntent) {
        const {
          publishable_key: publishableKey,
          client_secret: clientSecret,
        } = advertiserStripeSetupIntent;

        return {
          stripe: loadStripe(publishableKey),
          options: { clientSecret },
        };
      }

      return {
        stripe: null,
        options: null,
      };
    },
    [advertiserStripeSetupIntent],
  );
};
