import React from 'react';
import PropTypes from 'prop-types';
import { advancedModals, sections } from './constants';
import { General } from './General';
import { Audience } from './Audience';
import { Geography } from './Geography/Geography';
import { Inventory } from './Inventory';
import { Creative } from './Creative';
import { BidStrategy } from './BidStrategy/BidStrategy';
import { sectionInfoType } from '../../../../components/SectionInfo/types';
import { useSection } from './hooks/useSection';

export const Section = ({
  bundles,
  name,
  info,
  adGroup,
  campaign,
  isDisplay,
  onAdvanced,
}) => {
  const { setZipCodesToEdit, inventory, audience, customInventory } =
    useSection();

  switch (name) {
    case advancedModals.general:
      return (
        <General
          adGroup={adGroup}
          campaign={campaign}
          info={info}
          isDisplay={isDisplay}
        />
      );
    case advancedModals.audience:
      return (
        <Audience
          campaignName={campaign?.name}
          info={info}
          isDisplay={isDisplay}
          onAdvanced={() =>
            onAdvanced(advancedModals.audience, {
              audience,
            })
          }
        />
      );
    case sections.geography:
      return (
        <Geography
          info={info}
          isDisplay={isDisplay}
          onAdvanced={data => {
            setZipCodesToEdit(data);
            onAdvanced(advancedModals.geography, data);
          }}
        />
      );
    case sections.inventory:
      return (
        <Inventory
          onSelect={option => {
            if (option.value === 'bundles') {
              onAdvanced(advancedModals.customInventory, {
                customInventory,
              });
            }
          }}
          bundles={bundles}
          info={info}
          isDisplay={isDisplay}
          onAdvancedCustomInventory={() => {
            onAdvanced(advancedModals.customInventory, {
              customInventory,
            });
          }}
          onAdvanced={() =>
            onAdvanced(advancedModals.inventory, {
              inventory,
            })
          }
        />
      );
    case sections.creative:
      return (
        <Creative
          info={info}
          isDisplay={isDisplay}
          onAdvanced={() => onAdvanced(advancedModals.creative)}
        />
      );
    case sections.bidStrategy:
      return (
        <BidStrategy
          info={info}
          isDisplay={isDisplay}
          onAdvanced={data => onAdvanced(advancedModals.bidStrategy, data)}
        />
      );
    default:
      return null;
  }
};

Section.propTypes = {
  adGroup: PropTypes.object,
  name: PropTypes.oneOf(Object.keys(sections)).isRequired,
  onAdvanced: PropTypes.func,
  campaign: PropTypes.object,
  info: PropTypes.arrayOf(sectionInfoType),
  isDisplay: PropTypes.bool,
  sectionsData: PropTypes.object,
  bundles: PropTypes.arrayOf(PropTypes.object),
};
