import React, { useState } from 'react';
import { Menu, Grid, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { SubwayMenuProps } from './types';
import { StyledMenuItem } from './styles';

export const SubwayMenu = ({
  options,
  disabled,
  ...props
}: SubwayMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      justifyContent="flex-end"
      alignItems="center"
      {...props}
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        flexShrink: 0,
        ...props.sx,
      }}
    >
      {/** TODO: Remove ignore on the next version of mui. */}
      {/** @ts-expect-error mistype. */}
      <IconButton
        id="basic-button"
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        data-testid="ad-group-menu-dots"
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => {
              option.onClick?.();
              handleClose();
            }}
            disabled={option.disabled}
            data-testid={option.label.toLowerCase().replace(/\s+/g, '-')}
            type={option.type}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </Grid>
  );
};
