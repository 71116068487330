import { EnqueueSnackbar } from 'notistack';

import { Dimension } from '@components/hooks/dimensions';
import { MediaTypes } from './constants';

export const getVideoDuration = (file: File) =>
  new Promise(res => {
    const vid = document.createElement('video');
    const fileURL = URL.createObjectURL(file);
    vid.src = fileURL;
    vid.ondurationchange = function () {
      res((this as any).duration); // eslint-disable-line @typescript-eslint/no-explicit-any
    };
  });

export const fetchDimensions = (
  image: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;

    const reader = new FileReader();

    reader.onloadend = function (ended: ProgressEvent<FileReader>) {
      img.src = ended.target?.result as string;
    };

    reader.readAsDataURL(image);
  });
};

export const handlers = {
  [MediaTypes.image]: async (
    files: File[],
    dimensions: Dimension[],
    enqueueSnackbar: EnqueueSnackbar
  ) => {
    const filesDimensions = await Promise.all(
      files.map(file => fetchDimensions(file))
    );
    const filesToUpload: File[] = [];
    files.forEach((file, i) => {
      const { height, width } = filesDimensions[i];

      const validDimension = dimensions.some(
        ({ creative_height, creative_width }) =>
          width === creative_width && height === creative_height
      );

      if (validDimension) {
        filesToUpload.push(file);
      } else {
        const message = `${file.name} has invalid width x height combination:\
          ${width}x${height}. If you are uploading other files, do not leave \
          this page.`;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
    return filesToUpload;
  },
  [MediaTypes.video]: async (files: File[]) => Promise.resolve(files),
};
