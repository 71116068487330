import React from 'react';
import numeral from 'numeral';
import { BID_STRATEGIES } from './constants';
import { MaxCpm } from './MaxCpm';
import { EventPicker } from './EventPicker';
import {
  GetLockedInfoType,
  GetLockedStatusType,
  GetRecommendedBidRangeType,
  GetUnlockedContentType,
} from './types';

const getAdjustedBidRange = (range: number[]) =>
  range.map(n => n * 1.18 + 0.4);

export const getRecommendedBidRange = ({
  bundles,
  inventoryOption,
  peacock,
  isInternal,
}: GetRecommendedBidRangeType) => {
  switch (inventoryOption) {
    // TODO: When DS returns a suggested bid range in the future, we should
    // display it in the 'performanceOptimized' case. In the meantime,
    // 'performanceOptimized' gets the default behavior that also applies to
    // 'premium-reach'.

    // case 'performanceOptimized': {
    //   return;
    // }
    case 'bundles': {
      return bundles
        .map(
          ({ adjusted_cpm }: { adjusted_cpm: number | null }) =>
            adjusted_cpm ?? 0
        )
        .filter((cpm: number) => !!cpm)
        .sort((a: number, b: number) => a - b);
    }
    case 'premium-reach':
    default: {
      const range = peacock ? (isInternal ? [17, 21] : [18, 21]) : [10, 20];
      return getAdjustedBidRange(range);
    }
  }
};

export const getRangeText = (range: number[]) => {
  const min = range[0];
  const max = range[range.length - 1];

  if (min === max) {
    return `${numeral(Math.ceil(min)).format('$0,0')}`;
  }

  return `${numeral(min).format('$0,0')} - ${numeral(Math.ceil(max)).format(
    '0,0'
  )}`;
};

export const getUnlockedContent = ({
  option,
  control,
  bidStrategyEventSet,
  rangeText,
  handleCPMSwitchChange,
  isMaxCPMBidEnabled,
}: GetUnlockedContentType) => {
  switch (option.display_name) {
    case BID_STRATEGIES.MAX_IMPRESSIONS:
    case BID_STRATEGIES.AUTO_BID:
    case BID_STRATEGIES.MAX_ROAS:
      return (
        <MaxCpm
          control={control}
          handleCPMSwitchChange={handleCPMSwitchChange}
          isMaxCPMBidEnabled={isMaxCPMBidEnabled}
          rangeText={rangeText}
        />
      );
    case BID_STRATEGIES.MANUAL_BID:
      return (
        <MaxCpm
          required
          control={control}
          handleCPMSwitchChange={handleCPMSwitchChange}
          isMaxCPMBidEnabled={isMaxCPMBidEnabled}
          rangeText={rangeText}
        />
      );
    case BID_STRATEGIES.FIXED_CPM:
      return (
        <MaxCpm
          required
          control={control}
          label="Bid"
          handleCPMSwitchChange={handleCPMSwitchChange}
          isMaxCPMBidEnabled={isMaxCPMBidEnabled}
          rangeText={rangeText}
        />
      );
    case BID_STRATEGIES.MAX_OUTCOMES:
      return (
        <>
          <EventPicker
            control={control}
            bidStrategyEventSet={bidStrategyEventSet}
          />
          <MaxCpm
            control={control}
            handleCPMSwitchChange={handleCPMSwitchChange}
            isMaxCPMBidEnabled={isMaxCPMBidEnabled}
            rangeText={rangeText}
          />
        </>
      );
    default:
      return null;
  }
};

export const getLockedStatus = ({
  option,
  bidStrategyEventSet,
}: GetLockedStatusType) => {
  switch (option?.display_name) {
    case BID_STRATEGIES.MAX_OUTCOMES:
      return !bidStrategyEventSet || bidStrategyEventSet.length === 0;
    default:
      return false;
  }
};

export const getLockedInfo = ({ option, lockedInfo }: GetLockedInfoType) => {
  switch (option.display_name) {
    case BID_STRATEGIES.MAX_OUTCOMES:
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};
