import React from 'react';
import { useBWOverride } from './useBWOverride';
import { InfoText, WarningHeader } from './styles';
import { Button, Stack } from '@mui/material';
import type { LineItem } from '@local-types';

export const renderBWOverrideTitle = ({ adGroup }: { adGroup: LineItem }) => {
  const { handleBWOverride } = useBWOverride(adGroup);

  return (
    <Stack>
      <WarningHeader>Ad server out of sync</WarningHeader>
      <InfoText>
        Spend:{' '}
        {adGroup?.stats_cache?.total_spend != null
          ? `$${adGroup.stats_cache.total_spend}`
          : '-'}
      </InfoText>
      <InfoText sx={{ marginY: '6px' }}>
        To fix:{' '}
        <span style={{ fontWeight: 500 }}>
          Click the button below to update Beeswax{' '}
        </span>
      </InfoText>
      <Button
        variant="outlined"
        onClick={handleBWOverride}
        fullWidth
        disableRipple
      >
        Update Ad Server
      </Button>
    </Stack>
  );
};
