import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Creative } from '@local-types';
import { useGetAllCreatives } from '@components/hooks/apis/creatives';

export interface CreativeLibraryContextType {
  creatives: Creative[];
  assigned: Creative[];
  count: number;
  isLoading: boolean;
  isSelectAll: boolean;
  search: string;
  setSearch: (search: string) => void;
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
  handleAssignSelected: () => void;
  handleToggleAssign: (creative: Creative) => void;
  handleSelectAll: (event: MouseEvent) => void;
  handlePreview: (creative: Creative) => void;
}

export const CreativeLibraryContext =
  createContext<CreativeLibraryContextType>({
    creatives: [],
    count: 0,
    assigned: [],
    isLoading: false,
    isSelectAll: false,
    search: '',
    setSearch: () => {},
    selected: [],
    setSelected: () => {},
    handleAssignSelected: () => {},
    handleToggleAssign: () => {},
    handleSelectAll: () => {},
    handlePreview: () => {},
  });

export interface CreativeLibraryContextProviderProps
  extends Partial<CreativeLibraryContextType> {
  handleBatchAssign: (creatives: Creative[]) => void;
  handleUnassign: (creatives: Creative) => void;
  children: React.ReactNode;
  setCurrentPreview: (creative: Creative) => void;
  setIsPreview: (isPreview: true) => void;
}

export const CreativeLibraryContextProvider = ({
  children,
  handleUnassign,
  handleBatchAssign,
  assigned,
  setCurrentPreview,
  setIsPreview,
  ...props
}: CreativeLibraryContextProviderProps) => {
  const { items: creatives, isLoading } = useGetAllCreatives({ v1: true });

  const [selected, setSelected] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [search, setSearch] = useState<string>('');

  const handleSelectAll = (event: MouseEvent) => {
    const { checked } = event.target as HTMLInputElement;

    setIsSelectAll(checked);

    if (checked) {
      setSelected(() =>
        creatives
          .filter(c => !assigned?.some(a => a.url === c.url))
          .map(c => c.id),
      );
    } else {
      setSelected([]);
    }
  };

  const handlePreview = (creative: Creative) => {
    setCurrentPreview(creative);
    setIsPreview(true);
  };

  const handleAssignSelected = () => {
    if (selected.length > 0) {
      handleBatchAssign(
        selected
          .map(s => creatives.find(c => c.id === s))
          .filter(Boolean) as Creative[],
      );

      setSelected([]);
      setIsSelectAll(false);
    }
  };

  const handleToggleAssign = (video: Creative) => {
    const hasCreative = assigned?.some(a => a.url === video.url);

    if (hasCreative) {
      handleUnassign(video);
    } else {
      handleBatchAssign([video]);
      setSelected(prev => prev.filter(p => p === video.id));
    }
  };

  const filteredCreatives = useMemo(() => {
    return creatives.filter(
      c => !c.archived && c.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [creatives, search]);

  return (
    <CreativeLibraryContext.Provider
      value={
        {
          ...props,
          count: filteredCreatives?.length,
          creatives: filteredCreatives,
          assigned,
          search,
          isSelectAll,
          isLoading,
          setSearch,
          selected,
          setSelected,
          handleAssignSelected,
          handleToggleAssign,
          handleSelectAll,
          handlePreview,
        } as CreativeLibraryContextType
      }
    >
      {children}
    </CreativeLibraryContext.Provider>
  );
};

export const useCreativeLibrary = () => {
  return useContext(CreativeLibraryContext);
};
