import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import ManageTracking from '../ManageTracking';
import WebsiteManageTracking from '../WebsiteManageTracking';
import MobileManageTracking from '../MobileManageTracking';
import AddMobileAppTrackingEvent from '../AddMobileAppTrackingEvent';
import LoadingSpinner from '../ui/LoadingSpinner';
import {
  AdjustIcon,
  AppsFlyerIcon,
  BranchIcon,
  KochavaIcon,
  NoneIcon,
  SingularIcon
} from '../icons/trackingEvents/mobile';
import { DesktopIcon, MobileIcon } from '../icons/trackingEvents';
import { EmbedLookerChart } from '../EmbedLookerChart';
import ModalContext from '../../providers/ModalContext';
import { useIntercom } from '../../helpers/intercomWrapper';
import TextCopyField from '../TextCopyField';

const PREFIX = 'TrackingPage';

const classes = {
  container: `${PREFIX}-container`,
  campaignContainerSpacing: `${PREFIX}-campaignContainerSpacing`,
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  dashboard: `${PREFIX}-dashboard`,
  palette: `${PREFIX}-palette`,
  trackingId: `${PREFIX}-trackingId`
};

const StyledAppHeader = styled(AppHeader)(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none'
  },

  [`& .${classes.campaignContainerSpacing}`]: {
    padding: theme.spacing(2),
    maxWidth: 'none'
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.title}`]: {
    marginTop: 0,
    flexGrow: 4,
  },

  [`& .${classes.dashboard}`]: {
    '& > iframe': {
      width: '100%',
      height: 1100,
    }
  },

  [`& .${classes.palette}`]: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  [`& .${classes.trackingId}`]: {
    flexGrow: 1,
  }
}));

const PaperStyled = styled(Paper)(() => ({
  overflow: 'hidden',
}));

const TrackingPage = props => {
  const { showArticle } = useIntercom();
  const adContext = useContext(AdvertiserContext);
  const { setModal } = useContext(ModalContext);

  const handleOpenWebsiteTracking = () => {
    setModal({
      isOpen: true,
      data: {
        exposureLid: adContext.deductive_exposure_lid,
        outcomeLid: adContext.deductive_outcome_lid,
      },
      component: WebsiteManageTracking,
    });
  }

  const handleOpenIntegration = ({
    integrationName = null,
    articleId = null,
  } = {}) => () => {
    const onIntegrationGuideClick = articleId ? () => showArticle(articleId) : null;

    setModal({
      isOpen: true,
      data: {
        outcomeLid: adContext.deductive_outcome_lid,
        integrationName,
        onIntegrationGuideClick,
      },
      component: AddMobileAppTrackingEvent,
    });
  }

  const handleOpenMobileTracking = () => {
    setModal({
      isOpen: true,
      data: {
        name: adContext.name,
        options: [
          {
            name: "Adjust",
            icon: AdjustIcon,
            onClick: handleOpenIntegration({
              integrationName: 'Adjust',
              articleId: 8265177,
            }),
          },
          {
            name: "AppsFlyer",
            icon: AppsFlyerIcon,
            onClick: handleOpenIntegration({
              integrationName: 'AppsFlyer',
              articleId: 8264928,
            }),
          },
          {
            name: "Branch",
            icon: BranchIcon,
            onClick: handleOpenIntegration({
              integrationName: 'Branch',
              articleId: 8891969,
            }),
          },
          {
            name: "Kochava",
            icon: KochavaIcon,
            onClick: handleOpenIntegration({
              integrationName: 'Kochava',
              articleId: 8733755,
            }),
          },
          {
            name: "Singular",
            icon: SingularIcon,
            onClick: handleOpenIntegration({
              integrationName: 'Singular',
              articleId: 8207972,
            }),
          },
          {
            name: "None of these",
            icon: NoneIcon,
            onClick: handleOpenIntegration(),
          },
        ],
      },
      component: MobileManageTracking,
    });
  }

  const handleOpenTracking = () => {
    setModal({
      isOpen: true,
      data: {
        options: [
          {
            name: "On a website",
            icon: DesktopIcon,
            onClick: handleOpenWebsiteTracking,
          },
          {
            name: "In a mobile app",
            icon: MobileIcon,
            onClick: handleOpenMobileTracking,
          },
        ],
      },
      component: ManageTracking,
    });
  };

  return (
    <StyledAppHeader history={props.history}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              <Toolbar disableGutters>
                <Typography className={classes.title} variant="h2" component="div" data-testid="active-tracking-header">
                  Tracking
                </Typography>
                {adContext.deductive_outcome_lid &&
                  <TextCopyField
                    label='Your tvScientific Tracking ID'
                    text={adContext.deductive_outcome_lid}
                    className={classes.trackingId}
                  />
                }
              </Toolbar>

              <Container className={classes.container}>
                <Grid container>
                  <Grid container justifyContent="flex-end" item xs={12}>
                    <Button
                      color="secondary"
                      onClick={() => handleOpenTracking()}
                      startIcon={<AddIcon size="small" />}
                    >
                      Create Tracking Code
                    </Button>
                  </Grid>
                </Grid>
              </Container>

              <PaperStyled>
                <EmbedLookerChart dashboard="PIXEL_TRACKING" />
              </PaperStyled>
            </Paper>
          </Grid>
        </Grid>

        {adContext?.deductive_exposure_lid === undefined && <LoadingSpinner />}
      </Container>
    </StyledAppHeader>
  );
};

TrackingPage.propTypes = {
  history: PropTypes.object,
};

export default TrackingPage;
