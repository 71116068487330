import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { StyledAppHeader, classes } from './styles';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { AdGroupCreativeTableV2 } from './AdGroupCreativeTableV2';
import { AdGroupCreativeTableContextProvider } from './AdGroupCreativeTableContext';
import { AdGroupCreativeTable } from './AdGroupCreativeTable';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';

const CreativeIndexPage = () => {
  const { adGroupId, campaignId } = useParams<{
    adGroupId: string;
    campaignId: string;
  }>();
  const { v2CreativesTable } = useLDFlags();

  return (
    <StyledAppHeader>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>{' '}
          &gt;{' '}
          <strong>
            <Link
              className={classes.link}
              to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}
            >
              Ad Groups
            </Link>
          </strong>{' '}
          &gt; Creative
        </Typography>
      </Box>

      {v2CreativesTable ? (
        <ErrorBoundary fallback={<div>Error loading items</div>}>
          <Suspense fallback={<h2>Loading...</h2>}>
            <AdGroupCreativeTableContextProvider
              adGroupId={Number(adGroupId)}
              campaignId={Number(campaignId)}
            >
              <AdGroupCreativeTableV2 />
            </AdGroupCreativeTableContextProvider>
          </Suspense>
        </ErrorBoundary>
      ) : (
        <AdGroupCreativeTable
          adGroupId={Number(adGroupId)}
          campaignId={Number(campaignId)}
        />
      )}
    </StyledAppHeader>
  );
};

export default CreativeIndexPage;
