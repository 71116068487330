import React from 'react';
import PropTypes from 'prop-types';

import InSync from './InSync';
import OutOfSync from './OutOfSync';

const BWSyncStatus = ({
  value,
  bwStatus,
  diff,
  isDisabled,
  isBWOverride,
}) => {
  if (bwStatus == true && !isDisabled) {
    return <InSync />;
  }

  const isAdGroup = value?.beeswax_lid;

  return isAdGroup ? (
    <OutOfSync
      adGroup={value}
      diff={diff}
      isDisabled={isDisabled}
      isBWOverride={isBWOverride}
    />
  ) : (
    <OutOfSync diff={diff} isDisabled={isDisabled} />
  );
};

BWSyncStatus.propTypes = {
  value: PropTypes.shape({
    beeswax_lid: PropTypes.number,
  }),
  bwStatus: PropTypes.bool,
  diff: PropTypes.object,
  isDisabled: PropTypes.bool,
  isBWOverride: PropTypes.bool,
};

export default BWSyncStatus;
