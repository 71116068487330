import React, { forwardRef } from 'react';

import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = forwardRef(
  (
    props: {
      name: string;
      onChange: (data: {
        target: {
          name: string;
          value: string;
        };
      }) => void;
    },
    ref
  ) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

NumericFormatCustom.displayName = 'NumericFormatCustom';

const NumberTextField = forwardRef<
  HTMLDivElement,
  TextFieldProps & {
    inputAdornment?: string;
  }
>(
  (
    { InputProps: { ...inputProps } = {}, inputAdornment = '$', ...props },
    ref
  ) => {
    return (
      <TextField
        {...props}
        ref={ref}
        InputProps={{
          ...inputProps,
          /** TODO: Move NumericFormatCustom to typescript. */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumericFormatCustom as unknown as any,
          inputProps: {
            ...props.inputProps,
            'data-testid': 'daily-campaign-budget',
          },
          startAdornment: (
            <InputAdornment position="start">{inputAdornment}</InputAdornment>
          ),
        }}
      />
    );
  }
);

NumberTextField.displayName = 'NumberTextField';

export default NumberTextField;
