import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Box } from '@mui/material';

export const DataGridLink = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: LinkProps['to'];
}) => {
  return (
    <Box
      component={Link}
      data-testid="campaign-link"
      sx={{
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        color: 'rgba(29, 175, 255, 1)',
      }}
      to={to}
    >
      {children}
    </Box>
  );
};
