import { Box, Grid, Stack, styled } from '@mui/material';
import React from 'react';

const StickyBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(14),
}));

export const Subway = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={3}>
      <StickyBox>
        <Stack spacing={1.5} useFlexGap alignItems="flex-start">
          {children}
        </Stack>
      </StickyBox>
    </Grid>
  );
};
