import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  styled,
  Grid,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Switch
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import FrequencyCapFieldsGroup from '@v2/components/campaign/FrequencyCapFieldsGroup/FrequencyCapFieldsGroup';
import { AdvancedBox } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBox';
import { AdvancedBoxFooter } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBoxFooter';

import {
  Copies,
  EXPERIMENT_OPTIONS,
  INCREMENTAL_OPTIONS,
  fields,
} from './constants';
import { useCopy } from '@hooks/copy';

const StyledFormBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  flex: 1,
  overflowY: 'auto',
}));

const StyledBlueIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.grey[3],
  paddingLeft: theme.spacing(3),
}));

const StyledInfoIcon = styled(StyledBlueIcon)({
  fontSize: '1rem',
  marginLeft: 8,
});

const StyledFormDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[6],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2.5),
}));

const StyledMainDivider = styled(StyledFormDivider)(({ theme }) => ({
  borderColor: theme.palette.grey[3],
  marginTop: 0,
}));

const StyledSubFormBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(),
  paddingLeft: theme.spacing(10),
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[1],
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 400,
    width: 184,
    padding: '4px 12px',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,

    ['&::before']: {
      boxShadow: theme.shadows[1],
    },
  },
}));

const PercentNumberFormat = forwardRef(function PercentNumberFormat(
  props,
  ref
) {
  const { name, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="%"
    />
  );
});
PercentNumberFormat.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

function AdvancedDeliveryOptionsForm({ dragActive, onSubmit, onCancel }) {
  const Copy = useCopy(Copies);

  const methods = useFormContext();

  const { control, formState, handleSubmit, setValue, watch } = methods;

  // Issue with isDirty: https://github.com/orgs/react-hook-form/discussions/7860
  const { isDirty, isValid } = formState;

  const incrementalityTooltip = dragActive ? (
    'Incrementality tests are not available for display retargeting.'
  ) : (
    <>
      <p>
        This setting will randomly set aside a percentage of your campaign
        audience as a holdout group to determine the effectiveness of your
        campaign.
      </p>
      <p>
        Instead of your assigned TV Ad, a randomly assigned PSA will be
        delivered to this audience to measure the performance of your ad
        compared to this control group.
      </p>
    </>
  );

  const [
    isCapAds,
    isExperiment,
    isIncremental,
    experimentPlan,
    experimentType,
  ] = watch([
    fields.isCapAds.path,
    fields.isExperiment.path,
    fields.isIncremental.path,
    fields.experimentPlan.path,
    fields.experimentType.path,
  ]);

  useEffect(() => {
    if (isExperiment && !experimentPlan) {
      setValue(fields.experimentPlan.path, EXPERIMENT_OPTIONS[0], {
        shouldDirty: true,
      });
    } else if (!isExperiment && experimentPlan) {
      setValue(fields.experimentPlan.path, '', { shouldDirty: true });
    }
  }, [isExperiment, experimentPlan]);

  useEffect(() => {
    if (isIncremental && !experimentType) {
      setValue(fields.experimentType.path, INCREMENTAL_OPTIONS[0].value, {
        shouldDirty: true,
      });
    } else if (!isIncremental && experimentType) {
      setValue(fields.experimentType.path, '', { shouldDirty: true });
    }
  }, [isIncremental, experimentType]);

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <AdvancedBox
        onClose={onCancel}
        header={<Typography variant="h3">{Copy.title}</Typography>}
      >
        <StyledFormBox>
          <StyledMainDivider />
          <Grid>
            <Grid item>
              <Controller
                name="isIncremental"
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        disabled={dragActive}
                        id="increment-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Test incrementality
                        <StyledTooltip
                          arrow
                          placement="top"
                          title={incrementalityTooltip}
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isIncremental && (
                <StyledSubFormBox>
                  <Controller
                    name={fields.experimentType.path}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Box>
                            <TextField
                              id="incremental-dropdown"
                              label="Holdout"
                              onChange={e => field.onChange(e.target.value)}
                              select
                              sx={theme => ({ width: theme.spacing(22) })}
                              value={field.value}
                            >
                              {INCREMENTAL_OPTIONS.map(({ label, value }) => (
                                <MenuItem
                                  key={value}
                                  data-testid={`${value}-option`}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        }
                      />
                    )}
                  />
                </StyledSubFormBox>
              )}

              {(isIncremental || isExperiment) && <StyledFormDivider />}
            </Grid>

            <Grid item>
              <Controller
                name={fields.isExperiment.path}
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        id="experiment-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Run an A/B test
                        <StyledTooltip
                          arrow
                          placement="top"
                          title="Split testing allows you to test different ad groups in a way that isolates the exposed households by test group so there's no contamination."
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isExperiment && (
                <StyledSubFormBox>
                  <Controller
                    name={fields.experimentPlan.path}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Box>
                            <TextField
                              data-testid="experiment-dropdown"
                              id="experiment-dropdown"
                              label="Splits"
                              onChange={e => field.onChange(e.target.value)}
                              select
                              sx={theme => ({ width: theme.spacing(22) })}
                              value={field.value}
                            >
                              {EXPERIMENT_OPTIONS.map(value => (
                                <MenuItem
                                  key={value}
                                  data-testid={`${value}-option`}
                                  value={value}
                                >
                                  {Copy[value]}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        }
                      />
                    )}
                  />
                </StyledSubFormBox>
              )}

              {(isExperiment || isCapAds) && <StyledFormDivider />}
            </Grid>

            <Grid item>
              <Controller
                name={fields.isCapAds.path}
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        id="adCap-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Configure Campaign Frequency Cap
                        <StyledTooltip
                          arrow
                          placement="top"
                          title="Frequency caps can be set at various time frames to control the over-delivery of your ad to the same household or ensure maximum reach across your target audience."
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isCapAds && (
                <StyledSubFormBox>
                  <FrequencyCapFieldsGroup fieldName={fields.freqCaps.path} />
                </StyledSubFormBox>
              )}
            </Grid>
          </Grid>
        </StyledFormBox>

        <AdvancedBoxFooter>
          <Button
            onClick={() => onCancel(methods)}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: 'auto' }}
            disabled={!isValid || !isDirty}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </AdvancedBoxFooter>
      </AdvancedBox>
    </Stack>
  );
}

export default AdvancedDeliveryOptionsForm;

AdvancedDeliveryOptionsForm.propTypes = {
  dragActive: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
