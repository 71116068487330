import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { RHFTextField } from '../../RHFTextField';
import {
  formValuesToApi,
  getInitialValues,
  validationSchema,
} from './formConfig';
import { useAPI } from '../../hooks/api';
import { UserDeleteDialog } from './UserDeleteDialog';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { useRBACContext } from '../../../providers/RBACContext';
import { useUserAccountsContext } from '../UserAccountsContext';
import { OrgRoleLabelMap } from '../../../constants';

const PREFIX = 'UserGeneralInfo';

const classes = {
  form: `${PREFIX}-form`,
  textfield: `${PREFIX}-textfield`,
  reset: `${PREFIX}-reset`,
  resetIcon: `${PREFIX}-resetIcon`,
  remove: `${PREFIX}-remove`,
  submit: `${PREFIX}-submit`,
  successMsg: `${PREFIX}-successMsg`
};

const Root = styled('form')(({
  theme: { spacing, typography, palette }
}) => ({
  [`&.${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  [`& .${classes.textfield}`]: {
    marginBottom: spacing(3),
  },

  [`& .${classes.reset}`]: {
    fontSize: '0.825rem',
    color: palette.primary.light,
  },

  [`& .${classes.resetIcon}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.remove}`]: {
    color: '#f76a6a',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  }
}));

const UserGeneralInfo = ({
  currentUser,
  user,
  setUser,
  org,
  orgUser,
  onDelete,
}) => {

  const { usePatch, usePost } = useAPI();

  const [status, setStatus] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const { roles } = useRBACContext();
  const { onUserChange } = useUserAccountsContext()

  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  const { control, handleSubmit, reset } = useForm({
    defaultValues: getInitialValues({ user, orgUser }),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(getInitialValues({ user, orgUser }));
  }, [user, orgUser]);

  const canChangeRole = useMemo(() => {
    const isCurrentRoleAvailable = roles?.organization_roles?.find(
      r => r.value === orgUser.role
    );
    return isCurrentRoleAvailable;
  }, [roles]);

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    try {
      const [{ data }] = await Promise.all([
        usePatch(`/users/${user.id}/`, formValuesToApi(values)),
        canChangeRole
          ? usePatch(`/organization_users/${orgUser.id}/`, { role: values.role })
          : Promise.resolve(),
      ]);

      setStatus('success');
      onUserChange(orgUser.id, { role: values.role })
      setUser(data);
    } catch (error) {
      setStatus('error', error);
    }
  });

  const handleDelete = async () => {
    setShowDeleteDialog(false);
    try {
      await usePatch(`/organization_users/${currentUser.id}/`, {
        status: 'DEACTIVATED',
      });
      onDelete();
    } catch (error) {
      console.log('error in deleting user', error);
    }
  };

  const handleResetPassword = async () => {
    setIsResetPassword(false);
    setIsResetting(true);

    try {
      // API call expected empty payload
      const res = await usePost(`/users/${user.id}/reset_password`, {});

      if (res) {
        setIsResetPassword(true);
        setIsResetting(false);
      }
    } catch (err) {
      console.error('Error resetting password', err);
    }
  };

  return (
    <Root
      className={classes.form}
      onSubmit={onSubmit}
      noValidate
      onReset={() => reset(getInitialValues(user))}
    >
      <Box display="flex" justifySelf="flex-start" gap="6%">
        <Box width="40%" display="flex" flexDirection="column">
          <RHFTextField
            className={classes.textfield}
            label="First Name"
            name="firstName"
            control={control}
          />

          <RHFTextField
            className={classes.textfield}
            label="Last Name"
            name="lastName"
            control={control}
          />

          <RHFTextField
            className={classes.textfield}
            label="Mobile Phone (Optional)"
            name="phone"
            control={control}
          />

          <RHFTextField
            className={classes.textfield}
            select
            label="Org User Role"
            name="role"
            clearable
            control={control}
            disabled={!canChangeRole}
          >
            {!canChangeRole && (
              <MenuItem value={orgUser.role}>{OrgRoleLabelMap[orgUser.role]}</MenuItem>
            )}
            {(roles?.organization_roles || []).map(r => (
              <MenuItem key={r.value} value={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </RHFTextField>

          <Box>
            <Button
              className={classes.remove}
              size="small"
              startIcon={<DeleteOutlineOutlinedIcon fontSize="small" />}
              onClick={() => setShowDeleteDialog(true)}
            >
              Deactivate User
            </Button>
          </Box>
        </Box>

        <Box width="40%" display="flex" flexDirection="column">
          <RHFTextField
            className={classes.textfield}
            label="Email Address"
            name="email"
            control={control}
          />

          <TextField
            className={classes.textfield}
            color="secondary"
            type="password"
            label="Password"
            name="password"
            variant="outlined"
            value="999999999999999"
            InputProps={{
              endAdornment: isResetting ? (
                <Box height={30} width={30} position="relative">
                  <LoadingSpinner size={25} />
                </Box>
              ) : (
                <Button color="secondary" onClick={handleResetPassword}>
                  RESET
                </Button>
              ),
            }}
          />

          {isResetPassword && (
            <Box display="flex" mb={4} mt={-2}>
              <Box mr={1}>
                <InfoOutlinedIcon
                  className={classes.resetIcon}
                  fontSize="small"
                />
              </Box>

              <Typography className={classes.reset}>
                Please check your email for instructions on resetting your
                password.
              </Typography>
            </Box>
          )}

          <TextField
            disabled
            className={classes.textfield}
            label="Primary Organization"
            name="primaryOrg"
            variant="outlined"
            value={orgUser.organization_name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LockOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        mt={6}
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your Settings have been saved!
          </Typography>
        )}

        <Button
          disabled={isLoading}
          disableElevation
          disableRipple
          type="reset"
        >
          Cancel
        </Button>

        <Button
          className={classes.submit}
          disableRipple
          color="secondary"
          variant="contained"
          type="submit"
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>

      {showDeleteDialog && (
        <UserDeleteDialog
          userName={`${user.first_name} ${user.last_name}`}
          orgName={org.name}
          onClose={() => setShowDeleteDialog(false)}
          onDelete={handleDelete}
        />
      )}
    </Root>
  );
};

UserGeneralInfo.propTypes = {
  currentUser: PropTypes.object,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  org: PropTypes.object,
  orgUser: PropTypes.object,
  onDelete: PropTypes.func,
};

export default UserGeneralInfo;
