import { RouteBase } from '../types';
import { useWizardNavigation } from './useWizardNavigation';
import { useWizardQuery } from './useWizardQuery';

export const useWizard = ({
  sections,
  sectionNames,
  base,
}: {
  sections: string[];
  sectionNames: string[];
  base?: RouteBase;
}) => {
  const query = useWizardQuery({
    base,
  });

  const navigation = useWizardNavigation({
    sections,
    sectionNames,
    base,
  });

  return {
    navigation,
    query,
    sections,
  };
};
