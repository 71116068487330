import React from 'react';
import { Button } from '@mui/material';
import { SectionActionProps } from './types';

const SectionAction = ({
  label,
  handler,
  disabled,
  ...props
}: SectionActionProps) => {
  return (
    <Button onClick={handler} disabled={disabled} {...props}>
      {label}
    </Button>
  );
};

export default SectionAction;
