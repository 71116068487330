import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useUser } from '@hooks/user';
import { useFlags } from '@hooks/flags';
import BWSyncStatus from '../components/BWSync';

const BWSyncContext = React.createContext(null);

BWSyncContext.displayName = 'BWSyncContext';

export const BWSyncContextProvider = ({ children }) => {
  const { user } = useUser();
  const { flags, Flags } = useFlags();
  const [bwSync, setBWSync] = useState({});

  const isTargetingOverrideEnabled =
    flags[Flags.TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE];
  const showBWSync = !!user?.is_tvsci_employee;

  const renderBWSyncStatus = (value, isDisabled) => {
    return value ? (
      <BWSyncStatus
        value={value}
        bwStatus={value.in_sync}
        diff={value.out_of_sync ?? {}}
        isDisabled={isDisabled}
        isBWOverride={
          Boolean(value.out_of_sync?.beeswax_targeting) &&
          isTargetingOverrideEnabled
        }
      />
    ) : (
      '-'
    );
  };

  return (
    <BWSyncContext.Provider
      value={{ setBWSync, bwSync, showBWSync, renderBWSyncStatus }}
    >
      {children}
    </BWSyncContext.Provider>
  );
};

BWSyncContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BWSyncContext;
