import React from 'react';
import { Campaign } from '@local-types';
import { AdGroup } from './hooks/adGroups';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useArchiveUnarchiveCampaign } from './hooks/apis/campaigns';
import { useArchiveUnarchiveAdGroup } from './hooks/apis/adGroups';
import AsyncButton from './AsyncButton';
import { useLoader } from './hooks';
import { defaultTheme_v2 } from '@themes/default_v2';
import { useErrors } from './hooks/errors';

type ArchiveUnarchiveProps = {
  isOpen: boolean;
  item: Campaign | AdGroup;
  onClose: () => void;
  isArchiving: boolean;
  itemType: 'Campaign' | 'Ad Group';
};

const PREFIX = 'ArchiveUnarchive';

const classes = {
  dialog: `${PREFIX}-dialog`,
  campaign: `${PREFIX}-campaign`,
  confirm: `${PREFIX}-confirm`,
};

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  [`&.${classes.dialog}`]: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },

  [`& .${classes.confirm}`]: {
    color: 'white',
    backgroundColor: defaultTheme_v2.palette.red[2],
    '&:hover': {
      backgroundColor: defaultTheme_v2.palette.red[2],
    },
  },
}));

const ArchiveUnarchive = ({
  isOpen,
  item,
  onClose,
  isArchiving,
  itemType,
}: ArchiveUnarchiveProps) => {
  const { isLoading, setIsLoading } = useLoader();
  const { trigger: archiveUnarchiveCampaignTrigger } =
    useArchiveUnarchiveCampaign();
  const { trigger: archiveUnarchiveAdGroupTrigger } =
    useArchiveUnarchiveAdGroup();
  const { handleError } = useErrors();

  const handleArchiveUnarchive = async () => {
    setIsLoading(true);

    try {
      if (itemType === 'Campaign') {
        await archiveUnarchiveCampaignTrigger({
          campaign: item as Campaign,
          isArchiving,
        });
      } else if (itemType === 'Ad Group') {
        await archiveUnarchiveAdGroupTrigger({
          adGroup: item as AdGroup,
          isArchiving,
        });
      }
      onClose();
    } catch (error) {
      handleError(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledDialog
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="archive-unarchive-dialog-title"
      data-testid="archive-unarchive-dialog"
    >
      <DialogTitle id="archive-unarchive-dialog-title">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
        >
          <Box>
            <Typography variant="h4">
              <span>
                {isArchiving ? 'Archive' : 'Unarchive'} {itemType}
              </span>
            </Typography>
          </Box>

          <IconButton
            onClick={onClose}
            size="large"
            data-testid="archive-unarchive-dialog-close-btn"
            title="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box px={3}>
        <Divider />
      </Box>

      <DialogContent className={classes.dialog}>
        <Box py={2} px={2} mb={4}>
          <Box mb={2}>
            <Typography variant="body1">
              Are you sure you want to {isArchiving ? 'archive' : 'unarchive'}{' '}
              <b>{item.name}</b>?
            </Typography>
          </Box>

          {isArchiving && (
            <Typography variant="body1">
              Archived {`${itemType.toLowerCase()}s`} are hidden from view by
              default.
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        disableSpacing
        sx={{ backgroundColor: '#e5e7eb', px: 4, py: 3 }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button onClick={onClose}>Cancel</Button>
          </Grid>

          <Grid item>
            <AsyncButton
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleArchiveUnarchive}
              textButton={`Yes, ${isArchiving ? 'Archive' : 'Unarchive'}`}
              loadingButton={
                isLoading
                  ? isArchiving
                    ? 'Archiving...'
                    : 'Unarchiving...'
                  : isArchiving
                    ? 'Archive'
                    : 'Unarchive'
              }
              classes={classes.confirm}
              data-testid="archive-unarchive-dialog-confirm-btn"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default ArchiveUnarchive;
