/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import _, { defaults, keys } from 'lodash';
import states from '../utilStates';
import { GeoSearchParams } from '@v2/components/campaign/GeoTargeter/types';
import { geoTypes } from '@v2/components/campaign/GeoTargeter/constants';

interface State {
  name: string;
  abbreviation: string;
}

interface GeoResult {
  context?: Array<{ id: string; text: string }>;
  place_name?: string;
  place_type?: string[];
  text: string;
}

// Ensure the imported states match our interface
const typedStates: State[] = states;

////////////////////////////
// MAPBOX CONSTANTS
////////////////////////////
const mbToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geoBaseUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places`;

const defaultGeoUrlQueryParams = {
  fuzzyMatch: 'false',
  country: 'us',
  types: keys(geoTypes) as (keyof typeof geoTypes)[],
} as const;

export const geoUrl = (
  search: string,
  params?: Omit<GeoSearchParams, 'disabled' | 'autocomplete'>
) => {
  const { types, ...other } = defaults(params, defaultGeoUrlQueryParams);
  const actualTypes = types.filter(type => type !== geoTypes.dma);
  const query = new URLSearchParams({
    ...other,
    types: actualTypes.join(','),
  });
  return `${geoBaseUrl}/${JSON.stringify(
    search
  )}.json?access_token=${mbToken}&${query.toString()}`;
};

// Prepare data for BE
const formatGeo = (results: any[]): ResultsObj => {
  const formatResults = results.map(r => {
    // Handle Entire US data
    if (r.place_name && r.place_name === 'Entire US') {
      return {
        name: r.place_name,
        type: 'country',
        blacklist: r.blacklist,
      };
    }
    // Handle DMA data
    if (r.code) {
      let name = r.text ? r.text : r.name;
      if (r.place_name) {
        name = r.place_name;
      }
      return {
        code: r.code,
        name,
        type: 'dma',
        blacklist: r.blacklist,
      };
    }
    const { blacklist, id, name, place_name, place_type, text, type } = r;
    // Handle Mapbox data
    return {
      id,
      text,
      name: place_name ? place_name : name ? name : text,
      type: place_type && place_type[0] ? place_type[0] : type,
      blacklist,
    };
  });
  const resultsObj: ResultsObj = {
    cities: [],
    countries: [],
    dmas: [],
    states: [],
    zipcodes: [],
  };
  formatResults.forEach(res => {
    switch (res.type) {
      case 'dma':
        resultsObj.dmas.push(res);
        break;
      case 'place':
      case 'neighborhood':
        resultsObj.cities.push(res);
        break;
      case 'region':
        resultsObj.states.push(res);
        break;
      case 'postcode':
        resultsObj.zipcodes.push(res);
        break;
      default:
        resultsObj.countries.push(res);
        break;
    }
  });
  return resultsObj;
};

const formatGeoForClient = (geo: any[]): FormattedGeoResult[] => {
  // get geo reuslts and format for display
  let formattedGeoResults: any[] = [];
  const geoResults = geo;
  _.map(geoResults, i => {
    if (i.length !== 0) {
      formattedGeoResults.push(i);
    }
  });
  formattedGeoResults = _.flatten(formattedGeoResults);
  formattedGeoResults = formattedGeoResults.map(
    ({ name: place_name, ...rest }) => ({ place_name, ...rest })
  );
  return formattedGeoResults;
};

const formatGeoName = (result: GeoResult): string => {
  const { context, place_name, place_type, text } = result;
  if (place_name && place_type && context) {
    if (['place', 'neighborhood', 'postcode'].indexOf(place_type[0]) > -1) {
      const contextState = context.filter(
        (c: { id: string }) => c.id.indexOf('region') > -1
      );
      if (contextState.length > 0) {
        const state = typedStates.find(s => s.name === contextState[0].text);
        return state ? `${text}, ${state.abbreviation} (City)` : text;
      }
    }
  }
  if (place_type && place_type[0] && place_type[0] === 'region') {
    return `${text}, US (State)`;
  }
  return place_name ? place_name : text;
};
////////////////////////
// useGeo HOOK
////////////////////////
export const useGeo = () => {
  const [geo, setGeo] = useState<any[]>([]);
  return {
    formatGeo,
    formatGeoForClient,
    formatGeoName,
    geo,
    geoUrl,
    setGeo,
  };
};

interface ResultsObj {
  cities: any[];
  countries: any[];
  dmas: any[];
  states: any[];
  zipcodes: any[];
}

interface FormattedGeoResult {
  place_name: string;
  [key: string]: any;
}
