import React from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import { CreativeAdGroupV1 } from '@local-types';
import { Box, Typography } from '@mui/material';

export const CreativeLibraryAdGroupCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>,
) => {
  const { adgroup_count } = params.row;

  const isAssignable = adgroup_count && adgroup_count < 25;
  const hasAdGroups = adgroup_count && adgroup_count > 0;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {hasAdGroups ? (
        <Typography
          variant="body2"
          data-testid={`${
            isAssignable ? 'assignable-creative' : 'not-assignable-creative'
          }`}
        >{`${adgroup_count} Ad Group${
          adgroup_count > 1 ? 's' : ''
        }`}</Typography>
      ) : (
        <Typography variant="body2" data-testid="not-assigned-creative">
          Not Assigned
        </Typography>
      )}
    </Box>
  );
};
