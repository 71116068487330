import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useOktaAuth } from '@okta/okta-react';

import { usePingAuth } from '../components/hooks/ping';
import { nbcuPingAuthorizeUrl } from '../ping';

const AuthContext = React.createContext();

AuthContext.displayName = 'AuthContext';

const HOME_PATH = '/';
const QUIET_CALLBACK_PATH = '/login/callback/quiet';

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState({ isPending: true });
  const { authState: oktaAuthState, oktaAuth } = useOktaAuth();
  const { authState: pingAuthState, pingAuth } = usePingAuth();

  const revokeAccessToken = async () => {
    if (oktaAuthState.isAuthenticated) {
      return await oktaAuth.revokeAccessToken();
    }
    if (pingAuthState.isAuthenticated) {
      return await pingAuth.revokeAccessToken();
    }
  };

  const oktaSignInWithRedirect = ({ oktaOptions }) => {
    setState({ isPending: true });
    const baseUri = window.location.origin + HOME_PATH;
    oktaAuth.setOriginalUri(baseUri);
    oktaAuth.signInWithRedirect(oktaOptions);
  };

  const oktaSignInWithCredentials = ({ oktaOptions }) => {
    setState({ isPending: true });

    const redirectUri = window.location.origin + QUIET_CALLBACK_PATH;

    return oktaAuth
      .signInWithCredentials(oktaOptions)
      .then(transaction => {
        if (transaction.status == 'SUCCESS') {
          return oktaAuth.session.setCookieAndRedirect(
            transaction.sessionToken,
            redirectUri
          );
        }

        console.log(`Will not handle the ${transaction.status} status`);
        return oktaSignInWithRedirect({ loginHint: oktaOptions.email });
      })
      .catch((err)  => {
        console.error('okta signInWithCredentials error', err);
        throw err;
      });
  };

  const oktaGetTokensWithRedirect = (url = HOME_PATH) => {
    const baseUri = `${window.location.origin}${url}`;
    oktaAuth.setOriginalUri(baseUri);

    return oktaAuth.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
      })
      .catch(err => {
        console.log('Error on quiet Okta callback...', err);
      });
  };

  const attemptQuietLogin = async () => {
    try {
      // NOTE: try getting okta token
      // NOTE: no way to currently do this on Ping
      const result = await oktaAuth.token.getWithoutPrompt({
        responseType: ['token', 'id_token'],
      })
      oktaAuth.tokenManager.setTokens(result.tokens);

      const homeUri = window.location.origin + HOME_PATH;
      window.location.replace(homeUri);
    } catch (error) {
      console.log('Failed to login quietly', error);
    }
  }

  const nbcuSignIn = () => {
    clearAuth();
    setState({ isPending: true });
    window.location.replace(nbcuPingAuthorizeUrl);
  };

  const getUser = async () => {
    try {
      if (oktaAuthState.isAuthenticated) {
        return await oktaAuth.getUser();
      }
      if (pingAuthState.isAuthenticated) {
        return await pingAuth.getUser();
      }
    } catch (err) {
      if (err.name == 'AuthApiError' && err.xhr.status == 401) {
        clearAuth();
      }
    }
  };

  const signOut = async () => {
    if (oktaAuthState.isAuthenticated) {
      return await oktaAuth.signOut();
    }
    if (pingAuthState.isAuthenticated) {
      return await pingAuth.signOut();
    }
  };

  const clearAuth = () => {
    setState({ isAuthenticated: false, isPending: false });
    oktaAuth.tokenManager.clear();
    pingAuth.clear();
  }

  useEffect(() => {
    if (oktaAuthState && oktaAuthState?.isAuthenticated) {
      setState({
        accessToken: oktaAuthState.accessToken.value,
        idToken: oktaAuthState.idToken.value,
        isAuthenticated: oktaAuthState.isAuthenticated,
        isPending: oktaAuthState.isPending,
        sso: 'okta',
      });
      return;
    }
    if (pingAuthState && pingAuthState?.isAuthenticated) {
      setState({
        ...pingAuthState,
        sso: 'ping',
      });
      return;
    }

    if (!oktaAuthState.isPending && !pingAuthState.isPending) {
      setState({ isAuthenticated: false, isPending: false });
    }
  }, [
    oktaAuthState.isAuthenticated,
    oktaAuthState.isPending,
    pingAuthState.isAuthenticated,
    pingAuthState.isPending,
  ]);

  return (
    <AuthContext.Provider
      value={{
        authState: state,
        auth: {
          getUser,
          revokeAccessToken,
          oktaSignInWithRedirect,
          oktaSignInWithCredentials,
          oktaGetTokensWithRedirect,
          nbcuSignIn,
          signOut,
          clearAuth,
          attemptQuietLogin,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
