import { Stack, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { Variant, iconColors, iconSize, textColors } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { get } from 'lodash';
import { ColorType, ColorVariant, InfoProps } from './types';

const Alert = styled(Stack)<{
  color: string;
  type: ColorType;
  variant: ColorVariant;
}>(({ theme, type, variant }) => {
  const AlertColor = {
    [Variant.filled]: {
      success: theme.palette.green[10],
      info: theme.palette.blue[10],
      error: theme.palette.red[5],
      warning: theme.palette.tip.light,
      caption: theme.palette.grey[4],
    },
    [Variant.outlined]: {
      caption: 'transparent',
      success: 'transparent',
      info: 'transparent',
      error: 'transparent',
    },
    [Variant.text]: {
      caption: 'transparent',
      success: 'transparent',
      info: 'transparent',
      error: 'transparent',
    },
  } as const;

  return `
    padding: ${
      [Variant.outlined, Variant.text].includes(variant)
        ? 0
        : theme.spacing(1.5, 2.5)
    };
    border-radius: ${theme.spacing(0.55)};
    gap: ${theme.spacing(1.5)};
    align-items: center;
    background-color: ${AlertColor[variant][type]};
  `;
});

const IconStyled = styled(FontAwesomeIcon, {
  shouldForwardProp: prop =>
    prop !== 'color' && prop !== 'type' && prop !== 'variant',
})<{
  color: string;
  type: ColorType;
  variant: keyof typeof Variant;
}>(({ theme, color, type, variant }) => ({
  paddingTop: theme.spacing(0.25),
  width: theme.spacing(iconSize[variant][type]),
  height: theme.spacing(iconSize[variant][type]),
  color: get(theme.palette, color),
}));

const Info = ({
  type = 'success',
  variant = 'filled',
  icon,
  children,
  ...props
}: InfoProps) => {
  const actualIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    const iconSx = {
      [Variant.filled]: {
        color: iconColors[variant][type],
      },
      [Variant.outlined]: {
        color: iconColors[variant][type],
      },
      [Variant.text]: {
        color: iconColors[variant][type],
      },
    };

    const iconDefault = {
      [Variant.filled]: faSquareInfo,
      [Variant.outlined]: faSquareInfo,
      [Variant.text]: faTriangleExclamation,
    };

    const sx = iconSx[variant];

    return (
      <IconStyled
        icon={iconDefault[variant]}
        variant={variant}
        type={type}
        color={sx.color}
      />
    );
  }, [icon, variant, iconColors]);
  return (
    <Alert
      {...props}
      direction="row"
      color={textColors[variant]?.[type]}
      variant={variant}
      type={type}
    >
      {actualIcon}
      {children}
    </Alert>
  );
};

export default Info;
