import React from 'react';
import PropTypes from 'prop-types';

import { useGetFlags } from '@apis/flags';
import { DefaultFlags } from '../constants/flags';

const FlagsContext = React.createContext(null);

FlagsContext.displayName = 'FlagsContext';

const flagsFromLocalStorage = JSON.parse(localStorage.getItem('flags-override') || '{}');

export const FlagsContextProvider = ({ children }) => {
  const { flags, isFlagsLoading } = useGetFlags();

  return (
    <FlagsContext.Provider
      value={{
        flags: {
          ...DefaultFlags,
          ...flags,
          ...flagsFromLocalStorage,
        },
        isFlagsLoading,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};

FlagsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlagsContext;
