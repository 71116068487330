import { styled } from '@mui/material/styles';
import AppHeader from '../../AppHeader';

const PREFIX = 'CreativeIndexPage';


export const classes = {
  link: `${PREFIX}-link`,
  noWrap: `${PREFIX}-noWrap`,
  height: `${PREFIX}-height`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  tableHeader: `${PREFIX}-tableHeader`,
};

export const StyledAppHeader = styled(AppHeader)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },

  [`& .${classes.noWrap}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 81,
  },

  [`& .${classes.height}`]: {
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.stats}`]: {
    fontWeight: '700',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableCell}`]: {
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },
}));
