import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  TextField
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSnackbar } from 'notistack';

import { getSuggestions } from './GeoAutoSuggest';
import AsyncButton from './AsyncButton';
import Title from './Title';
import { useAPI } from './hooks/api';
import { useGeo } from './hooks/geo';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { dmaData } from './util';
import { Themes } from '../constants';

const PREFIX = 'UploadGeo';

const classes = {
  divider: `${PREFIX}-divider`,
  switch: `${PREFIX}-switch`,
  title: `${PREFIX}-title`,
  input: `${PREFIX}-input`,
  saveBtn: `${PREFIX}-saveBtn`,
  cancelBtn: `${PREFIX}-cancelBtn`,
  linearProgress: `${PREFIX}-linearProgress`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  position: 'absolute',
  background: theme.palette.background.overlay,
  padding: '30px 60px',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.switch}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.input}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.saveBtn}`]: {
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: '1.05rem',

    '&.Mui-disabled': {
      color: '#979BA1',
      background: '#2672A1',
    },
  },

  [`& .${classes.cancelBtn}`]: {
    color: '#1dafff'
  },

  [`& .${classes.linearProgress}`]: {
    marginTop: 12,
    borderRadius: 4,
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: 30,
  '& label.Mui-focused': {
    color: theme.palette.text.overlay,
  },
  '& label': {
    color: theme.palette.text.overlay,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.border.overlay,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.border.overlay,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.border.overlay,
    },
    '& label.Mui-focused': {
      color: theme.palette.text.overlay,
    },
  },
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Upload Geo-targeting'
  },
  [Themes.NBCU]: {
    HEAD: 'Upload Geotargeting'
  },
};

const ZIP_CODES_CHUNK_SIZE = 50;
const ZIP_CODE_CHUNK_MIN_WAIT = ZIP_CODES_CHUNK_SIZE * 100;

const UploadGeo = props => {
  const {
    setGeoResults,
    targetEntireUS,
    setTargetEntireUS,
    showOverlay,
    ...rest
  } = props;

  const theme = useTheme();
  const { geoUrl } = useGeo();
  const { useGet } = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoader();
  const Copy = useCopy(Copies);

  const [bulkZipString, setBulkZipString] = useState('');
  const [bulkCityString, setBulkCityString] = useState('');
  const [bulkStateString, setBulkStateString] = useState('');
  const [bulkDMAString, setBulkDMAString] = useState('');
  const [searchType, setSearchType] = useState('zip');
  const [label, setLabel] = useState('Zip Codes');
  const [include, setInclude] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [itemsToProcess, setItemsToProcess] = useState(0);

  const hasData = bulkZipString || bulkCityString || bulkStateString || bulkDMAString;

  const handleAlignment = (event, val) => {
    setLabel(event.target.innerText);
    setSearchType(val);
  };

  const handleInclude = () => {
    setInclude(current => !current);
  };

  const handleSubmit = suggestions => {
    setGeoResults(prev => {
      const tempData = [...suggestions, ...prev];
      const dmas = _.chain(tempData).filter('code').uniqBy('code');
      const others = _.chain(tempData).filter('id').uniqBy('id');
      const results = [...dmas, ...others];

      return results;
    });

    return;
  };

  const _getZipCodesChunk = async (chunk, features, failedZips) => {
    const promises = [];

    _.map(chunk, code => {
      if (!isNaN(code)) {
        const url = geoUrl(code);
        promises.push(useGet(url));
      }
    });

    promises.push(new Promise((resolve) => setTimeout(resolve, ZIP_CODE_CHUNK_MIN_WAIT)));

    const responses = await Promise.all(promises);

    _.map(responses, response => {
      if (response && response.features.length === 0) {
        failedZips.push(response.query);
        failedZips = _.flatten(failedZips);
      }
      if (response && response.features) {
        features.push(response.features);
      }
    });
  };

  const getZipCodes = async () => {
    let zipcodes = bulkZipString
      .replace(/\n/g, ',')
      .split(',')
      .map(el => el.trim());
    setItemsToProcess(prev => prev + zipcodes.length);

    const failedZips = [];

    // make sure zipcodes are valid
    zipcodes = _.map(zipcodes, code => {
      if (`${code}`.length === 5) {
        return code;
      }
      failedZips.push(code);
    });

    const chunks = [];
    for (let i = 0; i < zipcodes.length; i += ZIP_CODES_CHUNK_SIZE) {
      const chunk = zipcodes.slice(i, i + ZIP_CODES_CHUNK_SIZE);
      chunks.push(chunk);
    }

    let features = [];
    for (const chunkIndex in chunks) {
      try {
        await _getZipCodesChunk(chunks[chunkIndex], features, failedZips);
        setUploadProgress(prev => prev + chunks[chunkIndex].length);
      } catch (error) {
        console.log(error);
      }
    }

    // Display error if not found
    if (failedZips.length > 0) {
      enqueueSnackbar(`${failedZips.length} zipcodes were not found: ${failedZips.slice(0, 6).join(',')}${failedZips.length > 7 ? '...' : '.'}`, {
        variant: 'warning',
      });
    }

    features = _.map(features, i => {
      return _.filter(i, x => x !== undefined);
    });

    features = _.filter(features, x => x.length !== 0);

    features = _.flatten(features);

    features = _.forEach(features, feature => {
      if (!include) {
        feature.blacklist = true;
      }
    });

    return features;
  };

  const getCities = async () => {
    let errors = [];

    const promises = [];
    const cities = bulkCityString.replace(/\n/g, ',').split(',');
    setItemsToProcess(prev => prev + cities.length);

    try {
      _.map(cities, code => {
        const url = geoUrl(code, cities);
        promises.push(useGet(url));
      });
      const responses = await Promise.all(promises);
      let features = [];
      _.map(responses, response => {
        if (response && response.features.length === 0) {
          errors.push(response.query);
          errors = _.flatten(errors);
        }
        if (response && response.features) {
          features.push(response.features);
        }
      });

      setUploadProgress(prev => prev + responses.length);

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} cities were not found.`, {
          variant: 'warning',
        });
      }

      features = _.map(features, i => {
        return _.filter(i, x => x !== undefined);
      });

      features = _.filter(features, x => x.length !== 0);
      let results = [];

      _.map(features, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const getDMAs = async () => {
    const dmas = bulkDMAString.replace(/\n/g, ',').split(',');
    setItemsToProcess(prev => prev + dmas.length);
    const errors = [];

    try {
      let responses = _.map(dmas, code => {
        return getSuggestions([...dmaData], code);
      });

      _.map(responses, (res, i) => {
        if (res.length === 0) {
          errors.push(dmas[i]);
        }
      });

      setUploadProgress(prev => prev + responses.length);

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} DMAs were not found.`, {
          variant: 'warning',
        });
      }

      let results = [];

      responses = _.map(responses, i => {
        return _.filter(i, x => x !== undefined);
      });

      responses = _.filter(responses, x => x.length !== 0);

      _.map(responses, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    let errors = [];
    const promises = [];
    const cities = bulkStateString.replace(/\n/g, ',').split(',');
    setItemsToProcess(prev => prev + cities.length);

    try {
      _.map(cities, code => {
        const url = geoUrl(code, cities);
        promises.push(useGet(url));
      });
      const responses = await Promise.all(promises);
      let features = [];
      _.map(responses, response => {
        if (response && response.features.length === 0) {
          errors.push(response.query);
          errors = _.flatten(errors);
        }
        if (response && response.features) {
          features.push(response.features);
        }
      });

      setUploadProgress(prev => prev + responses.length);

      let results = [];

      features = _.map(features, featureArr => {
        return _.map(featureArr, i => {
          if (i.place_type[0] === 'region') {
            return i;
          }
        });
      });

      // Display error if not found
      if (errors.length > 0) {
        enqueueSnackbar(`${errors.join(',')} states were not found.`, {
          variant: 'warning',
        });
      }

      features = _.map(features, i => {
        return _.filter(i, x => x !== undefined);
      });

      features = _.filter(features, x => x.length !== 0);

      _.map(features, feature => {
        results.push(_.head(feature));
      });

      results = _.forEach(results, feature => {
        if (!include) {
          feature.blacklist = true;
        }
      });
      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const search = async () => {
    setIsLoading(true);
    const promises = [];

    if (bulkZipString) {
      promises.push(getZipCodes());
    }
    if (bulkCityString) {
      promises.push(getCities());
    }

    if (bulkStateString) {
      promises.push(getStates());
    }
    if (bulkDMAString) {
      promises.push(getDMAs());
    }

    const promiseResults = await Promise.all(promises);

    const results = _.flatten(promiseResults);

    handleSubmit(results);

    setBulkDMAString('');
    setBulkZipString('');
    setBulkStateString('');
    setBulkCityString('');

    setIsLoading(false);
    showOverlay(false);

    if (targetEntireUS) {
      setTargetEntireUS(false);
    }
  };

  const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 10,
    color: theme.palette.text.overlay,
  }));

  const geoInput = () => {
    let input = (
      <StyledTextField
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        color="primary"
        fullWidth
        id="bulkGeo"
        label={label}
        multiline
        placeholder={`Paste ${label} separated by commas or line breaks`}
        rows={6}
        variant="outlined"
        value={bulkZipString}
        onChange={event => setBulkZipString(event.target.value)}
      />
    );

    switch (searchType) {
      case 'zip':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by commas or line breaks`}
            rows={6}
            variant="outlined"
            value={bulkZipString}
            onChange={event => setBulkZipString(event.target.value)}
          />
        );
        break;
      case 'city':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkCityString}
            onChange={event => setBulkCityString(event.target.value)}
          />
        );
        break;
      case 'state':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkStateString}
            onChange={event => setBulkStateString(event.target.value)}
          />
        );
        break;
      case 'dma':
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkDMAString}
            onChange={event => setBulkDMAString(event.target.value)}
          />
        );
        break;
      default:
        input = (
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            color="primary"
            fullWidth
            id="bulkGeo"
            label={label}
            multiline
            placeholder={`Paste ${label} separated by comma`}
            rows={6}
            variant="outlined"
            value={bulkZipString}
            onChange={event => setBulkZipString(event.target.value)}
          />
        );
        break;
    }

    return input;
  };

  return (
    <StyledGrid {...rest}>
      <Title className={classes.title}>{Copy.HEAD}</Title>
      <Divider className={classes.divider} />

      <ToggleButtonGroup
        value={searchType}
        onChange={handleAlignment}
        exclusive
      >
        <ToggleButton value="zip" >
          Zip Codes
        </ToggleButton>
        <ToggleButton value="city">
          Cities & States
        </ToggleButton>
        <ToggleButton value="dma">
          DMAs
        </ToggleButton>
        <ToggleButton value="state" >
          States
        </ToggleButton>
      </ToggleButtonGroup>

      <span style={{ color: theme.palette.text.overlay, fontSize: '0.75rem', marginLeft: 30 }}>
        Exclude
      </span>
      <StyledFormControlLabel
        className={classes.switch}
        label="Include"
        control={
          <Switch
            checked={include}
            onChange={handleInclude}
            color="secondary"
            name="include"
            size="small"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
      />

      {geoInput()}

      {isLoading && (
        <LinearProgress
          color="secondary"
          className={classes.linearProgress}
          variant="determinate"
          value={100 / itemsToProcess * uploadProgress}
        />
      )}

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ marginTop: 35 }}
      >
        <Grid item xs={1}>
          <Button
            onClick={() => showOverlay(false)}
            className={classes.cancelBtn}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <AsyncButton
            color="secondary"
            isLoading={isLoading}
            fullWidth
            onClick={() => search()}
            textButton="Save"
            variant="contained"
            className={classes.saveBtn}
            disabled={!hasData}
            disableElevation
            size="large"
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

UploadGeo.propTypes = {
  showOverlay: PropTypes.func,
  setGeoResults: PropTypes.func,
  targetEntireUS: PropTypes.bool,
  setTargetEntireUS: PropTypes.func,
};

export default UploadGeo;
