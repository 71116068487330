import { isUndefined, omit, uniqBy } from 'lodash';
import { KeyboardEvent, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { GeoItem, UseChipSelectorArgs } from '../types';
import {
  getGeoWithTextByDistinct,
  getTemporaryGeo,
  mapBoxItemToGeoItem,
} from '../utils';
import { useGeoDropzone } from './useGeoDropzone';
import { useGeoSearchV2 } from './useGeoSearch';
import { useCheckCity } from '@hooks/checkCity';
import { useCopyNested } from '@hooks/copy';
import { Copies, distinctNames } from '../constants';
import { useSnackbar } from 'notistack';

export const useChipSelector = ({
  value,
  types,
  type,
  onChange,
  onBlur,
}: UseChipSelectorArgs) => {
  const { checkCity } = useCheckCity();
  const { enqueueSnackbar } = useSnackbar();
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined
  );
  const selectorRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useDebounce('', 50);
  const Copy = useCopyNested(Copies);
  const handleChange = (values: GeoItem[]) => {
    onChange(values);
  };

  const { data: result = [], isLoading } = useGeoSearchV2(input, {
    types,
  });

  const data = result.filter(v => v).map(item => mapBoxItemToGeoItem(item));

  const handleFocus = (index: number | undefined) => {
    setFocusedIndex(index);
  };

  const handleFocusPrev = () => {
    if (isUndefined(focusedIndex)) {
      setFocusedIndex(value.length ? value.length - 1 : undefined);
      return;
    }

    setFocusedIndex(focusedIndex === 0 ? undefined : focusedIndex - 1);
  };

  const handleFocusNext = () => {
    if (isUndefined(focusedIndex)) {
      setFocusedIndex(undefined);
    }

    setFocusedIndex(
      focusedIndex === value.length - 1
        ? undefined
        : (focusedIndex as number) + 1
    );
  };

  const handleFocusStale = () => {
    if (focusedIndex === value.length - 1) {
      handleFocusPrev();
    }
  };

  const handleBlur = () => {
    onBlur();
  };

  const handlePopperKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Backspace':
        if (!input.length) {
          handleFocusPrev();
        }
        break;
    }
  };

  const handleRemove = (item: GeoItem) => {
    handleChange(value.filter(v => v.id !== item.id));
  };

  const handleChangeItem = (prevValue: GeoItem, newValue: GeoItem) => {
    handleChange(
      uniqBy(
        value.map(v =>
          [v.id, v.temporaryId].includes(prevValue.id)
            ? omit(newValue, 'temporaryId')
            : v
        ),
        'id'
      )
    );
  };

  const handleAdd = (items: GeoItem[]) => {
    handleChange([
      ...value,
      ...items.map(item => getGeoWithTextByDistinct(item)),
    ]);
    setInput('');
  };

  const handlPopperSelect = async (item: GeoItem[]) => {
    const cityValidations = item.map(async item => {
      if (item.type === distinctNames['place']) {
        const isValid = await checkCity(item.place_name);
        return { item, isValid };
      }
      return { item, isValid: true };
    });
    const results = await Promise.all(cityValidations);
    const invalidItems = results
      .filter(({ isValid }) => isValid !== true)
      .map(({ item }) => item);

    if (invalidItems.length === 0) {
      handleAdd(item);
    } else {
      enqueueSnackbar('Selected location not supported', {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'warning',
      });
    }
  };

  const handleDrop = (items: string[]) => {
    handleAdd(items.map(item => getTemporaryGeo(item, type)));
  };

  return {
    ...useGeoDropzone({
      onChange: handleDrop,
    }),
    focusedIndex,
    focus: handleFocus,
    focusPrev: handleFocusPrev,
    focusNext: handleFocusNext,
    focusStale: handleFocusStale,
    selectorRef,
    setInput,
    errored: input.length > 3 && !isLoading && !data?.length,
    isLoading,
    input,
    data,
    Copy,
    onPopperKeyboardEvent: handlePopperKeyboardEvent,
    onPopperSelect: handlPopperSelect,
    change: handleChange,
    changeItem: handleChangeItem,
    blur: handleBlur,
    remove: handleRemove,
  };
};
