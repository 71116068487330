import React from 'react';
import { AccordionDetails, AccordionDetailsProps } from '@mui/material';

export const SectionDetails = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & AccordionDetailsProps) => {
  return <AccordionDetails {...props}>{children}</AccordionDetails>;
};
