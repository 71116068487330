import { useCallback } from 'react';
import { useWizardContext } from './useWizardContext';
import { nodeStatus } from '../components/Subway/constants';

export const useWizardSubwaySection = ({
  key,
  defaultPaneKey,
}: {
  key: string;
  defaultPaneKey: string;
}) => {
  const {
    navigation: { activeSection, activePane, goTo, ...other },
    state,
  } = useWizardContext();

  const handleSetActivePane = (paneId: string) => {
    goTo({
      sectionId: key,
      paneId,
    });
  };

  const handleSetActiveSection = (sectionId: string) => {
    goTo({
      sectionId,
    });
  };

  const handleGetPaneStatus = useCallback(
    (paneKey: string) => {
      if (
        paneKey === activePane ||
        (!activePane && paneKey === defaultPaneKey)
      ) {
        return nodeStatus.active;
      }

      if (
        state &&
        state[key] &&
        state[key][paneKey] &&
        state[key][paneKey].status === nodeStatus.complete
      ) {
        return nodeStatus.complete;
      }

      return nodeStatus.pending;
    },
    [activePane, defaultPaneKey]
  );

  return {
    active: key === activeSection,
    setActiveSection: handleSetActiveSection,
    setActivePane: handleSetActivePane,
    getPaneStatus: handleGetPaneStatus,
    ...other,
  };
};
