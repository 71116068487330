import { GROUP_TYPE } from '@hooks/apis/groups';
import { LineItem } from '@local-types';

export const getCampaignId = (adGroup: LineItem) => {
  const campaign = adGroup.campaign;

  if (!campaign) return 0;

  if (typeof campaign === 'number') {
    return campaign;
  }

  const match = campaign.match(/(\d+)\/?$/);

  return match ? parseInt(match[1], 10) : 0;
};

export const getGroupType = (adGroup: LineItem) => {
  if (adGroup.url && adGroup.url.includes('static')) {
    return GROUP_TYPE.STATIC_GROUP;
  }

  return GROUP_TYPE.AD_GROUP;
};
