import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, styled } from '@mui/material';

const lengthList = {
  short: '20px',
  long: '40px',
};

export const SubwayVerticalDivider = styled(Box, {
  shouldForwardProp: prop => prop !== 'length',
})<{
  length: keyof typeof lengthList;
}>(({ theme, length }) => {
  return {
    width: '1px',
    height: lengthList[length],
    backgroundColor: theme.palette.grey[4],
    marginLeft: '11.5px',
  };
});

export const SectionPreviewTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{
  isActive: boolean;
}>(({ theme, isActive }) => ({
  fontSize: 24,
  fontWeight: '600',
  color: isActive ? theme.palette.grey[1] : theme.palette.grey[3],
}));

export const SectionSubTitle = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  textAlign: 'left',
});

export const SectionPreviewIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{
  isActive: boolean;
}>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.blue[6] : theme.palette.grey[4],
  height: 32,
  width: 32,
  marginRight: 20,
}));
