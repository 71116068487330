import { useAdvertisers } from '@hooks/advertisers';
import { getPrimaryOrgId } from '@helpers/getPrimaryOrgId';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useWizardPage = () => {
  const history = useHistory();
  const { currentAdvertiser } = useAdvertisers();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(
      'org',
      getPrimaryOrgId(currentAdvertiser?.primary_org) ?? ''
    );
    searchParams.set('adAccount', String(currentAdvertiser?.id));

    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  }, [history, currentAdvertiser]);
};
