import React, { useState } from 'react';

type UseLoaderReturnType = {
  isLoading: boolean;
  isSuccess: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useLoader = (init: boolean = false): UseLoaderReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(init);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  return {
    isLoading,
    isSuccess,
    setIsLoading,
    setIsSuccess,
  };
};
