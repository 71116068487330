import useSWRMutation, { SWRMutationConfiguration, } from 'swr/mutation';
import { useCurrentSession } from '../currentSession';

interface UserAgreementData {
  terms: string;
  accepted: boolean;
}

interface PostResponse {
  data: UserAgreementData;
}

export const usePostUserAgreement = (
  data: UserAgreementData,
  options?: SWRMutationConfiguration<UserAgreementData, Error, string, UserAgreementData>
 ) => {
  const { postV1 } = useCurrentSession();

  return useSWRMutation<UserAgreementData, Error, string, UserAgreementData>(
    '/user_agreements/',
    (url, { arg }) => postV1(url, arg).then((res: PostResponse) => res.data),
    options
  );
 };
