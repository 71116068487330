import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBoxArchive,
} from '@fortawesome/pro-regular-svg-icons';
import { classes } from './styles';
import moment from 'moment';
import React from 'react';
import { Statuses } from './statuses';

export const renderPauseActivateStatus = (status: number, type: string) => {
  if (status === Statuses.ACTIVE) {
    return (
      <>
        <PauseCircleOutlineOutlinedIcon
          fontSize="small"
          className={classes.menuIcon}
        />
        Pause {type}
      </>
    );
  }

  if (status === Statuses.PAUSED) {
    return (
      <>
        <PlayCircleOutlineIcon fontSize="small" className={classes.menuIcon} />
        Activate {type}
      </>
    );
  }
};

export const renderArchiveUnarchiveStatus = (status: number, type: string) => {
  if (status === Statuses.PAUSED) {
    return (
      <>
        <FontAwesomeIcon icon={faBoxArchive} className={classes.menuIcon} fontSize="20px" />
        Archive {type}
      </>
    );
  }

  if (status === Statuses.ARCHIVED) {
    return (
      <>
        <FontAwesomeIcon icon={faArrowRotateLeft} fontSize="20px" className={classes.menuIcon} />
        Unarchive {type}
      </>
    );
  }
};

export const formatEndDate = (endDate: string | null) => {
  if (endDate && Date.parse(endDate)) {
    return moment(endDate).format('l');
  }
  return 'No End Date';
};
