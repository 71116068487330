import React, { useEffect } from 'react';

import apiInstance from '../connection/apiClient';
import {  useUser } from '@hooks/user';

const BWSyncContext = React.createContext(null);

BWSyncContext.displayName = 'BWSyncContext';

const BW_SYNC_URLS = [
  '/campaigns',
  '/creative_lineitems',
  '/creativelineitems',
  '/lineitems',
  '/static_display_lineitems',
  '/staticdisplaylineitems',
];

const isBWSyncUrl = url => {
  return BW_SYNC_URLS.some(u => url.includes(u));
};

export const useBWSyncInterceptors = () => {
  const { user } = useUser();

  const handleRequest = config => {
    if (config.method == 'get' && isBWSyncUrl(config.url)) {
      return {
        ...config,
        params: {
          ...config.params,
          sync: 1,
        },
      };
    }

    return config;
  };

  useEffect(() => {
    let requestInterceptor;

    if (user?.is_tvsci_employee) {
      requestInterceptor = apiInstance.interceptors.request.use(handleRequest);

      return () => {
        apiInstance.interceptors.request.eject(requestInterceptor);
      };
    }
  }, [user?.is_tvsci_employee]);
};
