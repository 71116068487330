const USER_GETS_VERTICAL_CAMPAIGN = 'USER_GETS_VERTICAL_CAMPAIGN';
const USER_GETS_VERTICAL_CAMPAIGN_DRAGS = 'USER_GETS_VERTICAL_CAMPAIGN_DRAGS';
const USER_GETS_NBCU_BIZMAN_UI = 'USER_GETS_NBCU_BIZMAN_UI';
const USER_GETS_VERTICAL_INVENTORY_AVAILABILITY =
  'USER_GETS_VERTICAL_INVENTORY_AVAILABILITY';
const USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS =
  'USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS';
const SYNC_CHECKS_ENABLED = 'SYNC_CHECKS_ENABLED';
const USER_CAN_ARCHIVE = 'USER_CAN_ARCHIVE';
const USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS =
  'USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS';
const USER_GETS_VERTICAL_PACING = 'USER_GETS_VERTICAL_PACING';
const USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART =
  'USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART';
const TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE =
  'TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE';

export const Flags = {
  USER_GETS_VERTICAL_CAMPAIGN,
  USER_GETS_VERTICAL_CAMPAIGN_DRAGS,
  USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS,
  USER_GETS_NBCU_BIZMAN_UI,
  USER_GETS_VERTICAL_INVENTORY_AVAILABILITY,
  SYNC_CHECKS_ENABLED,
  USER_CAN_ARCHIVE,
  USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS,
  USER_GETS_VERTICAL_PACING,
  USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART,
  TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE,
};

export const DefaultFlags = {
  [USER_GETS_VERTICAL_CAMPAIGN]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_DRAGS]: false,
  [USER_GETS_NBCU_BIZMAN_UI]: false,
  [USER_GETS_VERTICAL_INVENTORY_AVAILABILITY]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS]: false,
  [SYNC_CHECKS_ENABLED]: false,
  [USER_CAN_ARCHIVE]: false,
  [USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS]: false,
  [USER_GETS_VERTICAL_PACING]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART]: false,
  [TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE]: false,
};
