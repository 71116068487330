import {
  faCircleCheck,
  faCircleDashed,
  faPlusCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

export const sections = {
  group: 'group',
  campaign: 'campaign',
  review: 'review',
} as const;

export const sectionsOrdering = [
  sections.campaign,
  sections.group,
  sections.review,
] as const;

export const urlSearchParams = {
  section: 'section',
} as const;

export const subwayIconList = {
  complete: faCircleCheck,
  add: faPlusCircle,
  pending: faCircleDashed,
  active: faCircleArrowRight,
} as const;
