import React, { useEffect, useRef } from 'react';
import { useForm, useFieldArray, Controller, type Resolver } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  GridProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Item } from './styled';
import { IListFilterFormProps, IFormValues } from './types/ListFilterFormProps.types';

const itemSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  checked: yup.boolean().required(),
});

const schema = yup.object({
  items: yup.array().of(itemSchema),
  searchValue: yup.string(),
  selectedIds: yup.array(yup.number()),
  isLoading: yup.boolean().required()
});

const formResolver = yupResolver(schema as yup.AnyObjectSchema) as Resolver<IFormValues>;

const ListFilterForm: React.FC<IListFilterFormProps & Omit<GridProps, 'onSubmit'>> = (props) => {
  const { fieldName, data, onSubmit, onSearch, ...rest } = props;
  const isInitialized = useRef(false);

  const { control, handleSubmit, formState, register, watch, setValue } = useForm<IFormValues>({
    defaultValues: {
      items: [],
      searchValue: '',
      selectedIds: [],
      isLoading: false,
    },
    resolver: formResolver
  });

  const { fields } = useFieldArray<IFormValues, 'items', 'internalId'>({
    control,
    name: "items",
    keyName: 'internalId',
  });

  const [isLoading, searchValue, selectedIds] = watch([
    'isLoading',
    'searchValue',
    'selectedIds',
  ]);

  const handleCheckboxChange = (checked: boolean, itemId: number) => {
    const newSelectedIds = checked
      ? [...selectedIds, itemId]
      : selectedIds.filter((id: number) => id !== itemId);

    setValue('selectedIds', newSelectedIds, { shouldValidate: true });
  };

  useEffect(() => {
    setValue('isLoading', data.isLoading);
  }, [data.isLoading, setValue]);

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue, onSearch]);

  useEffect(() => {
    if (data.selectedIds && !isInitialized.current) {
      setValue('selectedIds', data.selectedIds);
      isInitialized.current = true;
    }
  }, [data.selectedIds, setValue]);

  useEffect(() => {
    if (data.items) {
      setValue('items', data.items.map(item => ({
        ...item,
        checked: selectedIds.includes(item.id)
      })));
    }
  }, [data.items, selectedIds, setValue]);

  return (
    <Grid
      component="form"
      container
      direction="column"
      spacing={1}
      sx={{ justifyContent: 'space-between' }}
      onSubmit={handleSubmit(onSubmit)}
      {...rest}
    >
      <Grid xs={7} sx={{ width: "100%" }}>
        <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
          <Stack component={Item} spacing={1}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {fieldName}
              </Typography>
            </Stack>

            <TextField
              id="search-field"
              label={`Enter ${fieldName}`}
              variant="outlined"
              autoComplete="off"
              {...register('searchValue')}
              sx={{ backgroundColor: 'common.white' }}
            />

            <List sx={{
              width: '100%',
              maxWidth: 360,
              height: 200,
              overflow: 'auto',
            }}>
              {fields.length > 0 ? (
                fields.map((field, index) => {
                  const labelId = `checkbox-list-label-${field.id}`;

                  return (
                    <Controller
                      key={field.internalId}
                      name={`items.${index}.checked`}
                      control={control}
                      render={({ field: checkboxField }) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            role={undefined}
                            dense
                          >
                            <ListItemIcon sx={{ minWidth: theme => theme.spacing(3) }}>
                              <Checkbox
                                edge="start"
                                checked={checkboxField.value}
                                onChange={(e) => {
                                  checkboxField.onChange(e.target.checked);
                                  handleCheckboxChange(e.target.checked, field.id);
                                }}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                sx={{ p: 0 }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={field.name} />
                          </ListItemButton>
                        </ListItem>
                      )}
                    />
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText
                    primary={isLoading ? "Loading..." : "No results found"}
                    sx={{
                      textAlign: 'center',
                      color: 'text.secondary',
                      py: 2
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Stack>
        </Box>
      </Grid>

      <Grid xs={2} sx={{ width: '100%' }}>
        <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
          <Stack direction="column" spacing={2}>
            <Divider flexItem sx={{ margin: 0 }} />

            <Item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={!formState.isValid || formState.isSubmitting}
              >
                Apply
              </Button>
            </Item>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListFilterForm;
