import React, { useState, useRef, useEffect } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { TruncableTextTooltipProps } from './types';
import { StyledTruncableSubTitle, StyledTruncableTypography } from './styles';

const ENTER_TOOLTIP_DELAY = 600;

const TruncatedTextTooltip = ({
  text,
  subtitle,
  isExpanded,
}: TruncableTextTooltipProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  // Determine whether the text is truncated
  // ref: https://stackoverflow.com/a/69968815
  const checkTruncation = () => {
    const element = textRef.current;
    if (element && element.scrollWidth > element.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  };

  useEffect(() => {
    checkTruncation();
  }, [text]);

  return (
    <Tooltip
      title={text}
      placement="bottom-end"
      disableHoverListener={!isTruncated}
      enterNextDelay={ENTER_TOOLTIP_DELAY}
    >
      <Stack>
        {subtitle && (
          <StyledTruncableSubTitle
            isExpanded={isExpanded}
            noWrap
            variant="subtitle2"
          >
            {subtitle}
          </StyledTruncableSubTitle>
        )}
        <StyledTruncableTypography
          isExpanded={isExpanded}
          noWrap
          ref={textRef}
          variant="h4"
        >
          {text}
        </StyledTruncableTypography>
      </Stack>
    </Tooltip>
  );
};

export default TruncatedTextTooltip;
