import React from 'react';
import { ButtonBase, Grid } from '@mui/material';
import TruncatedTextTooltip from './TruncableTextToolTip';
import { SubwayElementTitleProps } from './types';

export const SubwayElementTitle = ({
  expanded,
  menu,
  onChange,
  disabled,
  buttonProps,
  title,
  subtitle,
  ...props
}: SubwayElementTitleProps) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      spacing={2}
      flexWrap="nowrap"
      {...props}
    >
      <Grid
        item
        justifyContent="flex-start"
        alignItems="center"
        sx={{ minWidth: 0 }}
      >
        <ButtonBase
          disabled={disabled}
          disableRipple
          sx={{
            display: 'block',
            justifyContent: 'flex-start',
            maxWidth: '100%',
          }}
          onClick={onChange}
          {...buttonProps}
        >
          <TruncatedTextTooltip
            text={title}
            subtitle={subtitle}
            isExpanded={expanded}
          />
        </ButtonBase>
      </Grid>
      {menu}
    </Grid>
  );
};
