import React from 'react';
import { Typography, Toolbar, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { Scopes } from '@constants/scopes';
import { useAdGroupCreativeTable } from './AdGroupCreativeTableContext';
import { CreativeNameCellRenderer } from './renderers/CreativeNameCellRenderer';
import { usePermissions } from '@components/hooks';
import { CreativeQCAlerts } from '@components/CreativeQCAlerts';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { CreativeStatusCellRenderer } from './renderers/CreativeStatusCellRenderer';
import { PinnedPosition } from '@v2/components/ui/DataGrid/constants';

export const AdGroupCreativeTableV2 = () => {
  const { adGroupsCreatives, rowCount, isLoading, adGroup } =
    useAdGroupCreativeTable();
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      resizable: false,
      hideable: false,
      pinned: PinnedPosition.LEFT,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: CreativeNameCellRenderer,
      width: 220,
      resizable: false,
      hideable: false,
      pinned: PinnedPosition.LEFT,
    },
    {
      field: 'active',
      headerName: 'Status',
      renderCell: CreativeStatusCellRenderer,
      width: 180,
      resizable: false,
      hideable: false,
      pinned: PinnedPosition.LEFT,
    },
    {
      field: 'adGroup',
      headerName: 'Ad Group',
      flex: 1,
      renderCell: () => adGroup?.name,
      resizable: false,
      hideable: false,
    },
    {
      field: 'media_upload_date',
      headerName: 'Uploaded',
      renderCell: DateCellRenderer,
      minWidth: 150,
      resizable: false,
      hideable: false,
    },
    {
      field: 'stats.total_spend',
      headerName: 'Spend',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      minWidth: 120,
      resizable: false,
      hideable: false,
    },
    {
      field: 'stats.visit_count',
      headerName: 'Visits',
      valueGetter: DotNotationValueGetter,
      minWidth: 120,
      resizable: false,
      hideable: false,
    },
    {
      field: 'stats.cost_per_visit',
      headerName: 'Cost Per Visit',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      minWidth: 120,
      resizable: false,
      hideable: false,
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      {showQCFunctionality && (
        <CreativeQCAlerts
          email={'support@tvscientific.com'}
          items={adGroupsCreatives || []}
        />
      )}
      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={2}
        m={4}
        borderRadius="20px"
      >
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">
            Creative
          </Typography>
        </Toolbar>
        <DataGrid
          loading={isLoading}
          rows={adGroupsCreatives}
          hideFooter
          rowCount={rowCount}
          columns={columns}
          disableRowSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
};
