import { useContext, useEffect, useMemo, useRef } from 'react';
import { useCopy, useFlags, useCurrentSession } from '@hooks';
import { Copies as AdvancedDeliveryOptionsFormCopies } from '../../AdvancedDeliveryOptionsForm/constants';
import { Copies, fields, objectives } from '../constants';
import {
  getObjectivesOptions,
  getRecommendedCampaignObjectForAdvertiser,
} from '../utils';
import { getMinDate } from '@components/WizardContainers/validations';
import { Themes } from '@constants/themes';
import AdvertiserContext from '@components/AdvertiserContext';
import { useFormContext } from 'react-hook-form';

const SCROLL_DELAY = 500;

export const useCampaignForm = ({
  campaignStaticDisplayAdGroups,
  isIncremental,
  startDate,
  startTime,
}) => {
  const ref = useRef(null);
  const { watch, setValue, trigger, control } = useFormContext();

  const {
    theme,
    advertiserevent_set = [],
    campaign_objective_types = {},
  } = useContext(AdvertiserContext);

  const { currentAdvertiser } = useCurrentSession();

  const Copy = useCopy(Copies);

  const { flags, Flags } = useFlags();

  const AdvancedDeliveryOptionsFormCopy = useCopy(
    AdvancedDeliveryOptionsFormCopies
  );

  const recommendedCampaignObjective = useMemo(
    () => getRecommendedCampaignObjectForAdvertiser(currentAdvertiser),
    [currentAdvertiser]
  );

  const handleValidatorAction = () => {
    trigger();
    if (ref?.current !== null) {
      window.setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, SCROLL_DELAY);
    }
  };

  const dragsAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_DRAGS] && theme !== Themes.NBCU;

  const objectiveGoalsAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS];

  const objectivesOptions = useMemo(
    () =>
      getObjectivesOptions({
        advertiserObjectives: campaign_objective_types,
        control,
        copies: Copy,
        objectiveGoalsAllowed,
        objectiveGoalEventSet: advertiserevent_set,
        recommendedCampaignObjective,
        setValue,
      }),
    [
      campaign_objective_types,
      control,
      Copy,
      objectiveGoalsAllowed,
      advertiserevent_set,
      recommendedCampaignObjective,
      setValue,
    ]
  );

  const [objective, goalEvent, goalType] = watch([
    fields.objective.path,
    fields.objectiveGoalEvent.path,
    fields.objectiveGoalType.path,
  ]);

  const minEndDate = getMinDate(startDate, startTime);

  const campaignHasSavedDrag =
    campaignStaticDisplayAdGroups.length > 0 &&
    !campaignStaticDisplayAdGroups[0].temporary;

  const dragToggleDisabled = campaignHasSavedDrag || isIncremental;

  const dragToggleText = isIncremental
    ? 'Display retargeting is not available for incrementality tests.'
    : Copy.dragToggleText;

  const dragTooltip = campaignHasSavedDrag
    ? 'Retarget exposed audience with display ads. This cannot be toggled off \
    once the display ad group is saved.'
    : Copy.dragToggleText;

  const campaignObjectiveHeader = Copy.campaignObjectiveHeader;

  const trackingIsSetUp = advertiserevent_set.length > 0;

  useEffect(() => {
    const currentObjectiveDescription = objectives[objective];

    if (currentObjectiveDescription.hasGoalEvent) {
      setValue(
        fields.objectiveGoalEvent.path,
        !goalEvent && advertiserevent_set.length === 1
          ? advertiserevent_set[0].value
          : goalEvent
      );

      setValue(
        fields.objectiveGoalType.path,
        !goalType && currentObjectiveDescription.goalTypes.length === 1
          ? currentObjectiveDescription.goalTypes[0].value
          : goalType
      );

      return;
    }

    if (goalEvent) {
      setValue(fields.objectiveGoalEvent.path, null, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }

    if (goalType) {
      setValue(fields.objectiveGoalType.path, null, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [objective, goalEvent, goalType, advertiserevent_set]);

  return {
    AdvancedDeliveryOptionsFormCopy,
    advertiserevent_set,
    campaignObjectiveHeader,
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    minEndDate,
    objectivesOptions,
    ref,
    trackingIsSetUp,
    validatorAction: handleValidatorAction,
  };
};
